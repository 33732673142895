import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUserThunk = createAsyncThunk(
  '/getFreelancers',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        '/freelancer/getFreelancers',
        data,
        {responseType: data?.export?'arraybuffer':'json'}
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const blacklistThunk = createAsyncThunk(
  '/blacklist',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.put(
        '/freelancer/blacklist',
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const updateStatusThunk = createAsyncThunk(
  '/updateAppUserStatus',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.put(
        '/freelancer/updateAppUserStatus',
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const getCountriesThunk = createAsyncThunk(
  'app/getCountries',
  async (data, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `/freelancer/countries`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

import { createSlice } from '@reduxjs/toolkit'
import { addUserThunk, getUserThunk, updateUserThunk, deleteUserThunk, resendInviteThunk } from './thunk';
import { IAdminState } from './types';


const initialState: IAdminState = {
    inProgress: false,
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    role: '',
    error: '',
    status:0
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {

    },
    // Async actions
    extraReducers: (builder) => {

        builder.addCase(getUserThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(getUserThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state = { ...state, ...action.payload.data }
            state.error = null
        })
        builder.addCase(getUserThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(addUserThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(addUserThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state = { ...state, ...action.payload.data }
            state.error = null
        })
        builder.addCase(addUserThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(updateUserThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(updateUserThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state = { ...state, ...action.payload.data }
            state.error = null
        })
        builder.addCase(updateUserThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(deleteUserThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(deleteUserThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state = { ...state, ...action.payload.data }
            state.error = null
        })
        builder.addCase(deleteUserThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(resendInviteThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(resendInviteThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state = { ...state, ...action.payload.data }
            state.error = null
        })
        builder.addCase(resendInviteThunk.rejected, (state, action) => {
            state.inProgress = false;
        })
    }
})

export const { } = adminSlice.actions
export default adminSlice.reducer

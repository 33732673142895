import { useState } from 'react';

const CheckBox: React.FC<{
  onSelect: any;
  id: string;
  project: { id: string; isChecked: boolean }[];
}> = ({ onSelect, id, project }, props) => {
  return (
    <input
      className='form-check-input'
      type='checkbox'
      key={id}
      data-kt-check-target='#kt_table_users .form-check-input'
      //checked={getCurrentCheckBox?.isChecked}
      onClick={(e) => onSelect(e, id)}
    />
  );
};

export default CheckBox;

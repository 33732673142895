const ProgressBar: React.FC<{ progressLength: number }> = ({
  progressLength,
}) => (
  <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
    <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
      <span className='fw-bold fs-6 text-gray-400'>Progress</span>
      <span className='fw-bolder fs-6'>{progressLength}%</span>
    </div>
    <div className='h-5px mx-3 w-100 bg-light mb-3'>
      <div
        className='bg-success rounded h-5px'
        role='progressbar'
        style={{ width: `${progressLength}%` }}
        aria-valuenow={progressLength}
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  </div>
);

export default ProgressBar;

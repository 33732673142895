import clsx from "clsx";
import {
  getPaidPayment,
  getUsersPayments,
} from "../../../../../redux/Projects/thunks";
import { useDispatch, useSelector } from "../../../../../../setup/redux/Store";
import { isFulfilled } from "@reduxjs/toolkit";
import { useState, useEffect, useMemo } from "react";
import { JOB_ROLES, ROLES } from "../../../../../../models/app";

const ProjectUserCard: React.FC<{
  currency: string;
  projectData: any;
  activities: any;
  payments: any;
  projectId: string | undefined;
  index: string;
  getDrawerData: Function;
  rating: number;
  role: string;
}> = ({
  currency,
  projectData,
  projectId,
  index,
  getDrawerData,
  activities,
  payments,
  rating,
  role,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [currentIndex, setCurrentIndex] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setData({ ...projectData });
  }, [projectData]);

  useEffect(() => {
    if (currentIndex?.status) {
      getDrawerData({
        data,
        activities,
        role,
        totalHours,
        approvedHours,
        rejectedHours,
        rating,
        index: currentIndex?.status,
      });
    }
  }, [data, currentIndex]);

  const [userPayment, setUserPayment] = useState<number>(0);

  const { PaymentReintialize, taskReintialize } = useSelector((store) => ({
    taskReintialize: store.slice.taskReintialize,
    PaymentReintialize: store.slice.PaymentReintialize,
  }));

  let [userPaymentStatus, setUserPaymentStatus] = useState<string | undefined>(
    undefined
  );

  const totalHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities.reduce((total: number, hrs: any) => {
        const workedHours = Number(
          (new Date(hrs.checkoutTime).getTime() -
            new Date(hrs.checkinTime).getTime()) /
            1000 /
            3600
        );
        return (
          total +
          (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
        );
      }, 0);
    } else {
      return 0;
    }
  }, [activities]);


  const approvedHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return +activities
        .filter((v: any) => v.approvalStatus === 1)
        .reduce((total: number, hrs: any) => {
          const workedHours = Number(
            (new Date(hrs.checkoutTime).getTime() -
              new Date(hrs.checkinTime).getTime()) /
              1000 /
              3600
          );
          return (
            total +
            (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
          );
        }, 0).toFixed(2);
    } else {
      return 0;
    }
  }, [activities]);


  const rejectedHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities
        .filter((v: any) => v.approvalStatus === 2)
        .reduce((total: number, hrs: any) => {
          const workedHours = Number(
            (new Date(hrs.checkoutTime).getTime() -
              new Date(hrs.checkinTime).getTime()) /
              1000 /
              3600
          );
          return (
            total +
            (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
          );
        }, 0);
    } else {
      return 0;
    }
  }, [activities]);

  const unapproveHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities
        .filter((v: any) => v.approvalStatus === 0 || v.approvalStatus === 3)
        .reduce((total: number, hrs: any) => {
          const workedHours = Number(
            (new Date(hrs.checkoutTime).getTime() -
              new Date(hrs.checkinTime).getTime()) /
              1000 /
              3600
          );
          return (
            total +
            (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
          );
        }, 0);
    } else {
      return 0;
    }
  }, [activities]);



  const paidStatus = useMemo(() => {
   
    const paidHours = +payments
      .filter((v: any) => v.paymentStatus === 2)
      .reduce((acc: number, v: any) => acc + Number(v.totalApprovedHours), 0).toFixed(2);
    const partialHours = +payments
      .filter((v: any) => v.paymentStatus === 1)
      .reduce((acc: number, v: any) => acc + Number(v.totalApprovedHours), 0).toFixed(2);
    if (paidHours >= approvedHours && approvedHours > 0) {
      return `Paid`;
    } else if (paidHours > 0 && paidHours < approvedHours) {
      return `Partially paid`;
    } else if (partialHours > 0) {
      return "Initiated";
    } else {
      return "Pending";
    }
  }, [payments, approvedHours]);

  const paidAmount = useMemo(() => {
    return payments
      .filter((v: any) => v.paymentStatus === 2)
      .reduce((acc: number, v: any) => acc + Number(v.amount), 0).toFixed(2);
  }, [payments, approvedHours]);

  /** The total approved hours the user has worked  */
  const OpenDrawer = (index: string) => {
    setCurrentIndex({ status: index });
  };

  let pic =
    data?.photo?.trim().length != 0 ? data.photo : "/media/avatars/blank.png";

  return (
    <div className="col-md-6 col-xl-4">
      <a
        //href='javascript:void(0);'
        className="card border-hover-primary pointer"
        id="kt_drawer_chat_toggle"
        onClick={() => OpenDrawer(index)}
      >
        <div className="card-header border-0 p-4 d-block">
          <div className="card-title m-0">
            <div className="symbol symbol-50px w-50px bg-light">
              <img src={pic} className="" />
            </div>
            <div className="user-name mx-4 ">
              <div className="fs-3 fw-bolder text-dark text-truncate text-capitalize text-wrap">
                {`${data.firstName} ${data.lastName}`}
              </div>

              <div className="d-flex align-items-center fw-bold mt-1">
                <span className="badge bg-light text-gray-700 px-3 py-1 me-2">
                  <i
                    className={clsx(
                      rating === 5
                        ? "bi-star-fill"
                        : rating > 0
                        ? "bi-star-half"
                        : "bi-star",
                      "bi me-1 text-warning fs-6"
                    )}
                  ></i>{" "}
                  {rating}
                </span>

                <span className="text-gray-400 fs-7">
                  {data.profile?.country}
                </span>
                <span className="text-gray-400 fs-7 text-capitalize">
                  {" "}
                  - {role.replace("_", " ")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="card-body p-4">
          <div className="d-flex flex-wrap">
            <div className="border border-gray-300 border-dashed rounded min-w-100x py-2 px-3 me-3 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">
                {totalHours.toFixed(2)}
              </div>
              <div className="fw-bold text-gray-400 fs-8">Total Hours</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-100x py-2 px-3 me-3 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">
                {unapproveHours.toFixed(2)}
              </div>
              <div className="fw-bold text-gray-400 fs-8">Unapproved Hours</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-100x py-2 px-3 me-3 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">
                {approvedHours.toFixed(2)}
              </div>
              <div className="fw-bold text-gray-400 fs-8">Approved Hours</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-100x py-2 px-3 me-3 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">
                {rejectedHours.toFixed(2)}
              </div>
              <div className="fw-bold text-gray-400 fs-8">Rejected Hours</div>
            </div>
            <div className="border border-gray-300 border-dashed rounded min-w-100x py-2 px-3 me-3 mb-3">
              <div className="fs-6 text-gray-800 fw-bolder">
                 {(paidAmount * approvedHours).toFixed(2)} {""} {currency}
              </div>
              <div className="fw-bold text-gray-400 fs-8">Amount Paid</div>
            </div>
           {totalHours > 0?<div className="border border-gray-300 border-dashed rounded min-w-100x py-2 px-3 me-3 mb-3 bg-light-success">
              <div className="fs-6 text-gray-800 fw-bolder text-success">
                {paidStatus}
              </div>
              <div className="fw-bold text-gray-400 fs-8">Payment Status</div>
            </div>:null}
          </div>
        </div>
      </a>
    </div>
  );
};

export default ProjectUserCard;

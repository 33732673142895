import ReactDOM from 'react-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import * as _redux from './setup'
import store, { persistor } from './setup/redux/Store'
// Axios
import axios from 'axios'
import { Chart, registerables } from 'chart.js'

// Apps
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/style.react.scss'
import { AppRoutes } from './app/routing/AppRoutes'
import { ToastContainer } from 'react-toastify';

_redux.setupAxios(axios, store)


Chart.register(...registerables)
ReactDOM.render(
  <MetronicI18nProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <AppRoutes />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
      </PersistGate>
    </Provider>
  </MetronicI18nProvider>,
  document.getElementById('root')
)

import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "../../../../../../setup/redux/Store";
import { updateTaskStatusThunk } from "../../../../../redux/Projects/thunks";
import { reloadProjectAction } from "../../../../../redux/Projects/projectSlice";
import Comments from "../comment";
import { isFulfilled } from "@reduxjs/toolkit";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { TASKSTATUS } from "../../../../../../models/project-mangement-model";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import moment from "moment";
import clsx from "clsx";

const TaskInfoTab: React.FC<{
  data: any;
  projectId: string | undefined;
  activities: any;
  fieldName: string;
}> = ({ data, projectId, activities, fieldName }) => {
  const [showRejectComments, setShowRejectComments] = useState(-1);
  const [reason, setReason] = useState("");
  const [tasks, setTasks] = useState<any[]>([]);
  const [showTask, setShowTask] = useState(-1);

  useEffect(() => {
    if (activities) {
      setTasks(activities);
    }
  }, [activities]);

  // const getTask = async () => {
  //   setInprogress(true);
  //   const response = await dispatch(getTaskThunk({ userId, projectId }));
  //   if (isFulfilled(response)) {
  //     setTasks(response.payload.data);
  //     setInprogress(false);
  //   } else {
  //     setTasks([]);
  //     setInprogress(false);
  //   }
  //   MenuComponent.reinitialization();
  // };

  // const ExecuteAll = async () => {
  //   await Promise.all([getTask(), getComments()])
  // }

  // useEffect(() => {
  //   ExecuteAll();
  // }, []);

  const dispatch = useDispatch();

  const updateTaskStatus = async (payload: any) => {
    const result = await dispatch(updateTaskStatusThunk(payload));
    if (isFulfilled(result)) {
      dispatch(reloadProjectAction(true));
      setReason("");
      setShowRejectComments(-1);
      toast("Task Status has been updated..", { type: "success" });
    } else {
      toast("Could not update Task Status.. ", { type: "error" });
    }
  };

  const onApproveTaskHandler = (taskId: number) => {
    updateTaskStatus({ taskId, status: TASKSTATUS.APPROVED });
  };

  const onHoldTaskHandler = (taskId: number) => {
    updateTaskStatus({ taskId, status: TASKSTATUS.HOLD });
  };
  const onRejectTaskHandler = (index: number) => {
    setReason("");
    setShowRejectComments(index);
  };

  const sendRejectTask = async (taskId: string) => {
    if (!reason) {
      toast("Please enter the reason", { type: "error" });
      return;
    }
    updateTaskStatus({ taskId, status: TASKSTATUS.REJECT, reason: reason });
  };

  return (
    <>
      <ul className="task-sheet" style={{ listStyle: "none" }}>
        {tasks && tasks.length > 0 ? (
          tasks.map((el: any, index: number) => {
            return (
              <li key={index}>
                <div className="review-info d-flex justify-content-between align-items-center">
                  <div className="check my-3">
                    <small className="text-muted d-flex">
                      {" "}
                      <div className="date">
                        <span className="svg-icon svg-icon-4 me-2 fw-bold">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              opacity="0.3"
                              d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                              fill="black"
                            ></path>
                            <path
                              d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                              fill="black"
                            ></path>
                            <path
                              d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        {/* {moment(el.checkinTime).format("DD-MM-YYYY hh:mm:ss A")} */}
                      </div>
                      Checked In
                    </small>
                    <div className="flex flex-col">
                      <p className="fw-bold mb-0 fs-12 mt-3">
                        {moment(el.checkinTime).format("DD-MM-YYYY hh:mm:ss A")}
                      </p>
                      <a
                        href={`https://maps.google.com/?q=${el.checkInlocation.latitude},${el.checkInlocation.longitude}`}
                        className="fw-bold mb-0 fs-12 mt-3"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`https://maps.google.com/?q=${el.checkInlocation.latitude},${el.checkInlocation.longitude}`}
                      </a>
                    </div>
                  </div>

                  <div className="check my-3">
                    <small className="text-muted">Checked Out</small>
                    <div className="flex flex-col">
                      <p className="fw-bold mb-0 fs-12 mt-3">
                        {moment(el.checkoutTime).format(
                          "DD-MM-YYYY hh:mm:ss A"
                        )}
                      </p>

                      <a
                        href={`https://maps.google.com/?q=${el.checkOutlocation.latitude},${el.checkOutlocation.longitude}`}
                        className="fw-bold mb-0 fs-12 mt-3"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`https://maps.google.com/?q=${el.checkOutlocation.latitude},${el.checkOutlocation.longitude}`}
                      </a>
                    </div>
                  </div>
                  <div className="status  mt-4 d-flex flex-column flex-row-auto align-items-center">
                    <Menu
                      menuButton={
                        <div className="d-flex align-items-center  mt-5">
                          <a
                            className={`text pointer ${
                              el.approvalStatus === 2
                                ? "text-danger"
                                : el.approvalStatus === 1
                                ? "text-success"
                                : el.approvalStatus === 0
                                ? "text-primary"
                                : el.approvalStatus === 3
                                ? "text-warning"
                                : ""
                            }`}
                          >
                            {el.approvalStatus === TASKSTATUS.APPROVED
                              ? "Approved"
                              : el.approvalStatus === TASKSTATUS.REJECT
                              ? "Reject"
                              : el.approvalStatus === TASKSTATUS.HOLD
                              ? "Hold"
                              : "Waiting"}
                          </a>
                          <i className="bi bi-chevron-down mr-2"></i>
                        </div>
                      }
                    >
                      {el.approvalStatus === TASKSTATUS.REJECT ? (
                        <>
                          <MenuItem
                            onClick={() => onApproveTaskHandler(el._id)}
                          >
                            Approved
                          </MenuItem>
                          <MenuItem onClick={() => onHoldTaskHandler(el._id)}>
                            on Hold
                          </MenuItem>
                        </>
                      ) : el.approvalStatus === TASKSTATUS.APPROVED ? (
                        <>
                          <MenuItem onClick={() => onRejectTaskHandler(index)}>
                            Reject
                          </MenuItem>
                          <MenuItem onClick={() => onHoldTaskHandler(el._id)}>
                            on Hold
                          </MenuItem>
                        </>
                      ) : el.approvalStatus === TASKSTATUS.HOLD ? (
                        <>
                          <MenuItem
                            onClick={() => onApproveTaskHandler(el._id)}
                          >
                            Approved
                          </MenuItem>
                          <MenuItem onClick={() => onRejectTaskHandler(index)}>
                            Reject
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          <MenuItem onClick={() => onHoldTaskHandler(el._id)}>
                            On Hold
                          </MenuItem>
                          <MenuItem
                            onClick={() => onApproveTaskHandler(el._id)}
                          >
                            Approved
                          </MenuItem>
                          <MenuItem onClick={() => onRejectTaskHandler(index)}>
                            Reject
                          </MenuItem>
                        </>
                      )}
                    </Menu>
                  </div>
                  <div className="mt-5" style={{ minWidth: 20 }}>
                    {el.tasks.length > 0 ? (
                      <a
                        title="View Comments"
                        //href='javascript:(0)'
                        className="btn btn-link text-danger fs-6 p-0 mt-4"
                        onClick={() =>
                          setShowTask(
                            showTask === -1 || showTask != index ? index : -1
                          )
                        }
                      >
                        <i
                          className={clsx(
                            showTask !== index ? "bi-eye" : "bi-eye-slash",
                            "bi bi-eye-slash text-primary"
                          )}
                        ></i>
                      </a>
                    ) : null}
                  </div>
                </div>
                <div>
                  <span className="text-muted">{fieldName}</span> :{" "}
                  {el.saleValue}
                </div>
                {showTask === index && el.tasks.length > 0 ? (
                  <div>
                    <table className="w-100 table table-striped table-bordered">
                      <thead>
                        <th
                          className="bg-success text-center"
                          style={{ color: "#fff" }}
                        >
                          Tasks
                        </th>
                        <th
                          className="bg-success text-center"
                          style={{ color: "#fff" }}
                        >
                          Added on
                        </th>
                      </thead>
                      <tbody>
                        {el.tasks.map((v: any) => (
                          <tr>
                            <td>{v.description}</td>
                            <td className="text-center">
                              {" "}
                              {moment(v.time).format("DD MMM hh:mm:ss A")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}

                {el.comments ? (
                  <div>
                    <small className="text-muted">Comments</small>
                    <p>{el.comments}</p>
                    <p>
                      Added on:{" "}
                      {moment(el.commentOn).format("DD-MM-YYYY hh:mm:ss A")}
                    </p>
                  </div>
                ) : null}

                {/** the checks in and out */}
                <div className="accordion-item border border-transparent">
                  {showRejectComments === index ? (
                    <div className="mt-10">
                      <div className="fv-row mb-3">
                        <label className="required fw-bold fs-6 mb-2">
                          Reject Reason
                        </label>
                        <textarea
                          className="form-control form-control-solid"
                          rows={3}
                          name="message"
                          maxLength={500}
                          // value={rejectComment}
                          placeholder=""
                          onChange={({ target: { value } }) => setReason(value)}
                        ></textarea>
                      </div>

                      <div className="col-md-3 mb-2 text-start">
                        <button
                          type="button"
                          className="btn btn-small btn-primary"
                          onClick={() => sendRejectTask(el._id)}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="separator my-2"></div>
              </li>
            );
          })
        ) : (
          <>
            <div className="col-md-12 ">
              <div className="fs-5 fw-bolder text-muted d-flex me-5 w-100 justify-content-center">
                {" "}
                No task added yet
              </div>
            </div>
          </>
        )}
      </ul>
    </>
  );
};

export default TaskInfoTab;

import axios, { AxiosResponse } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IChatMessage } from "../../pages/projectManagement/chat/ChatType";

export const getGroups = createAsyncThunk(
  "/group/get",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post("/group/get", data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const getGroupMessagesThunk= createAsyncThunk(
  "/group/getMessages",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `/group/getMessages`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);


export const editGroupThunk = createAsyncThunk(
  "group/create",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/group/create`, data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const saveGroupMessageThunk = createAsyncThunk(
  "group/save/message",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `/group/save/message`,
        data,
        { headers: { "content-type": "multipart/form-data" } }
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const deleteGroupThunk = createAsyncThunk(
  "/deleteProject",
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post("/group/delete", data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";

const ExportModal: React.FC<{
  closeModal: any;
  download: Function;
}> = ({ closeModal, download }) => {
  let [exportFormat, setExportFormat] = useState<string>("");

  const submit = () => {
    download(exportFormat);
    closeModal();
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h2 style={{margin:20}}> Export Jobs</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => closeModal()}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-1"
          />
        </div>
      </div>

      <div className="modal-body mx-5 mx-xl-15 my-7">
        <div className="d-flex flex-column" id="kt_modal_add_user_scroll">
          <div className="fv-row row ">
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Select Export Format:{" "}
                </label>
                <select
                  className="form-select form-select-solid mb-3 mb-lg-0"
                  placeholder="Select a format"
                  value={exportFormat}
                  onChange={({ target: { value } }) => setExportFormat(value)}
                >
                  <option value={""}>Select</option>
                  <option value={"xlsx"}>Excel</option>
                  <option value={"pdf"}>PDF</option>
                  <option value={"csv"}>CSV</option>
                </select>
              </div>

              <div className="text-center mb-5">
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={() => closeModal()}
                >
                  Discard
                </button>
                <button
                  disabled={!exportFormat}
                  type="button"
                  className="btn btn-primary"
                  onClick={submit}
                >
                  <span className="indicator-label">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;

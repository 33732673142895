import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { isFulfilled } from '@reduxjs/toolkit'
import { createValidationSchema } from '../../../../utils/validationSchema';
import { useSelector, useDispatch } from '../../../../setup/redux/Store';
import { forgotPasswordThunk } from '../../../redux/Admin/thunk';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({

})

export function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { inProgress } = useSelector((state) => ({
    inProgress: state.admin.inProgress
  }))


  const { handleBlur, handleSubmit, handleChange, setFieldValue, errors, values, touched, status: formStatus } = useFormik({
    initialValues: {
      email: ''
    },
    enableReinitialize: true,
    validationSchema: createValidationSchema('forgotPassword'),
    onSubmit: async (values: any, { setStatus }) => {
      const response: any = await dispatch(forgotPasswordThunk(values));
      if (isFulfilled(response)) {
        toast("Reset link sent to your email",{type:'success'})
        navigate('/');
      } else {
        const err: any = response;
        setStatus(err.payload.error)
      }
    }
  })

  const handler = (field: string, event: any) => {
    const { target: { value } } = event;
    setFieldValue(field, value)
  }

  return (
    <>

      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Forgot Password ?</h1>
        <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
      </div>

      {formStatus?(
        <div className=' alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formStatus}</div>
        </div>
      ) : null}

      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input onKeyUp={(event) => handler('email', event)} onBlur={handleBlur}
          type='email'
          placeholder=''
          autoComplete='off'
          className={clsx(
            'form-control form-control-lg form-control-solid'
          )}
        />
        {errors.email && touched.email ? <div className='text-danger'>
          {errors.email}
        </div> : null}


      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder me-4'
            disabled={inProgress}
          >
            Cancel
          </button>
        </Link>{' '}
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary fw-bolder '
          disabled={inProgress} onClick={() => handleSubmit()}
        >
          <span className='indicator-label'>Submit</span>
        </button>

      </div>
    </>
  )
}

import * as io from "socket.io-client";
export let jobaSocket: any = null;

export default function setupSocket(store: any) {
  const url = process.env.REACT_APP_SOCKET_URL || "";

  const {
    auth: { accessToken },
  } = store.getState();

  const socket =  io.connect(url, {
    extraHeaders: {
      Authorization: accessToken,
    },
  });
  console.group("Socket OCmplered")
  jobaSocket = socket.connect();

}

import {configureStore} from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {RootReducer} from './RootReducer'
import { createSelectorHook, createStoreHook, useDispatch as DispatchHook } from 'react-redux'


// import logger from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage:storage,
  whiteList:['auth']
}
const persistedReducer = persistReducer(persistConfig, RootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
      // serializableCheck: {
      //   ignoredActions: ['uploadImages'],
      //   ignoredActionPaths: ['meta.arg', 'payload.images']
      // }
    }).prepend(routerMiddleware(history))
})


export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useSelector = createSelectorHook<RootState>()
export const useStore = createStoreHook<RootState>()
export const useDispatch = () => DispatchHook<AppDispatch>()


export const persistor = persistStore(store);

export default store

import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationList,
  ConversationHeader,
  Avatar,
  Conversation,
  Sidebar,
  Search,
} from "@chatscope/chat-ui-kit-react";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { useDispatch, useSelector } from "../../../../setup/redux/Store";
import { isFulfilled } from "@reduxjs/toolkit";
import { jobaSocket } from "../../../../setup/socket/SetupSocket";
import { useParams } from "react-router";
import * as _redux from "../../../../setup";
import {
  getGroups,
  saveGroupMessageThunk,
  getGroupMessagesThunk,
} from "../../../redux/Chat/thunk";
import { UsersListLoading } from "../../../../_metronic/layout/components/loading/UsersListLoading";
import { IAuthor, IChatMessage } from "./ChatType";
import store from "../../../../setup/redux/Store";
declare global {
  interface Window {
    downloadImage: (imageUrl: string) => void;
  }
}

function Chat() {
  const dispatch = useDispatch();
  const params = useParams();

  const projectId: string | undefined = params.id;
  const getGroupAvatarInitials = (name: string) => {
    let initials = name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toUpperCase();
    return initials.length > 2 ? initials.slice(0, 2) : initials;
  };

  useLayoutEffect(() => {
    _redux.setupSocket(store);
  }, []);

  const [messageInputValue, setMessageInputValue] = useState("");
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [messages, setMessages] = useState<{
    [groupId: string]: IChatMessage[];
  }>({});
  const [totalMessages, setTotalMessages] = useState(0);
  const [currentSelectedGroupId, setCurrentSelectedGroupId] = useState<any>();
  const [currentSelectedGroupName, setCurrentSelectedGroupName] = useState<
    string
  >();
  const [inProgress, setInProgress] = useState<boolean>(true);
  const [skip, setSkip] = useState(0);
  const noOfItem = 10;

  const { id, firstName, lastName } = useSelector((state) => ({
    id: state.auth.user?._id,
    firstName: state.auth.user?.firstName,
    lastName: state.auth.user?.lastName,
  }));

  useEffect(() => {
    getGroupsList();
  }, []);

  useEffect(() => {
    const handleNewGroupMessage = (newMessage: IChatMessage) => {
      setMessages((prevMessages: any) => {
        const { groupId }: any = newMessage;
        if (prevMessages[groupId]) {
          return {
            ...prevMessages,
            [groupId]: [...prevMessages[groupId], newMessage], // Appending new message at the end
          };
        } else {
          return {
            ...prevMessages,
            [groupId]: [newMessage],
          };
        }
      });
    };
    jobaSocket.on("newGroupMessage", handleNewGroupMessage);
    
  }, []);


  useEffect(() => {
    currentSelectedGroupId && getGroupMessages();
  }, [currentSelectedGroupId]);

  const getGroupMessages = async () => {
    setSkip(0);
    const payload = {
      skip: 0,
      noOfItem,
      groupId: currentSelectedGroupId,
    };

    const response: any = await dispatch(getGroupMessagesThunk(payload));
    if (isFulfilled(response)) {
      setTotalMessages(response.payload.count);
      setMessages((prevMessages) => {
        return {
          ...prevMessages,
          [currentSelectedGroupId]: response.payload.data.reverse(),
        };
      });
      
      
    }
  };
  const getMessageTime = (timestamp: any) => {
    const timestampDate = new Date(timestamp);
    const hours = timestampDate.getHours();
    const minutes = timestampDate.getMinutes();
    const amPM = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12
    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${amPM}`;

    return formattedTime;
  };

  const getGroupsList = async () => {
    const response = await dispatch(getGroups({ projectId }));
    handleGroupsResponse(response);
  };

  const handleGroupsResponse = (response: any) => {
    if (isFulfilled(response)) {
      setInProgress(false);
      const groupData = response.payload?.data || [];
      const groupInfoList = groupData.map(mapGroupInfo);
      jobaSocket?.emit("addGroups", response.payload?.data);

      if (groupInfoList.length > 0) {
        const [firstGroup] = groupInfoList;
        jobaSocket.emit("joinGroup", firstGroup._id);
        setCurrentSelectedGroupId(firstGroup._id);
        setCurrentSelectedGroupName(firstGroup.name);
        setGroups(groupInfoList);
        setFilteredGroups(groupInfoList);
      }
    }
  };

  const mapGroupInfo = (group: any) => {
    const { _id: groupId, name, userIds, recentMessage } = group;
    const lastMessage =
      recentMessage && recentMessage.image
        ? "Sent an attachment"
        : recentMessage?.content ?? null;
    const lastMessageTime = recentMessage?.createdAt
      ? getMessageTime(recentMessage?.createdAt)
      : null;
    const lastMessageUserName = recentMessage?.author
      ? `${recentMessage.author.firstName} ${recentMessage.author.lastName}`
      : null;

    return {
      _id: groupId,
      name: name || "No Group Name",
      recentMessage: lastMessage || "No Recent Message",
      recentMessageTime: lastMessageTime || "",
      lastMessageUserName,
      userIds,
    };
  };

  const sendMessage = async () => {
    const userData: IAuthor = {
      _id: id || "",
      firstName: firstName || "",
      lastName: lastName || "",
    };

    const formData: any = new FormData();
    formData.append("content", messageInputValue);
    formData.append("author", userData?._id);
    formData.append("groupId", currentSelectedGroupId);
   
    const result: any = await dispatch(saveGroupMessageThunk(formData));
    const payloadData = result.payload.data;

    const newMessage: IChatMessage = {
      content: messageInputValue,
      author: userData || null,
      createdAt: payloadData.createdAt,
      groupId: currentSelectedGroupId || "",
    };

    jobaSocket.emit("newMessage", newMessage);
    setMessageInputValue("")
  };

  const handleLoadConversation = (groupId: string, groupName: string) => {
    setCurrentSelectedGroupId(groupId);
    setCurrentSelectedGroupName(groupName);
    jobaSocket.emit("joinGroup", groupId);
  };

  const getMessageDirection = (userId: string) => {
    return userId === id ? "outgoing" : "incoming";
  };

  window.downloadImage = function (imageUrl) {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.setAttribute("download", ""); // This attribute triggers the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getMessagePayload = (item: IChatMessage) => {
    let payload = `<strong>${item.author?.firstName} ${item.author?.lastName}</strong><br />`;

    if (item?.image) {
      // If item.image is present, include the image with an onclick handler to trigger the download
      payload += `<img src="${
        item.image
      }" alt="Chat Image" style="max-width: 100%; max-height: 200px; cursor: pointer;" onclick="window.downloadImage('${
        item.image
      }')" /><br />${getMessageTime(item.createdAt)}`;
    } else {
      // If item.image is not present, include item.content
      payload += `${item?.content}<br />${getMessageTime(item.createdAt)}`;
    }
    return payload;
  };

  return (
    <React.Fragment>
      {inProgress ? (
        <UsersListLoading />
      ) : (
        <div
          style={{
            height: "550px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {groups.length === 0 ? (
            <div className="d-flex text-center w-100 align-items-center justify-content-center">
              No Groups found
            </div>
          ) : (
            <>
              <MainContainer responsive>
                <Sidebar position="left" scrollable={false}>
                  <Search placeholder="Search..." />
                  <ConversationList>
                    {filteredGroups.map((group: any) => (
                      <Conversation
                        onClick={() =>
                          handleLoadConversation(group._id, group.name)
                        }
                        name={group.name}
                        lastActivityTime={group.recentMessageTime}
                        lastSenderName={group.lastMessageUserName}
                        info={group.recentMessage}
                        about={group.recentMessage}
                        style={{
                          backgroundColor:
                            currentSelectedGroupId === group._id
                              ? "lightgray"
                              : "inherit",
                        }}
                      >
                        <Avatar
                          src="https://fastly.picsum.photos/id/20/3670/2462.jpg?hmac=CmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I"
                          name={getGroupAvatarInitials(group.name)}
                          content={getGroupAvatarInitials(group.name)}
                          status="available"
                        />
                      </Conversation>
                    ))}
                  </ConversationList>
                </Sidebar>

                <ChatContainer>
                  <ConversationHeader>
                    <ConversationHeader.Back />
                    <Avatar
                      src="https://picsum.photos/id/237/200/300"
                      name="Zoe"
                    />
                    <ConversationHeader.Content
                      userName={currentSelectedGroupName}
                    />
                  </ConversationHeader>
                  <MessageList>
                    {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}
                    {messages[currentSelectedGroupId]?.map(
                      (item: IChatMessage) => (
                        <Message
                          type="image"
                          model={{
                            type: "html",
                            direction: getMessageDirection(
                              item.author?._id || ""
                            ),
                            position: "normal",
                            payload: getMessagePayload(item),
                          }}
                        >
                          <Avatar
                            src="https://picsum.photos/id/237/200/300"
                            name="Zoe"
                          />
                        </Message>
                      )
                    )}
                  </MessageList>
                  <MessageInput
                    autoFocus
                    attachButton={false}
                    placeholder="Type message here"
                    value={messageInputValue}
                    onChange={(val) => setMessageInputValue(val)}
                    onSend={sendMessage}
                  />
                </ChatContainer>
              </MainContainer>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
}

export default Chat;

/* this show either the supervisor or manager and their profile pic*/
import { toAbsoluteUrl } from '../../../../../_metronic/helpers/AssetHelpers';
import ReactTooltip from 'react-tooltip';

const Avatar: React.FC<{ title: string; imgUrl: string, content:string }> = ({
  title,
  imgUrl,
  content
}) => {
  let ImageUrl =
    imgUrl && imgUrl.trim().length > 0 ? imgUrl : '/media/avatars/blank.png';

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='fw-bold fs-6 text-gray-400 d-block'>{title}</div>
        <div className='d-flex flex-stack flex-wrapr'>
          <div className='symbol-group symbol-hover my-1'>
            <div
              className='symbol symbol-35px symbol-circle'
              data-tip={content}
            >
              <img alt='Profile' src={toAbsoluteUrl(ImageUrl)} />
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip type='light'  effect='float' place='top'/>
    </>
  );
};

export default Avatar;

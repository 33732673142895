import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import JobManagement from '../pages/jobManagement';
import ProjectManagement from '../pages/projectManagement';
import AppUser from '../pages/appUser';
import UserManagement from '../pages/userManagement';
import ViewJob from '../pages/jobManagement/viewJobs';
import ProjectDetails from '../pages/projectManagement/project-details/projectDetails';
import GroupChatManagement from '../pages/chatManagement';
import ChatManagement from '../pages/projectManagement/chat';


const PrivateRoutes = () => {
  /*const { role, companyId } = useSelector((state) => ({
    role: state.auth.user?.role,
    companyId: state.auth.user?.company?._id,
  }));*/


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/job_management' />} />
        <Route path='job_management' element={<JobManagement />} />
        <Route path='view_job/:id' element={<ViewJob />} />
        <Route path='project_management' element={<ProjectManagement />} />
        <Route path='chat/:id' element={<ChatManagement />} />
        <Route path='project_details/:id' element={<ProjectDetails />} />
        <Route path='app_user' element={<AppUser />} />
        <Route path='user_management' element={<UserManagement />} />
        <Route path='chat_management' element={<GroupChatManagement />} />
    
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };

/**
 * <Route path='project_details/:id:tab' element={UserInfoPage} />
          <Route path='work-history' element={WorkHistoryPage} />
 */

import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from '../../../setup/redux/Store';
import { KTCard } from '../../../_metronic/helpers/components/KTCard';
import { PageTitle, PageLink } from '../../../_metronic/layout/core';
import ProjectManagementTable from './table';
import { Navigate, useNavigate } from 'react-router-dom';

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/projects',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const ProjectManagementMainPage = () => {
  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className='container-fluid d-flex flex-stack'
        >
          <div
            data-kt-swapper='true'
            data-kt-swapper-mode='prepend'
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className='page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0'
          >
            <h1 className='d-flex align-items-center text-dark fw-bolder fs-3 my-1'>
              Project Management
            </h1>
            <span className='h-20px border-gray-300 border-start mx-4'></span>
            <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1'>
              <li className='breadcrumb-item text-muted'>
                <a
                  href='javascript:void(0)'
                  className='text-muted text-hover-primary'
                >
                  Home
                </a>
              </li>
              <li className='breadcrumb-item'>
                <span className='bullet bg-gray-300 w-5px h-2px'></span>
              </li>
              <li className='breadcrumb-item text-dark'>Project</li>
            </ul>
          </div>
        </div>
      </div>
      <KTCard>
        <ProjectManagementTable />
      </KTCard>
    </>
  );
};

export default ProjectManagementMainPage;

import * as Yup from 'yup';
import { JOB_ROLE } from '../models/app';

const rules: any = {
    admin: {
        firstName: Yup.string().
            required('First Name is required'),
        lastName: Yup.string().
            required('Last Name is required'),
        email: Yup.string()
            .email('Wrong email format')
            .required('Email is required'),
        contact: Yup.string().
            required('Contact is required'),
        company: Yup.string().
            required('Company is required'),
        role: Yup.string().
            required('Role is required')
    },
    login: {
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Email is required'),
        password: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Password is required')
    },
    inviteUser: {
        password: Yup.string()
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Password is required')
    },
    forgotPassword: {
        email: Yup.string()
            .email('Wrong email format')
            .min(3, 'Minimum 3 symbols')
            .max(50, 'Maximum 50 symbols')
            .required('Email is required'),
    },
    addJob: {
        title: Yup.string().
            required('Title is required'),
        startDate: Yup.string().
            required('Start Date is required'),
        endDate: Yup.string().
            required('End Date is required'),
        location: Yup.string().
            required('Location is required'),
        duration: Yup.number().
            required('Duration is required'),
        durationPeriod: Yup.string().
            required('Period is required'),
        jobDescription: Yup.string().
            required('Description is required'),
        minExp: Yup.string().
            required('Min Experience is required'),
        maxExp: Yup.string().
            required('Max Experience is required'),
        role: Yup.string().
            required('Role is required'),
        subRole: Yup.string().when(['role'], (role, schema) => {
            return role === JOB_ROLE.AMBASSADOR ? schema.required('Sub role is required') : schema;
        }),
        // skillTag: Yup.array().
        //     required('Skill Tag is required'),
        companyId: Yup.string().
            required('Company is required'),
        currency: Yup.string().
            required('Currency is required'),
        minWages: Yup.number().
            required('Min wages is required'),
        maxWages: Yup.number().
            required('Max wages is required'),
    },
    addProject: {
        name: Yup.string().required('Title is required'),
        startDate: Yup.string().required('Start Date is required'),
        endDate: Yup.string().required('End Date is required'),
        location: Yup.string().required('Location is required'),
        companyId: Yup.string().required('Company is required'),
        currency: Yup.string().required('Currency is required'),
        fieldName: Yup.string().required('Field name is required'),
        fieldDisplayName: Yup.string().required('Display name is required'),
        projectDescription: Yup.string().required('Description is required'),
        assignToBA: Yup.array().required('BAs is required'),
        assignToSupervisor: Yup.string().required('Supervisors is required'),
        assignToProjectManager: Yup.string().required('Project Manager is required'),
    },
    addChatGroup: {
        name: Yup.string().required('Title is required'),
    },

}



export const createValidationSchema = (entity: any) => {
    const fields: any = rules[entity]
    return Yup.object().shape(fields);
}

import { createSlice } from '@reduxjs/toolkit'
import { IJobs } from './types';
import { getJobThunk, getJobByIdThunk, addJobThunk, editJobThunk, inactiveJobThunk } from './thunk';

const initialState: IJobs = {
    inProgress: false,
    error: '',
    viewUser:null
}

const adminSlice = createSlice({
    name: 'adminJobs',
    initialState,
    reducers: {
        viewUserAction:(state,action)=>{
            state.viewUser = action.payload;
        }
    },
    // Async actions
    extraReducers: (builder) => {

        builder.addCase(getJobThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(getJobThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(getJobThunk.rejected, (state, action) => {
            state.inProgress = false;
        })


        builder.addCase(getJobByIdThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(getJobByIdThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(getJobByIdThunk.rejected, (state, action) => {
            state.inProgress = false;
        })


        builder.addCase(addJobThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(addJobThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(addJobThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(editJobThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(editJobThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(editJobThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(inactiveJobThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(inactiveJobThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(inactiveJobThunk.rejected, (state, action) => {
            state.inProgress = false;
        })
    }
})

export const { viewUserAction } = adminSlice.actions
export default adminSlice.reducer

/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";
import { logoutAction } from "../../../../app/redux/Auth/slice";
import { useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../helpers";
import { isFulfilled } from "@reduxjs/toolkit";
import { uploadPhotoThunk } from "../../../../app/redux/Auth/thunk";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);

  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logoutAction());
    navigate("/auth/login");
  };

  const uploadPhoto = async (event: any) => {
    const {
      target: { files },
    } = event;
    const [file] = files;
    const ext = file.name.split(".").pop();
    if (!["jpeg", "jpg", "png"].includes(ext.toLowerCase())) {
      toast("Please upload a valid format (jpg, png)", { type: "error" });
      return;
    }

    const timestamp = new Date().getTime();
    const originalFileName = file.name;
    const desiredFileName = `${originalFileName}_${timestamp}.${ext}`;

    
    const formdata: any = new FormData();
    formdata.append("files", new File([file], desiredFileName, { type: file.type }));
    setInProgress(true);
    const result:any = await dispatch(uploadPhotoThunk(formdata));
    if (isFulfilled(result)) {
      setInProgress(false);
      // toast("Photo updated successfully", { type: "success" });
    } else {
      const errorMessage=result?.payload.message;
      toast(errorMessage, { type: "error" });
      setInProgress(false);
    }
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center flex-column justify-content-center">
          <label htmlFor="upload-image">
            <div className="symbol  pointer">
              {inProgress ? (
                <div className="d-flex justify-content-center align-items-center">
                  <TailSpin color="green" height={50} width={50} />
                </div>
              ) : (
                <div style={{width:80, height:80}}>
                <img
                style={{width:'100%', height:'100%'}}
                  alt="Logo"
                  src={
                    user.photo
                      ? user.photo
                      : toAbsoluteUrl("/media/avatars/blank.png")
                  }
                />
                </div>
              )}
            </div>
            <div className="fw-bold text-muted text-hover-primary fs-8 text-center mt-2" style={{cursor:'pointer'}}><u>Change Image</u></div>
          </label>
          <input
          className="d-none"
            id="upload-image"
            type="file"
            onChange={uploadPhoto}
            style={{ visibility: "hidden" }}
          />
          <div className="text-center mt-3">
            <div className="fw-bolder  fs-5">
              {user.firstName} {user.lastName}
            </div>
            <div>
              <a className="fw-bold text-muted text-hover-primary fs-7">
                {user.email}
              </a>
            </div>
            <div>
              <a className="fw-bold text-muted text-hover-primary fs-7 text-capitalize textoverflow">
                {user.role.replace("_", " ")}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div> */}
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <a
          onClick={logout}
          className="menu-link px-5 btn btn-primary d-flex justify-content-center"
          style={{ color: "#fff" }}
        >
          Log Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };

import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByUidThunk } from '../../../redux/Auth/thunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { createValidationSchema } from '../../../../utils/validationSchema';
import { useSelector, useDispatch } from '../../../../setup/redux/Store';
import { updatePasswordThunk } from '../../../redux/Admin/thunk';


const initialValues = {
    password: '',
}


const InviteUser = () => {
    const { uid } = useParams()
    const [name, setName] = useState('');
    const [id, setId] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { inProgress } = useSelector((state) => ({
        inProgress: state.admin.inProgress
    }))

    const getUser = async () => {
        const result = await dispatch(getUserByUidThunk({ uid }));
        if (isFulfilled(result)) {
            setName(`${result.payload.data.firstName} ${result.payload.data.lastName}`);
            setId(result.payload.data._id);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        if (uid) {
            getUser();
        } else {
            navigate('/');
        }
    }, [uid])



    const { handleBlur, handleSubmit, handleChange, setFieldValue, errors, values, touched, status: formStatus } = useFormik({
        initialValues: {
            id: id,
            password: ''
        },
        enableReinitialize: true,
        validationSchema: createValidationSchema('inviteUser'),
        onSubmit: async (values: any, { setStatus }) => {
            const response: any = await dispatch(updatePasswordThunk(values));
            if (isFulfilled(response)) {
                navigate('/');
            } else {
                const err: any = response;
                setStatus(err.payload.error)
            }
        }
    })

    const handler = (field: string, event: any) => {
        const { target: { value } } = event;
        setFieldValue(field, value)
    }


    return (
        <>
            <div className='text-center mb-10'>
                <h1 className='text-dark mb-3'>Welcome {`${name}`} !</h1>
                <div className='text-gray-400 fw-bold fs-4'>Set your password.</div>
            </div>

            {formStatus && (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>
                        {formStatus}
                    </div>
                </div>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
                <input onKeyUp={(event) => handler('password', event)} onBlur={handleBlur}
                    type='password'
                    placeholder=''
                    autoComplete='off'
                    className={clsx(
                        'form-control form-control-lg form-control-solid')}
                />
                {errors.password && touched.password ? <div className='text-danger'>
                    {errors.password}
                </div> : null}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <Link to='/'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-lg btn-light-primary fw-bolder  me-4'
                        disabled={inProgress}
                    >
                        Cancel
                    </button>
                </Link>{' '}
                <button
                    type='submit'
                    id='kt_password_reset_submit'
                    className='btn btn-lg btn-primary fw-bolder'
                    disabled={inProgress} onClick={() => handleSubmit()}
                >
                    <span className='indicator-label'>Submit</span>
                </button>

            </div>
            {/* end::Form group */}
        </>
    )
}


export default InviteUser
import { useState } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from '../../../../setup/redux/Store';
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG';
import ExportModal from './exportModal';

const customStyles = {
  content: {
    border: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '50%',
    padding: 0,
    transform: 'translate(-50%, -50%)',
  },
};

const ExportData: React.FC<{ download: Function }> = ({ download }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <button
        onClick={openModal}
        type='button'
        className='btn btn-light-primary me-3 d-flex align-items-center'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr078.svg'
          className='svg-icon-2'
        />
        Export
      </button>

      <Modal
        isOpen={modalIsOpen}
        id={'project_management'}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <ExportModal closeModal={closeModal} download={download} />
      </Modal>
    </>
  );
};

export { ExportData };

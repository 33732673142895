import ReviewComments from './review-comments';

const ViewRatingBtn: React.FC<{projectId: string | undefined; brandAmbassador: any, supervisor:any }> = ({
  brandAmbassador,
  supervisor,
  projectId,
}) => (
  <>
    <button
      className='btn btn-sm btn-primary me-4'
      id='kt_drawer_review_toggle'
    >
      <i className='bi bi-star me-1 text-white fs-6'></i> View Rating
    </button>
    {<ReviewComments brandAmbassador={brandAmbassador} supervisor={supervisor} projectId={projectId}/>}
  </>
);

export default ViewRatingBtn;

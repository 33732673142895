/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//import { Filter } from './components/filter';
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";
import { ExportData } from "./components/exportData";
import ReactPaginate from "react-paginate";
import { UsersListLoading } from "../../../_metronic/layout/components/loading/UsersListLoading";
import { KTCardBody } from "../../../_metronic/helpers/components/KTCardBody";
import { useDispatch, useSelector } from "../../../setup/redux/Store";
import Modal from "react-modal";
import { getCountriesThunk } from "../../redux/Freelancer/thunk";

import {
  getAllProjects,
  deleteProjectThunk,
  editProjectStatus,
  getProjectManagersThunk,
  publishProjectThunk,
} from "../../redux/Projects/thunks";
import { toast } from "react-toastify";
import { isFulfilled } from "@reduxjs/toolkit";
import { ROLES } from "../../../models/app";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { useDebounce } from "../../../_metronic/helpers";
import EditProject from "./components/editProject";

import clsx from "clsx";
import DeleteSelectedDataOnTable from "../../../utils/delete-selected-data";
import CheckBox from "../../../utils/CheckBox";

export interface stateType {
  _id: string;
  name: string;
  startDate: string;
  endDate: string;
  location: string;
  projectDescription: string;
  tagToJob: number;
  assignedBA: [];
  supervisor: [];
  projectManager: [];
  status: number;
  jobsId: [];
  tagAllsUsers: number;
}

const customStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};
const deleteModalStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const itemsPerPage = 10;
const initFilter = {
  projectManager: "",
  startDate: "",
  endDate: "",
  status: "",
  location: "",
  name: "",
  supervisor: "",
};

const ProjectManagementTable: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sort, setSort] = useState({ field: "", order: "asc" });

  const [search, setSearch] = useState<string>("");
  const [projects, setProjects] = useState<any>([]);
  const [country, setCountry] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [multiFilter, setMultiFilter] = useState(initFilter);
  const [projectManager, setProjectManager] = useState([]);
  const [openDeleteConfirmation, setDeleteOpenConfirmation] = useState(false);
  useEffect(() => {
    const getCountry = async () => {
      const response = await dispatch(getCountriesThunk());
      if (isFulfilled()) {
        setCountry(response.payload.data);
      }
    };
    getCountry();
  }, []);
  const [filter, setFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const { companyId, role, inProgress } = useSelector((state) => ({
    role: state.auth.user?.role,
    companyId: state.auth.user?.company?._id,
    inProgress: state.project.inProgress,
  }));

  const isAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);

  const handlePageClick = (event: any) => {
    setFilter({ ...filter, page: event.selected });
  };

  const fetchAllProjects = async () => {
    const response:any = await dispatch(getAllProjects({ ...filter, companyId }));
    if (isFulfilled(response)) {
      if (response.payload.status) {
        setProjects(response.payload.data);
        setTotal(response.payload.count);
      }
    }
    MenuComponent.reinitialization();
  };

  const getProjectManagers = async () => {
    const response:any = await dispatch(getProjectManagersThunk({ companyId: companyId }));
    if (isFulfilled(response)) {
      if (response.payload.status) {
        setProjectManager(response.payload.data);
      }
    }
  };

  useEffect(() => {
    fetchAllProjects();
    getProjectManagers();
  }, [filter]);

  const deleteProject = (data: any) => {
    setDeleteOpenConfirmation(true);
    setProjectData(data);
  };

  const deleteProjectHandler = async (data: any) => {
    const response = await dispatch(deleteProjectThunk({ id: data._id }));
    if (isFulfilled(response)) {
      toast("Project deleted successfully", { type: "success" });
      setDeleteOpenConfirmation(false);
      fetchAllProjects();
    } else {
      toast("Project deleted unsuccessfully", { type: "error" });
    }
  };

  const onChangeFilter = (field: string, value: any) => {
    const filt: any = { ...multiFilter };
    filt[field] = value;
    setMultiFilter(filt);
  };

  const applyFilter = () => {
    setFilter({ ...filter, filter: multiFilter });
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      setFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  const viewJob = (data: any) => {
    navigate(`/project_details/${data._id}`);
  };

  const chat = (data: any) => {
    navigate(`/chat/${data._id}`);
  };

  const projectMangerHandler = (event: any) => {
    onChangeFilter("projectManager", event.target.value);
  };

  const resetFormHandler = () => {
    setMultiFilter(initFilter);
    setFilter({ ...filter, filter: initFilter });
  };

  const startDateInputHandler = (event: any) => {
    let startDate = event.target.value;
    onChangeFilter("startDate", startDate);
  };

  const endDateInputHandler = (event: any) => {
    let endDate = event.target.value;
    onChangeFilter("endDate", endDate);
  };

  const statusInputHandler = (event: any) => {
    let statusValue = event.target.value;
    onChangeFilter("status", statusValue);
  };

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [projectData, setProjectData] = useState({
    _id: "",
    name: "",
    startDate: "",
    endDate: "",
    location: "",
    projectDescription: "",
    tagToJob: 0,
    assignedBA: [],
    supervisor: {},
    projectManager: {},
    status: 1,
    tagAllsUsers: 1,
    jobsId: [],
  });

  const [action, setAction] = useState<string>("");
  
  const editProject = (data: stateType) => {
    setProjectData(data);
    setAction("edit");
    openModal();
  };

  const addProject = () => {
    setProjectData({
      _id: "",
      assignedBA: [],
      endDate: "",
      jobsId: [],
      location: "",
      name: "",
      projectDescription: "",
      projectManager: [],
      startDate: "",
      status: 1,
      supervisor: [],
      tagToJob: 0,
      tagAllsUsers: 1,
    });
    setAction("Add");
    openModal();
  };

  const onChangeProjectStatusHandler = async (
    projectId: string,
    status: number
  ) => {
    //let status: number = 0;
    const response = await dispatch(editProjectStatus({ status, projectId }));
    if (isFulfilled(response)) {
      toast("Project status has successfully been changed", {
        type: "success",
      });
      fetchAllProjects();
    } else {
      toast("Project status hasn't been changed, try again", { type: "error" });
    }
  };

  const changeSort = (field: string) => {
    if (sort.field === field) {
      let order = "";
      let sortField = "";
      if (sort.order === "") {
        order = "asc";
        sortField = field;
      } else if (sort.order === "asc") {
        order = "desc";
        sortField = field;
      }
      setSort({ field: sortField, order: order });
      setFilter({ ...filter, sort: sortField, order: order });
    } else {
      setSort({ field, order: "asc" });
      setFilter({ ...filter, sort: field, order: "asc" });
    }
  };

  const download = async (type: string) => {
    const response:any = await dispatch(
      getAllProjects({ ...filter, companyId, export: type })
    );
    if (isFulfilled(response)) {
      const blob = new Blob([response.payload], {
        type:
          type === "csv"
            ? "text/csv"
            : type === "pdf"
            ? "application/pdf"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Projects.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const mappedData = useMemo(() => {
    if (!projects) return [];
    let v: any = {};
    let result = projects.map((el: any) => {
      v.id = el._id;
      v.isChecked = false;
      return v;
    });
    return result;
  }, [projects]);

  const [isCheck, setIsCheck] = useState<
    Array<{ id: string; isChecked: boolean }>
  >([...mappedData]);

  const numOfProjectChecked: number = useMemo(() => {
    let projectWithIsCheckTrue = [];
    isCheck.forEach((el: any) => {
      if (el.isChecked === true) {
        projectWithIsCheckTrue.push(el.id);
      }
    });
    return projectWithIsCheckTrue.length;
  }, [isCheck]);

  const onSelect = (e: any, el: string) => {
    let project = isCheck;
    project.forEach((p) => {
      if (p.id === el) p.isChecked = e.target.checked;
    });
    setIsCheck(project);
  };

  const onSelectAll = (e: any) => {
    let project = isCheck;
    project.forEach((p) => (p.isChecked = e.target.checked));
    setIsCheck(project);
  };

  const publishProject = async (data: any, hasNotification: boolean) => {
    const result = await dispatch(
      publishProjectThunk({ id: data._id, hasNotification })
    );
    if (isFulfilled(result)) {
      fetchAllProjects();
      toast(
        hasNotification
          ? "Project published successfully"
          : "Pjoject updated successfully",
        { type: "success" }
      );
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const isFilterApplied = useMemo(() => {
    return (
      Object.keys(filter.filter).filter((v) => !!filter.filter[v]).length > 0
    );
  }, [filter.filter]);

  return (
    <React.Fragment>
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {/* begin::Input Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search Project"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {/* end::Input Search */}
        </div>
        {/* end::card-tile Search */}

        {
          /*(
          <DeleteSelectedDataOnTable itemsChecked={ numOfProjectChecked} />
        ) : */ <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-user-table-toolbar="base"
            >
              <>
                {/** start filtert */}
                <>
                  <button
                    disabled={false}
                    type="button"
                    className={clsx(
                      "btn btn-light-primary me-3 d-flex align-items-center",
                      isFilterApplied ? "btn-success" : ""
                    )}
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen031.svg"
                      className="svg-icon-2"
                    />
                    Filter
                  </button>
                  <div
                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true"
                  >
                    <div className="px-7 py-5">
                      <div className="fs-5 text-dark fw-bolder">
                        Filter Options
                      </div>
                    </div>

                    <div className="separator border-gray-200"></div>

                    <div
                      className="px-7 py-5"
                      data-kt-user-table-filter="form"
                      style={{ overflow: "auto", maxHeight: 350 }}
                    >
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Project Manager:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                          onChange={projectMangerHandler}
                          value={multiFilter.projectManager}
                        >
                          <option value="">Select Project Manager</option>
                          {projectManager &&
                            projectManager.map((v: any) => (
                              <option
                                key={v._id}
                                value={v._id}
                              >{`${v.firstName} ${v.lastName}`}</option>
                            ))}
                        </select>
                      </div>

                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Start Date:
                        </label>
                        <input
                          type="date"
                          name="user_start_date"
                          onChange={startDateInputHandler}
                          value={multiFilter.startDate}
                          className="form-control form-control-solid mb-3 mb-lg-0"
                        />
                      </div>
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          End Date:
                        </label>
                        <input
                          value={multiFilter.endDate}
                          type="date"
                          name="user_end_date"
                          onChange={endDateInputHandler}
                          className="form-control form-control-solid mb-3 mb-lg-0"
                        />
                      </div>
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Status:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                          onChange={statusInputHandler}
                          value={multiFilter.status}
                        >
                          <option value="">Select</option>
                          <option value={1}>Draft</option>
                          <option value={2}>Published</option>
                          <option value={3}>Completed</option>
                          <option value={4}>Inactive</option>
                        </select>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          disabled={false}
                          onClick={resetFormHandler}
                          className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="reset"
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          onClick={applyFilter}
                          className="btn btn-primary fw-bold px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="filter"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </>
                {/** end filter */}
                <ExportData download={download} />
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  onClick={addProject}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  Add Project
                </button>
              </>
            </div>
          </div>
        }
      </div>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 "
          >
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-up~percase gs-0">
                <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_table_users .form-check-input"
                      value="check all"
                      onClick={(e) => onSelectAll(e)}
                    />
                  </div>
                </th>
                <th
                  onClick={() => changeSort("name")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "name" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  PROJECT NAME
                </th>
                <th
                  onClick={() => changeSort("startDate")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "startDate" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  START DATE
                </th>
                <th
                  onClick={() => changeSort("endDate")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "endDate" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  END DATE
                </th>
                {/* <th
                  onClick={() => changeSort("location")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "location" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  LOCATION
                </th> */}
                <th
                  //onClick={() => changeSort('supervisor')}
                  onClick={() => changeSort("supervisor")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "supervisor" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  NO OF BRAND AMBASSADOR
                </th>
                <th
                  //onClick={() => changeSort('supervisor')}
                  onClick={() => changeSort("supervisor")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "supervisor" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  NO OF SUPERVISOR
                </th>
                <th className={clsx("min-w-125px ")}>PROJECT MANAGER</th>

                <th
                  //onClick={() => changeSort('status')}
                  onClick={() => changeSort("status")}
                  className={clsx(
                    "min-w-125px pointer text-center",
                    sort.field === "status" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  STATUS
                </th>
                <th
                  //onClick={() => changeSort('action')}
                  onClick={() => changeSort("action")}
                  className={clsx(
                    "min-w-125px pointer text-center",
                    sort.field === "action" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                  //className={clsx('min-w-125px pointer text-center')}
                >
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {!projects ? (
                <tr>
                  <td colSpan={8}>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      No record found
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {projects.length === 0 ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex  w-100  text-center justify-content-center">
                          No record found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    projects.map((el: any, index: number) => {
                      let _status: string = "";
                      if (el.status === 4) {
                        _status = "Inactive";
                      } else if (el.status === 1) {
                        _status = "Draft";
                      } else if (el.status === 2) {
                        _status = "Published";
                      } else if (el.status === 3) {
                        _status = "Completed";
                      }

                      return (
                        <tr key={el._id}>
                          {/** the check box */}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <CheckBox
                                onSelect={onSelect}
                                project={isCheck}
                                id={el._id}
                              />
                            </div>
                          </td>

                          {/** project title */}
                          <td>
                            <div className="d-flex justify-content-start flex-column pointer">
                              <a
                                //href='javascript:void(0)'
                                onClick={() => viewJob(el)}
                                className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                              >
                                {el.name}
                              </a>
                            </div>
                          </td>
                          {/** project date */}
                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                              >
                                {new Intl.DateTimeFormat("en-GB", {
                                  dateStyle: "medium",
                                }).format(new Date(`${el.startDate}`))}
                              </a>
                            </div>
                          </td>

                          {/** end date */}
                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                              >
                                {new Intl.DateTimeFormat("en-GB", {
                                  dateStyle: "medium",
                                }).format(new Date(`${el.endDate}`))}
                              </a>
                            </div>
                          </td>

                          {/** location */}
                          {/* <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                              >
                                {el.location?.country}
                              </a>
                            </div>
                          </td> */}
                          {/** brand ambassador */}
                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                                title={el.brandAmbassador
                                  .map(
                                    (v: any) =>
                                      `${v?.brandAmbassadorUser[0]?.firstName} ${v?.brandAmbassadorUser[0]?.lastName}`
                                  )
                                  .join(", ")}
                              >
                                {el.brandAmbassador.length}
                              </a>
                            </div>
                          </td>

                          {/** supervisor */}
                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                                title={el.supervisor
                                  .map(
                                    (v: any) =>
                                      `${v?.supervisorUser[0]?.firstName} ${v?.supervisorUser[0]?.lastName}`
                                  )
                                  .join(", ")}
                              >
                                {el.supervisor.length}
                              </a>
                            </div>
                          </td>

                          {/** project manager */}
                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                              >
                                {el?.projectManager.length > 0
                                  ? `${el?.projectManager[0]?.firstName} ${el?.projectManager[0]?.lastName}`
                                  : ""}
                              </a>
                            </div>
                          </td>

                          {/** status */}
                          <td>
                            <a
                              //href='javascript:void(0)'
                              className="d-block mb-1 fs-6 text-center"
                            >
                              <div
                                className={clsx(
                                  "badge fw-bolder",
                                  el.status === 4 ? " badge-light-danger" : "",
                                  el.status === 1 ? "badge-light-success" : "",
                                  el.status === 2 || el.status === 3
                                    ? "badge-light-primary"
                                    : ""
                                )}
                              >
                                {_status}
                              </div>
                            </a>
                          </td>

                          {/** action  */}
                          <td className="d-flex justify-content-center">
                            <a
                              //href='javascript:void(O)'
                              className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center "
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              Actions
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr072.svg"
                                className="svg-icon-5 m-0"
                              />
                            </a>
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                              data-kt-menu="true"
                            >
                              {el.status !== 4 ? (
                                <>
                                  {el.status !== 2 &&
                                  el?.projectManager.length > 0 ? (
                                    <>
                                      <div className="menu-item">
                                        <a
                                          className="menu-link"
                                          onClick={() =>
                                            publishProject(el, false)
                                          }
                                        >
                                          Publish without notification
                                        </a>
                                      </div>
                                      <div className="menu-item">
                                        <a
                                          className="menu-link"
                                          onClick={() =>
                                            publishProject(el, true)
                                          }
                                        >
                                          Publish with notification
                                        </a>
                                      </div>
                                    </>
                                  ) : null}
                                  {el?.projectManager.length > 0 ? (
                                    <div className="menu-item">
                                      <a
                                        onClick={() => viewJob(el)}
                                        className="menu-link"
                                      >
                                        View
                                      </a>
                                    </div>
                                  ) : null}
                                  <div className="menu-item">
                                    <a
                                      //href='javascript:void(0)'
                                      className="menu-link"
                                      onClick={() => editProject(el)}
                                    >
                                      Edit
                                    </a>
                                  </div>
                                  <div className="menu-item">
                                    <a
                                      className="menu-link"
                                      onClick={() =>
                                        onChangeProjectStatusHandler(el._id, 4)
                                      }
                                    >
                                      In-activate
                                    </a>
                                  </div>
                                  <div className="menu-item">
                                    <a
                                      //href='javascript:void(0)'
                                      onClick={() => deleteProject(el)}
                                      className="menu-link"
                                    >
                                      Delete
                                    </a>
                                  </div>
                                  <div className="menu-item">
                                    <a
                                      //href='javascript:void(0)'
                                      onClick={() => chat(el)}
                                      className="menu-link"
                                    >
                                      Chat
                                    </a>
                                  </div>
                                </>
                              ) : (
                                <div className="menu-item">
                                  <a
                                    className="menu-link"
                                    onClick={() =>
                                      onChangeProjectStatusHandler(el._id, 1)
                                    }
                                  >
                                    Activate
                                  </a>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ margin: 20 }}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={total / itemsPerPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={itemsPerPage}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            // forcePage={pageOffset}
          />
        </div>
        {inProgress && <UsersListLoading />}
      </KTCardBody>
      <Modal
        isOpen={modalIsOpen}
        id={"edit_project"}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <EditProject
          action={action}
          closeModal={closeModal}
          fetchAllProjects={fetchAllProjects}
          data={projectData}
          country={country}
        />
      </Modal>
      <Modal
        isOpen={openDeleteConfirmation}
        // onRequestClose={closeModal}
        style={deleteModalStyles}
        id={"delete_confirmation"}
      >
        <div className="container m-5">
          <h1>Delete Project</h1>
          <p>Are you sure you want to delete this project?</p>
          <div className="clearfix mt-5 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setDeleteOpenConfirmation(false)}
              className="btn btn-sm btn-secondary me-3"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => deleteProjectHandler(projectData)}
              className="btn btn-sm btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ProjectManagementTable;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../helpers";
import { useSelector, useDispatch } from "../../../../setup/redux/Store";
import clsx from "clsx";

const ViewDrawer = () => {
  const { viewUser } = useSelector((state) => ({
    viewUser: state.freelancers.viewUser,
  }));

  const [activeDrawerTabs, setActiveDrawerTabs] = useState({
    tab1: true,
    tab2: false,
  });
  const [showId, setShowId] = useState(false);

  const onClickUserInfo = () => {
    setActiveDrawerTabs({ tab1: true, tab2: false });
  };
  const onClickWorkHistory = (event: any) => {
    setActiveDrawerTabs({ tab1: false, tab2: true });
  };

  const rating = useMemo(() => {
    let rating =
      viewUser?.rating.length > 0
        ? viewUser?.rating.reduce((acc: number, r: any) => acc + r.rating, 0) /
          viewUser?.rating.length
        : 0;

    let supervisorRating =
      viewUser?.supervisorRating?.length > 0
        ? viewUser?.supervisorRating.reduce(
            (acc: number, r: any) => acc + r.rating,
            0
          ) / viewUser?.supervisorRating.length
        : 0;

    const overallRating = rating + supervisorRating;
    const avgRating =
      overallRating > 0
        ? Math.round(
            overallRating / (rating > 0 && supervisorRating > 0 ? 2 : 1)
          )
        : 0;
    return avgRating;
  }, [viewUser]);
  

  return (
    <div
      id="kt_help"
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="help"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_view_user_toggle"
      data-kt-drawer-close="#kt_view_user_close"
    >
      {viewUser ? (
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          <div
            className="card-header px-5"
            id="kt_drawer_chat_messenger_header"
          >
            <div className="card-title">
              <div className="d-flex justify-content-center flex-column me-3">
                <a
                  href="#"
                  className="fs-4 fw-bolder text-gray-900  me-1  lh-1"
                >
                  {viewUser.firstName} {viewUser.lastName}
                </a>

                <div className="mb-0 lh-1 fs-7">
                  <span className="px-3 my-0">
                    <div className="rating">
                      <div className="rating-label me-2">
                        <i
                          className={clsx(
                            rating > 0 ? "bi-star-fill" : "bi-star",
                            " bi fs-5 text-warning"
                          )}
                        ></i>
                      </div>
                      <div className="rating-label me-2">
                        <i
                          className={clsx(
                            rating > 1 ? "bi-star-fill" : "bi-star",
                            " bi fs-5 text-warning"
                          )}
                        ></i>
                      </div>
                      <div className="rating-label me-2">
                        <i
                          className={clsx(
                            rating > 2 ? "bi-star-fill" : "bi-star",
                            " bi fs-5 text-warning"
                          )}
                        ></i>
                      </div>
                      <div className="rating-label me-2">
                        <i
                          className={clsx(
                            rating > 3 ? "bi-star-fill" : "bi-star",
                            " bi fs-5 text-warning"
                          )}
                        ></i>
                      </div>
                      <div className="rating-label me-2">
                        <i
                          className={clsx(
                            rating > 4 ? "bi-star-fill" : "bi-star",
                            " bi fs-5 text-warning"
                          )}
                        ></i>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>

            <div className="card-toolbar">
              <div
                className="btn btn-sm btn-icon btn-active-light-primary"
                id="kt_view_user_close"
              >
                <span className="svg-icon svg-icon-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="6"
                      y="17.3137"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-45 6 17.3137)"
                      fill="black"
                    />
                    <rect
                      x="7.41422"
                      y="6"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(45 7.41422 6)"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>

          <ul
            className="nav nav-line-tabs nav-line-tabs-3x nav-stretch fw-bold px-9 bg-light"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item mb-0" role="presentation">
              <button
                className={clsx(
                  "nav-link opacity-75 opacity-state-100 fw-bolder bg-light",
                  activeDrawerTabs.tab1 ? "active" : ""
                )}
                id="task-tab"
                data-bs-toggle="tab"
                data-bs-target="#task"
                type="button"
                role="tab"
                aria-controls="task"
                aria-selected="true"
                onClick={onClickUserInfo}
              >
                User Info
              </button>
            </li>
            <li className="nav-item mb-0" role="presentation">
              <button
                className={clsx(
                  "nav-link opacity-75 opacity-state-100 fw-bolder bg-light p-4",
                  activeDrawerTabs.tab2 ? "active" : ""
                )}
                id="payment-tab"
                data-bs-toggle="tab"
                data-bs-target="#payment"
                type="button"
                role="tab"
                aria-controls="payment"
                aria-selected="false"
                onClick={onClickWorkHistory}
              >
                Work History
              </button>
            </li>
          </ul>
          <div className="card-body p-5" id="kt_drawer_chat_messenger_body">
            <div
              className="scroll-y me-n5 pe-5"
              data-kt-element="messages"
              data-kt-scroll="true"
              data-kt-scroll-activate="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
              data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
              data-kt-scroll-offset="0px"
            >
              <div className="tab-content" id="myTabContent">
                {activeDrawerTabs.tab1 ? (
                  <div
                    className="tab-pane  show active"
                    id="task"
                    role="tabpanel"
                    aria-labelledby="task-tab"
                    style={{ minHeight: "100%" }}
                  >
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Email</span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900">
                        {viewUser.email}
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Phone</span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900 ">
                        {viewUser.phoneNumber}
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Id Card Number</span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900">
                        #{viewUser?.profile?.idCardNo}{" "}
                        <a
                          href="javascript:void(0)"
                          onClick={() => setShowId(!showId)}
                          className="ms-3 fs-7 fw-normal float-end"
                        >
                          <i
                            className={clsx(
                              !showId ? "bi-eye" : "bi-eye-slash",
                              "bi bi-eye-slash text-primary"
                            )}
                          ></i>
                        </a>
                      </div>
                    </div>
                    {showId ? (
                      <>
                        <div className="mt-4">
                          <div
                            className="fs-4 fw-bolder mb-1 text-gray-900"
                            style={{
                              height: 300,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              style={{ height: "100%", width: "100%" }}
                              src={viewUser?.profile?.idCard}
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {viewUser.roles ? (
                      <>
                        <hr />
                        <div className="mt-4">
                          <span className="text-gray-400 fs-7">Roles</span>
                          <div className="fs-4 fw-bolder mb-1 text-gray-900">
                            {viewUser.roles
                              .map((v: any) => {
                                return v.title;
                              })
                              .join(", ")}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Skills</span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900">
                        {viewUser.skills
                          .map((v: any) => {
                            return v.title;
                          })
                          .join(", ")}
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Experience</span>
                      <div className="fs-6 fw-bolder mb-1 text-gray-900">
                        {viewUser.experience.length > 0 ?viewUser.experience.map((v) => (
                          <div>
                            <div>
                              {v.title} - {v.company}
                            </div>
                            <div className="text-gray-600 fs-7">
                              {v.description}
                            </div>
                          </div>
                        )):<div className="text-gray-400 fs-8">Not added yet</div>}

                   
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Bio</span>
                      <div className="fs-6 fw-bolder mb-1 text-gray-900">
                        {viewUser.bio}
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Language</span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900">
                        {viewUser.languageKnown
                          .map((v: any) => {
                            return `${v.language} - ${v.level}`;
                          })
                          .join(", ")}
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">Location</span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900">
                        {viewUser?.profile?.country}
                      </div>
                    </div>
                    <hr />
                    <div className="mt-4">
                      <span className="text-gray-400 fs-7">
                        Preferred Location
                      </span>
                      <div className="fs-4 fw-bolder mb-1 text-gray-900">
                        {viewUser?.profile?.preferredCountry}
                      </div>
                    </div>
                  </div>
                ) : null}
                {activeDrawerTabs.tab2 ? (
                  <div
                    className="tab-pane show active"
                    id="payment"
                    role="tabpanel"
                    aria-labelledby="payment-tab"
                  >
                    <div className="fs-6 text-gray-800 fw-bolder mb-3">
                      Total Hours
                    </div>
                    <div className="d-flex flex-wrap">
                      <div className="rounded min-w-100px p-3 me-3 mb-3 bg-light-success">
                        <div className="d-flex align-items-center">
                          <div className="fs-2 fw-bolder">
                            {
                              viewUser?.rating?.filter(
                                (v: any) => v.projects.length > 0
                              ).length
                            }
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Projects
                        </div>
                      </div>

                      <div className="rounded min-w-100px p-3 me-3 mb-3 bg-light-info">
                        <div className="d-flex align-items-center">
                          <div className="fs-2 fw-bolder">{rating}</div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Overall Rating
                        </div>
                      </div>

                      <div className="rounded min-w-100px p-3 me-3 mb-3 bg-light-danger">
                        <div className="d-flex align-items-center">
                          <div className="fs-2 fw-bolder">
                            {viewUser?.rejectedJobs.length}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Rejected
                        </div>
                      </div>
                    </div>
                    {viewUser?.rating.length > 0 ? (
                      <div className="fv-row row mt-9">
                        <div className="col-12">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-muted bg-light">
                                <th className="text-start">Clients</th>
                                <th className="text-start">Project</th>
                                <th className="text-center">Rating</th>
                              </tr>
                            </thead>
                            <tbody>
                              {viewUser.rating.map((v: any) => {
                                return v.projects.length > 0 ? (
                                  <tr>
                                    <td className="text-start">
                                      {v.projects[0].company[0].companyName}
                                    </td>
                                    <td className="text-start">
                                      {v.projects[0].name}
                                    </td>
                                    <td className="text-center">
                                      {" "}
                                      <div className="rating">
                                        <div className="rating-label me-2">
                                          <i
                                            className={clsx(
                                              v.rating > 0
                                                ? "bi-star-fill"
                                                : "bi-star",
                                              " bi fs-5 text-warning"
                                            )}
                                          ></i>
                                        </div>
                                        <div className="rating-label me-2">
                                          <i
                                            className={clsx(
                                              v.rating > 1
                                                ? "bi-star-fill"
                                                : "bi-star",
                                              " bi fs-5 text-warning"
                                            )}
                                          ></i>
                                        </div>
                                        <div className="rating-label me-2">
                                          <i
                                            className={clsx(
                                              v.rating > 2
                                                ? "bi-star-fill"
                                                : "bi-star",
                                              " bi fs-5 text-warning"
                                            )}
                                          ></i>
                                        </div>
                                        <div className="rating-label me-2">
                                          <i
                                            className={clsx(
                                              v.rating > 3
                                                ? "bi-star-fill"
                                                : "bi-star",
                                              " bi fs-5 text-warning"
                                            )}
                                          ></i>
                                        </div>
                                        <div className="rating-label me-2">
                                          <i
                                            className={clsx(
                                              v.rating > 4
                                                ? "bi-star-fill"
                                                : "bi-star",
                                              " bi fs-5 text-warning"
                                            )}
                                          ></i>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                ) : null;
                              })}
                              {viewUser.rating.filter(
                                (v: any) => v.projects.length > 0
                              ).length === 0 ? (
                                <tr>
                                  <td className="text-center" colSpan={3}>
                                    No data available
                                  </td>
                                </tr>
                              ) : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <img alt="" src="assets/img/5.png" className="h-200px mb-5" />
          <h1 className="fw-bold text-danger">Sorry!</h1>
          <h4 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
            No data available
          </h4>
        </div>
      )}
    </div>
  );
};

export { ViewDrawer };

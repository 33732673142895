/* eslint-disable no-unreachable */
import { useState, useEffect, lazy, Suspense, useMemo } from 'react';
import { useDispatch } from '../../../../../../setup/redux/Store';
import { getUserComment } from '../../../../../redux/Projects/thunks';
import { isFulfilled } from '@reduxjs/toolkit';
import { TailSpin } from 'react-loader-spinner';

//import ReviewInnerComments from './review-inner-comment';

const InnerComment = lazy(() => import('./review-inner-comment'));
const InnerCommentSupervisor = lazy(() => import('./review-inner-comment-supervisor'));

const ReviewComments: React.FC<{
  projectId: string | undefined;
  brandAmbassador: any;
  supervisor: any;
}> = ({ projectId, brandAmbassador, supervisor }) => {

  return (
    <div
      id='kt_drawer_review'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='review'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'360px', 'md': '500px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_drawer_review_toggle'
      data-kt-drawer-close='#kt_drawer_review_close'
    >
      <div
        className='card w-100 rounded-0 border-0'
        id='kt_drawer_review_messenger'
      >
        <div className='card-header px-5' id='kt_drawer_chat_messenger_header'>
          <div className='card-title'>
            <div className='d-flex justify-content-center flex-column me-3'>
              <a
                href='#'
                className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'
              >
                Review
              </a>

              <div className='mb-0 lh-1 fs-7'>
                Overall Rating
                <span className='text-gray-700 px-1 py-1 me-2'>
                  <i className='bi bi-star-fill me-1 text-warning fs-6'></i>
                </span>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-light-primary'
              id='kt_drawer_review_close'
            >
              {/*<!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->*/}
              <span className='svg-icon svg-icon-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect
                    opacity='0.5'
                    x='6'
                    y='17.3137'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-45 6 17.3137)'
                    fill='black'
                  />
                  <rect
                    x='7.41422'
                    y='6'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(45 7.41422 6)'
                    fill='black'
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div className='card-body p-5' id='kt_drawer_chat_messenger_body'>
          {/*<!--begin::Messages-->*/}

          <Suspense
            fallback={
              <div className='d-flex justify-content-center align-items-center'>
                <TailSpin color='green' height={50} width={50} />
              </div>
            }
          >
            <InnerComment data={brandAmbassador} />
            <InnerCommentSupervisor data={supervisor}/>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default ReviewComments;

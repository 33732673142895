/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  useFormik,
} from "formik";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import * as Yup from "yup";
import { ROLES } from "../../../models/app";
import { createValidationSchema } from "../../../utils/validationSchema";
import {
  addJobThunk,
  editJobThunk,
  getRolesAndSkillsThunk,
} from "../../redux/Jobs/thunk";
import { IAddForm } from "../../redux/Admin/types";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "../../../setup/redux/Store";
import { getCompanyThunk } from "../../redux/Admin/thunk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Autosuggest from "react-autosuggest";
import { JOB_ROLE } from "../../../models/app";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import {currencies} from 'currencies.json';


interface IAddModal {
  closeModal: any;
  action: string;
  data?: any;
  country: Array<any>;
}

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -3.745,
  lng: -38.523,
};

const JobModal: FC<IAddModal> = ({
  closeModal,
  country,
  action,
  data,
  ...props
}) => {
  const dispatch: any = useDispatch();
  const { inProgress, companyId, role } = useSelector((state) => ({
    role: state.auth.user?.role,
    inProgress: state.adminJobs.inProgress,
    companyId: state.auth.user?.company?._id,
  }));
  const [company, setCompany] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [roleList, setRoleList] = useState([]);

  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const isSuperAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);

  useEffect(() => {
    const getRolesAndSkills = async () => {
      const response: any = await dispatch(getRolesAndSkillsThunk({}));
      if (isFulfilled(response)) {
        const {
          payload: {
            data: { roles, skills },
          },
        } = response;
        setSkillList(skills);
        setRoleList(roles.filter((v:any)=>!['Project supervisor'].includes(v.title)));
      }
    };

    getRolesAndSkills();
  }, []);

  useEffect(() => {
    const getCompanyList = async () => {
      const response = await dispatch(getCompanyThunk({}));
      if (isFulfilled(response)) {
        setCompany(
          response.payload.data.map((v: any) => ({
            id: v._id,
            companyName: v.companyName,
          }))
        );
      }
    };
    if (isSuperAdmin) {
      getCompanyList();
    }
  }, [companyId]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  }, [navigator.geolocation]);

  useEffect(() => {
    if (data) {
      setFieldValue("id", data._id);
      setFieldValue("title", data.title);
      setFieldValue("startDate", moment(data.startDate).format("YYYY-MM-DD"));
      setFieldValue("endDate", moment(data.endDate).format("YYYY-MM-DD"));
      setFieldValue("location", data.location);
      setFieldValue("duration", data.duration);
      setFieldValue("durationPeriod", data.durationPeriod);
      setFieldValue("jobDescription", data.jobDescription);
      setFieldValue("minExp", data.minExp);
      setFieldValue("maxExp", data.maxExp);
      setFieldValue("role", data.role);
      setFieldValue("subRole", data?.subRole?._id);
      setFieldValue("skillTag", data.skillTag);
      setFieldValue("companyId", data.companyId);
      setFieldValue("currency", data.currency);
      setFieldValue("minWages", data.minWages);
      setFieldValue("maxWages", data.maxWages);
    }
  }, [data]);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    values,
    touched,
    status: formStatus,
  } = useFormik({
    initialValues: {
      id: "",
      title: "",
      startDate: "",
      endDate: "",
      location: data ? data.location : "",
      duration: "",
      durationPeriod: data ? data.durationPeriod : 0,
      jobDescription: "",
      minExp: data ? data.minExp : "",
      maxExp: data ? data.maxExp : "",
      role: data ? data.role : "",
      subRole: data && data?.subRole?._id? data?.subRole?._id:"",
      skillTag: [],
      companyId: companyId?.toString(),
      currency: data ? data.currency : "",
      minWages: data ? data.minWages : 0,
      maxWages: data ? data.maxWages : 0,
    },
    enableReinitialize: true,
    validationSchema: createValidationSchema("addJob"),
    onSubmit: async (values: any, { setStatus }) => {
      if (values.minExp > values.maxExp) {
        toast("Max experience should be greater than Min experience", {
          type: "error",
        });
        return;
      }
      if (new Date(values.startDate) > new Date(values.endDate)) {
        toast("End date should be greater than Start Date experience", {
          type: "error",
        });
        return;
      }
      if (Number(values.minWages) > Number(values.maxWages)) {
        toast("Max wages should be greater than min wages", {
          type: "error",
        });
        return;
      }
      let thunk = addJobThunk(values);
      if (action === "edit") {
        thunk = editJobThunk(values);
      }

      const response: any = await dispatch(thunk);
      if (isFulfilled(response)) {
        closeModal();
        if (action === "edit") {
          toast("Job updated successfully", { type: "success" });
        } else {
          toast("Job added successfully", { type: "success" });
        }
      } else {
        const err: any = response;
        setStatus(err.payload.error);
      }
    },
  });

  const handler = (field: string, event: any) => {
    const {
      target: { value },
    } = event;
    setFieldValue(field, value);
  };

  const autocompleteRenderInput = ({ addTag, ...props }: any) => {
    const handleOnChange = (e: any, { newValue, method }: any) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const inputValue = (props.value && props.value.trim().toLowerCase()) || "";
    const inputLength = inputValue.length;

    let suggestions = skillList.filter((skill: any) => {
      return skill.title.toLowerCase().slice(0, inputLength) === inputValue;
    });

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={suggestions}
        shouldRenderSuggestions={(value) =>
          value && value.trim().length > 0 ? true : false
        }
        getSuggestionValue={(suggestion: any) => suggestion.title}
        {...{
          renderSuggestion: (suggestion: any) => (
            <span className="pointer">{suggestion.title}</span>
          ),
          inputProps: { ...props, onChange: handleOnChange },
          onSuggestionSelected: (e, { suggestion }) => {
            addTag(suggestion.title);
          },
          onSuggestionsClearRequested: () => {},
          onSuggestionsFetchRequested: () => {},
        }}
      />
    );
  };

  const onChangeRole = (event:any)=>{
    handler("role", event)
    setFieldValue('subRole','')
  }

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h2 style={{margin:20}}>{action === "add" ? "Add" : "Edit"} Job</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => closeModal()}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-1"
          />
        </div>
      </div>

      <div
        className="modal-body scroll-y mx-5 mx-xl-15 my-7"
        style={{ maxHeight: 500 }}
      >
        <div className="d-flex flex-column" id="kt_modal_add_user_scroll">
          <div className="fv-row row ">
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Job Title</label>
                <input
                  type="text"
                  name="user_name"
                  defaultValue={values.title}
                  onKeyUp={(event) => handler("title", event)}
                  onBlur={handleBlur}
                  className="form-control form-control-solid mb-3 mb-lg-0"
                />
                {errors.title && touched.title ? (
                  <div className="text-danger">{errors.title}</div>
                ) : null}
              </div>

              <div className="fv-row row ">
                <div className="col-md-6 mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="user_email"
                    defaultValue={values.startDate}
                    onChange={(event) => handler("startDate", event)}
                    onBlur={handleBlur}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {errors.startDate && touched.startDate ? (
                    <div className="text-danger">{errors.startDate}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-7">
                  <label className="required fw-bold fs-6 mb-2">End Date</label>
                  <input
                    type="date"
                    name="user_email"
                    defaultValue={values.endDate}
                    onChange={(event) => handler("endDate", event)}
                    onBlur={handleBlur}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {errors.endDate && touched.endDate ? (
                    <div className="text-danger">{errors.endDate}</div>
                  ) : null}
                </div>
              </div>
              <div className="fv-row row ">
                <div className="col-md-6 mb-7">
                  <label className="required fw-bold fs-6 mb-2">Location</label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    defaultValue={values.location}
                    onChange={(event) => handler("location", event)}
                    onBlur={handleBlur}
                  >
                    <option value={""}>Select</option>
                    {country.map((v: any) => (
                      <option value={v.value}>{v.label}</option>
                    ))}
                  </select>
                  {errors.location && touched.location ? (
                    <div className="text-danger">{errors.location}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-7">
                  <label className="required fw-bold fs-6 mb-2">Duration</label>
                  <div className="input-group">
                    <input
                      type="number"
                      placeholder="Duration"
                      name="user_email"
                      defaultValue={values.duration}
                      onKeyUp={(event) => handler("duration", event)}
                      onBlur={handleBlur}
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                    <select
                      className="form-select form-select-solid mb-3 mb-lg-0"
                      defaultValue={values.durationPeriod}
                      onChange={(event) => handler("durationPeriod", event)}
                      onBlur={handleBlur}
                    >
                      <option value={""}>Select</option>
                      <option value={"day"}>Day(s)</option>
                      <option value={"week"}>Week(s)</option>
                      <option value={"month"}>Month(s)</option>
                    </select>
                  </div>
                  {errors.duration && touched.duration ? (
                    <div className="text-danger">{errors.duration}</div>
                  ) : null}
                  {errors.durationPeriod && touched.durationPeriod ? (
                    <div className="text-danger">{errors.durationPeriod}</div>
                  ) : null}
                </div>
              </div>
              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Job Description
                </label>
                <textarea
                  className="form-control form-control-solid"
                  defaultValue={values.jobDescription}
                  onKeyUp={(event) => handler("jobDescription", event)}
                  onBlur={handleBlur}
                  rows={3}
                  name="message"
                  placeholder=""
                ></textarea>
                {errors.jobDescription && touched.jobDescription ? (
                  <div className="text-danger">{errors.jobDescription}</div>
                ) : null}
              </div>

              <div className="fv-row row ">
                <div className="col-md-6 mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Minimum Experience
                  </label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    defaultValue={values.minExp}
                    onChange={(event) => handler("minExp", event)}
                    onBlur={handleBlur}
                  >
                    <option value={""}>Select</option>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                  </select>
                  {errors.minExp && touched.minExp ? (
                    <div className="text-danger">{errors.minExp}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Maximum Experience
                  </label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    defaultValue={values.maxExp}
                    onChange={(event) => handler("maxExp", event)}
                    onBlur={handleBlur}
                  >
                    <option value={""}>Select</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                  </select>
                  {errors.maxExp && touched.maxExp ? (
                    <div className="text-danger">{errors.maxExp}</div>
                  ) : null}
                </div>
              </div>

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Role</label>
                <select
                  className="form-select form-select-solid mb-3 mb-lg-0"
                  defaultValue={values.role}
                  onChange={(event) => onChangeRole(event)}
                  onBlur={handleBlur}
                >
                  <option value={""}>Select</option>
                  <option value={JOB_ROLE.AMBASSADOR}>Brand Ambassador</option>
                  <option value={JOB_ROLE.SUPERVISOR}>Supervisor</option>
                </select>
                {errors.role && touched.role ? (
                  <div className="text-danger">{errors.role}</div>
                ) : null}
              </div>
              { values.role === JOB_ROLE.AMBASSADOR?<div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">SubRole</label>
                <select
                  className="form-select form-select-solid mb-3 mb-lg-0"
                  value={values.subRole}
                  onChange={(event) => handler("subRole", event)}
                  onBlur={handleBlur}
                >
                  <option value={""}>Select</option>
                  {roleList.map((v:any)=><option value={v._id}>{v.title}</option>)}
                </select>
                {errors.subRole && touched.subRole ? (
                  <div className="text-danger">{errors.subRole}</div>
                ) : null}
              </div>: null}

              <div className="fv-row mb-7">
                <label className=" fw-bold fs-6 mb-2">Skill Tags</label>
                <TagsInput
                  renderInput={autocompleteRenderInput}
                  className="form-control form-control-solid"
                  value={values.skillTag}
                  onChange={(event) =>
                    setFieldValue("skillTag", Array.from(new Set(event)))
                  }
                />
                {errors.skillTag && touched.skillTag ? (
                  <div className="text-danger">{errors.skillTag}</div>
                ) : null}
              </div>
              <div className="fv-row row ">
                <div className="col-md-4 mb-7">
                  <label className="required fw-bold fs-6 mb-2">Currency</label>
                  <select
                  className="form-select form-select-solid mb-3 mb-lg-0"
                  defaultValue={values.currency}
                  onChange={(event) => handler("currency", event)}
                  onBlur={handleBlur}
                >
                  <option value={""}>Select</option>
                  {currencies.map((v:any)=><option value={v.code}>{v.code} - {v.name}</option>)}
                </select>
                  {errors.currency && touched.currency ? (
                    <div className="text-danger">{errors.currency}</div>
                  ) : null}
                </div>
                <div className="col-md-4 mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Min wages
                  </label>
                  <input
                    type="number"
                    name="minWages"
                    min={0}
                    defaultValue={values.minWages}
                    onChange={(event) => handler("minWages", event)}
                    onBlur={handleBlur}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {errors.minWages && touched.minWages ? (
                    <div className="text-danger">{errors.minWages}</div>
                  ) : null}
                </div>
                <div className="col-md-4 mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Max Wages
                  </label>
                  <input
                    type="number"
                    name="maxWages"
                    min={0}
                    defaultValue={values.maxWages}
                    onChange={(event) => handler("maxWages", event)}
                    onBlur={handleBlur}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {errors.maxWages && touched.maxWages ? (
                    <div className="text-danger">{errors.maxWages}</div>
                  ) : null}
                </div>
              </div>
              {isSuperAdmin ? (
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">Company</label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    value={values.companyId}
                    onChange={(event) => handler("companyId", event)}
                  >
                    <option value={""}>Select</option>
                    {company.map((v: any) => (
                      <option value={v.id}>{v.companyName}</option>
                    ))}
                  </select>
                  {errors.companyId && touched.companyId ? (
                    <div className="text-danger">{errors.companyId}</div>
                  ) : null}
                </div>
              ) : null}
            </div>
            {formStatus ? (
              <div className="alert alert-danger">
                <div className="alert-text font-weight-bold">{formStatus}</div>
              </div>
            ) : null}
            <div className="text-center mb-5">
              <button
                type="button"
                className="btn btn-light me-3"
                onClick={() => closeModal()}
              >
                Discard
              </button>
              <button
                type="submit"
                disabled={inProgress}
                className="btn btn-primary"
                onClick={() => handleSubmit()}
              >
                <span className="indicator-label">Submit</span>
              </button>
            </div>
          </div>
        </div>
        {/* <>
          <LoadScript googleMapsApiKey="AIzaSyAEH84lP0ccDtATeqPKNmCgt_TcyK5N6-A">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
            >
              <Marker draggable={true} position={currentLocation} />
              <></>
            </GoogleMap>
          </LoadScript>
        </> */}
      </div>
    </div>
  );
};

export default JobModal;

import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const getJobThunk = createAsyncThunk(
    '/getJob', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/getJob', data, {responseType:data?.export?'arraybuffer':'json'})
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)


export const addJobThunk = createAsyncThunk(
    '/addJob', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/addJob', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const editJobThunk = createAsyncThunk(
    '/editJob', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/editJob', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const getJobByIdThunk = createAsyncThunk(
    '/getJobById', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/getJobById', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const getAppliedUserThunk = createAsyncThunk(
    '/getAppliedUser', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/getAppliedUser', data, {responseType:data?.export?'arraybuffer':'json'})
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const publishJobThunk = createAsyncThunk(
    '/publishJob', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/publishJob', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const deleteJobThunk = createAsyncThunk(
    '/deleteJob', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/deleteJob', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const inactiveJobThunk = createAsyncThunk(
    '/inactiveJob', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/inActiveJob', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const jobActivateThunk = createAsyncThunk(
    '/jobActivate', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/activateJob', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const updateJobAppliedStatusThunk = createAsyncThunk(
    '/updateJobAppliedStatus', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/updateJobAppliedStatus', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const getRolesAndSkillsThunk =  createAsyncThunk(
    '/getRolesAndSkills', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/jobs/getRolesAndSkills', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)



export enum PROJECT_STATUS {
  DELETE = 0,
  ACTIVE = 1,
  PUBLISHED = 2,
  COMPLETED = 3,
  INACTIVE = 4,
}

export enum PAYMENT_MODE {
  PENDING = 0,
  PROCESSING = 1,
  PAID = 2,
  REJECTED = 3
  
}

export enum ROLE {
  USER = 'user',
  SUPERVISOR = 'supervisor',
}

export enum TAG_PROJECT_TO_JOB {
  YES = 1,
  NO = 0,
}


export enum TASKSTATUS {
  APPROVED = 1,
  WAITING = 0,
  REJECT = 2,
  HOLD = 3
}
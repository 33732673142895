
import { createSlice } from '@reduxjs/toolkit'
import { IChats } from './types';
import { getGroups } from './thunk';

const initialState: IChats = {
    inProgress: false,
    error: '',
    viewUser:null
}

const groupSlice = createSlice({
    name: 'chatGroups',
    initialState,
    reducers: {
    },
    // Async actions
    extraReducers: (builder) => {
        builder.addCase(getGroups.pending, (state, action) => {
            state.inProgress = true;
            state.error = null;
          });
          builder.addCase(getGroups.rejected, (state, action) => {
            state.inProgress = false;
          });
          builder.addCase(getGroups.fulfilled, (state, action) => {
            state.inProgress = false;
          });
    }
})

export default groupSlice.reducer

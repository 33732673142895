const SmallCard: React.FC<{ num: number; text: string }> = ({ num, text }) => {
  return (
    <div className='border border-gray-300 border-dashed rounded min-w-100px p-3 me-3 mb-3'>
      <div className='d-flex align-items-center'>
        <div className='fs-2 fw-bolder'>{num}</div>
      </div>

      <div className='fw-bold fs-6 text-gray-400'>{text}</div>
    </div>
  );
};

export default SmallCard;

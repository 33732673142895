import { FC, useRef, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { getUserByTokenThunk } from '../../redux/Auth/thunk'
import { logoutAction } from '../../redux/Auth/slice';
import { RootState } from '../../../setup'
import { useDispatch, useSelector } from '../../../setup/redux/Store';

const AuthInit: FC<any> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { accessToken, user } = useSelector((state) => ({
    accessToken: state.auth.accessToken,
    user: state.auth.user
  }))

  const navigate = useNavigate();
  const location = useLocation();
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {

      try {  
        if (!user) {
          await dispatch(getUserByTokenThunk(accessToken as string))
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(logoutAction())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
    } else {
      if(!(location.pathname).includes('/auth/invite/')  && !(location.pathname).includes('/auth/forgot-password') && 
      !(location.pathname).includes('/auth/resetPassword/')){
        dispatch(logoutAction())
        navigate('/auth/login')
      }
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [accessToken])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default AuthInit

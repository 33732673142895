import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "../../../setup/redux/Store";
import {
  getJobThunk,
  publishJobThunk,
  deleteJobThunk,
  jobActivateThunk,
  inactiveJobThunk,
} from "../../redux/Jobs/thunk";
import { KTCardBody } from "../../../_metronic/helpers";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { isFulfilled } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ROLES, JOB_ROLE, JOB_STATUS } from "../../../models/app";
import { UsersListLoading } from "../../../_metronic/layout/components/loading/UsersListLoading";
import ReactPaginate from "react-paginate";
import { KTSVG, useDebounce } from "../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import moment from "moment";
import { getCountriesThunk } from "../../redux/Freelancer/thunk";
import JobModal from "./jobModal";
import ExportModal from "./export";

const itemsPerPage = 10;
const initFilter = {
  status: "",
  role: "",
  country: "",
  startDate: "",
  endDate: "",
};
const deleteModalStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const customStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const JobsTable = () => {
  const { companyId, role, inProgress } = useSelector((state) => ({
    role: state.auth.user?.role,
    inProgress: state.adminJobs.inProgress,
    companyId: state.auth.user?.company?._id,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [country, setCountry] = useState([]);
  const [search, setSearch] = useState<string>("");
  const [jobs, setJobs] = useState([]);
  const [sort, setSort] = useState({ field: "", order: "asc" });
  const [total, setTotal] = useState(0);
  const [multiFilter, setMultiFilter] = useState(initFilter);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDeleteConfirmation, setDeleteOpenConfirmation] = useState(false);
  const [jobData, setJobData] = useState<any>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [action, setAction] = useState("add");
  const [filter, setFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const isAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);


  const getJobList = async () => {
    const response = await dispatch(getJobThunk({ ...filter, companyId }));
    if (isFulfilled(response)) {
      setTotal(response.payload.count);
      setJobs(response.payload.data);
    }
    MenuComponent.reinitialization();
  };

  useEffect(() => {
    const getCountry = async () => {
      const response = await dispatch(getCountriesThunk());
      if (isFulfilled()) {
        setCountry(response.payload.data);
      }
    };
    getCountry();
  }, []);

  useEffect(() => {
    if (filter && Object.keys(filter).length > 0 ) {
      getJobList();
    }
  }, [filter]);

  const isFilterApplied = useMemo(()=>{
    return Object.keys(filter.filter).filter((v)=>!!filter.filter[v]).length > 0
  },[filter.filter])

  const deleteJob = async (data: any) => {
    const result = await dispatch(deleteJobThunk({ id: data._id }));
    if (isFulfilled(result)) {
      toast("Job deleted successfully", { type: "success" });
      setDeleteOpenConfirmation(false);
      getJobList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const inactiveJob = async (data: any) => {
    const result = await dispatch(inactiveJobThunk({ id: data._id }));
    if (isFulfilled(result)) {
      toast("Job In-active  successfully", { type: "success" });
      setOpenConfirmation(false);
      getJobList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const publishJob = async (data: any, hasNotification: boolean) => {
    const result = await dispatch(
      publishJobThunk({ id: data._id, hasNotification })
    );
    if (isFulfilled(result)) {
      toast("Job published successfully", { type: "success" });
      setJobData(null);
      getJobList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const jobActivate = async (data: any) => {
    const result = await dispatch(jobActivateThunk({ id: data._id }));
    if (isFulfilled(result)) {
      toast("Job activated successfully", { type: "success" });
      setOpenConfirmation(false);
      getJobList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const jobInActive = (data: any) => {
    setOpenConfirmation(true);
    setJobData(data);
  };
  const jobDelete = (data: any) => {
    setDeleteOpenConfirmation(true);
    setJobData(data);
  };

  const editJob = (data: any) => {
    setAction("edit");
    setJobData(data);
    openModal();
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    getJobList();
    setJobData(null);
    setAction("add");
    setModalIsOpen(false);
  };

  const onSelect = (state: boolean, data: any) => {
    const selected: string[] = [...selectedData];
    if (state) {
      selected.push(data._id.toString());
    } else {
      const index = selected.findIndex((v) => v === data._id);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setSelectedData(selected);
  };

  const onSelectAll = (state: boolean) => {
    if (state) {
      const selected: string[] = jobs.map((v: any) => v._id.toString());
      setSelectedData(selected);
    } else {
      setSelectedData([]);
    }
  };

  const handlePageClick = (event: any) => {
    setFilter({ ...filter, page: event.selected });
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      setFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  const changeSort = (field: string) => {
    if (sort.field === field) {
      let order = "";
      let sortField = "";
      if (sort.order === "") {
        order = "asc";
        sortField = field;
      } else if (sort.order === "asc") {
        order = "desc";
        sortField = field;
      }
      setSort({ field: sortField, order: order });
      setFilter({ ...filter, sort: sortField, order: order });
    } else {
      setSort({ field, order: "asc" });
      setFilter({ ...filter, sort: field, order: "asc" });
    }
  };

  const onChangeFilter = (field: string, value: any) => {
    const filt: any = { ...multiFilter };
    filt[field] = value;
    setMultiFilter(filt);
  };

  const resetFilter = () => {
    setMultiFilter(initFilter);
    setFilter({ ...filter, filter: initFilter });
  };

  const applyFilter = () => {
    setFilter({ ...filter, filter: multiFilter });
  };

  const download = async (type:string) => {
    const response = await dispatch(
      getJobThunk({ ...filter, companyId, export: type })
    );
    if (isFulfilled(response)) {
      const blob = new Blob([response.payload], { type: type === 'csv'?"text/csv":(type === 'pdf'? "application/pdf":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8") });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Jobs.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const openExportModel = ()=>{
    setExportModalIsOpen(true)
  }

  const closeExportModel = ()=>{
    setExportModalIsOpen(false)
  }

  const viewJob = (data: any) => {
    navigate(`/view_job/${data._id}`);
  };

  return (
    <>
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search job"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            <>
              <button
                disabled={inProgress}
                type="button"
                className={clsx("btn btn-light-primary me-3 d-flex align-items-center",isFilterApplied?"btn-success":"")}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-2"
                />
                Filter
              </button>

              <div
                className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                data-kt-menu="true"
              >
                <div className="px-7 py-5">
                  <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                </div>

                <div className="separator border-gray-200"></div>

                <div
                  className="px-7 py-5"
                  data-kt-user-table-filter="form"
                  style={{ overflow: "auto", maxHeight: 350 }}
                >
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">
                      Start Date:
                    </label>
                    <input
                      type="date"
                      name="user_email"
                      value={multiFilter.startDate}
                      onChange={(event) =>
                        onChangeFilter("startDate", event.target.value)
                      }
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">End Date:</label>
                    <input
                      value={multiFilter.endDate}
                      type="date"
                      name="user_email"
                      onChange={(event) =>
                        onChangeFilter("endDate", event.target.value)
                      }
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Status:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      onChange={(e) => onChangeFilter("status", e.target.value)}
                      value={multiFilter.status}
                    >
                      <option value="">Select</option>
                      <option value={JOB_STATUS.PUBLISHED}>Published</option>
                      <option value={JOB_STATUS.DRAFT}>Draft</option>
                      <option value={JOB_STATUS.IN_ACTIVE}>In-active</option>
                    </select>
                  </div>
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Role:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      onChange={(e) => onChangeFilter("role", e.target.value)}
                      value={multiFilter.role}
                    >
                      <option value="">Select</option>
                      <option value={JOB_ROLE.AMBASSADOR}>Brand Ambassador</option>
                      <option value={JOB_ROLE.SUPERVISOR}>Supervisor</option>
                    </select>
                  </div>
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Country:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      onChange={(e) =>
                        onChangeFilter("country", e.target.value)
                      }
                      value={multiFilter.country}
                    >
                      <option value="">Select</option>
                      {!country ? (
                        <option value="">Select</option>
                      ) : (
                        country.map((v: any) => (
                          <option value={v.value}>{v.label}</option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={inProgress}
                      onClick={resetFilter}
                      className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="reset"
                    >
                      Reset
                    </button>
                    <button
                      disabled={inProgress}
                      type="button"
                      onClick={applyFilter}
                      className="btn btn-primary fw-bold px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="filter"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </>

            <button
              onClick={openExportModel}
              type="button"
              className="btn btn-light-primary me-3 d-flex align-items-center"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr078.svg"
                className="svg-icon-2"
              />
              Export
            </button>

            <button
              type="button"
              className="btn btn-primary d-flex"
              onClick={openModal}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              Add Job
            </button>
          </div>
        </div>
      </div>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                {/* <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check={selectedData.length === total}
                      data-kt-check-target="#kt_table_users .form-check-input"
                      checked={selectedData.length === total}
                      onChange={({ target: { checked } }) =>
                        onSelectAll(checked)
                      }
                    />
                  </div>
                </th> */}
                <th
                  onClick={() => changeSort("title")}
                  className={clsx(
                    "min-w-150px pointer",
                    sort.field === "title" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Job Title
                </th>
                <th
                  onClick={() => changeSort("startDate")}
                  className={clsx(
                    "min-w-150px pointer",
                    sort.field === "startDate" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Job Start Date
                </th>
                <th
                  onClick={() => changeSort("endDate")}
                  className={clsx(
                    "min-w-150px pointer",
                    sort.field === "endDate" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Job End Date
                </th>
                <th
                  onClick={() => changeSort("role")}
                  className={clsx(
                    "min-w-140px pointer",
                    sort.field === "role" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Role
                </th>
                <th
                  onClick={() => changeSort("location")}
                  className={clsx(
                    "min-w-120px pointer",
                    sort.field === "location" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Location
                </th>
                <th className="min-w-120px">Status</th>
                <th className="min-w-100px text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {jobs && jobs.length > 0 ? (
                jobs.map((v: any, key: number) => (
                  <tr key={key}>
                    {/* <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          data-kt-check={selectedData.includes(
                            v._id.toString()
                          )}
                          data-kt-check-target="#kt_table_users .form-check-input"
                          checked={selectedData.includes(v._id.toString())}
                          onChange={({ target: { checked } }) =>
                            onSelect(checked, v)
                          }
                        />
                      </div>
                    </td> */}
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href="javascript:void(0)"
                          onClick={() => viewJob(v)}
                          className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                        >
                          {v.title} - {v.jobNo}
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href="javascript:void(0)"
                          className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                        >
                          {moment(v.startDate).format("DD-MM-YYYY")}
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href="javascript:void(0)"
                          className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                        >
                          {moment(v.endDate).format("DD-MM-YYYY")}
                        </a>
                      </div>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark text-hover-primary d-block mb-1 fs-6 text-capitalize"
                      >
                        {v.role.replace("_", " ")}
                      </a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark text-hover-primary d-block mb-1 fs-6 text-capitalize"
                      >
                        {v.location}
                      </a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark  text-hover-primary d-block mb-1 fs-6"
                      >
                        <div
                          className={clsx(
                            "badge fw-bolder",
                            v.status === 3 ? " badge-light-danger" : "",
                            v.status === 1 ? "badge-light-success" : "",
                            v.status === 2 ? "badge-light-success" : ""
                          )}
                        >
                          {v.status === 3
                            ? "Inactive"
                            : v.status === 1
                            ? "Draft"
                            : "Published"}
                        </div>
                      </a>
                    </td>
                    <td className="d-flex justify-content-center">
                      <a
                        href="javascript:void(O)"
                        className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        Actions
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr072.svg"
                          className="svg-icon-5 m-0"
                        />
                      </a>
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                        data-kt-menu="true"
                      >
                        {v.status !== 3 ? (
                          <>
                           {v.status !== 2? <><div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => publishJob(v, false)}
                              >
                                Publish without notification
                              </a>
                            </div>
                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => publishJob(v, true)}
                              >
                                Publish with notification
                              </a>
                            </div></>: null}
                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => viewJob(v)}
                              >
                                View
                              </a>
                            </div>

                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => editJob(v)}
                              >
                                Edit
                              </a>
                            </div>
                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => jobInActive(v)}
                              >
                                In-activate
                              </a>
                            </div>
                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => jobDelete(v)}
                              >
                                Delete
                              </a>
                            </div>
                          </>
                        ) : (
                          <div className="menu-item">
                            <a
                              className="menu-link"
                              onClick={() => jobActivate(v)}
                            >
                              Activate
                            </a>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      No record found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{margin:20}}>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={total / itemsPerPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={itemsPerPage}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
          // forcePage={pageOffset}
        />
        </div>
        {inProgress && <UsersListLoading />}
      </KTCardBody>
      <Modal
        isOpen={openConfirmation}
        // onRequestClose={closeModal}
        style={deleteModalStyles}
        id={"delete_confirmation"}
      >
        <div className="container m-5">
          <h1>Inactivate Job</h1>
          <p>Are you sure you want to in-activate this job?</p>
          <div className="clearfix mt-5 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setOpenConfirmation(false)}
              className="btn btn-sm btn-secondary me-3"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => inactiveJob(jobData)}
              className="btn btn-sm btn-danger"
            >
              In-activate
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openDeleteConfirmation}
        // onRequestClose={closeModal}
        style={deleteModalStyles}
        id={"delete_confirmation"}
      >
        <div className="container m-5">
          <h1>Delete Job</h1>
          <p>Are you sure you want to delete this job?</p>
          <div className="clearfix mt-5 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setDeleteOpenConfirmation(false)}
              className="btn btn-sm btn-secondary me-3"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => deleteJob(jobData)}
              className="btn btn-sm btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        id={"job_management"}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <JobModal
          country={country}
          closeModal={closeModal}
          action={action}
          data={jobData}
        />
      </Modal>
      <Modal
        isOpen={exportModalIsOpen}
        id={'export_project'}
        onRequestClose={closeExportModel}
        style={customStyles}
      >
       <ExportModal closeModal={closeExportModel} download={download}/>
      </Modal>
    </>
  );
};

export { JobsTable };

import React, { FC, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { KTCard } from "../../../_metronic/helpers";
import { JobsTable } from "./table";

const JobManagement: FC = () => {
  return (
    <>
      {" "}
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
          >
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              Job Management
            </h1>
            <span className="h-20px border-gray-300 border-start mx-4"></span>
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li className="breadcrumb-item text-muted">
                <a
                  href="javascript:void(0)"
                  className="text-muted text-hover-primary"
                >
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-300 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-dark">Jobs</li>
            </ul>
          </div>
        </div>
      </div>
      <KTCard>
        <JobsTable />
      </KTCard>
    </>
  );
};

export default JobManagement;

import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { Main } from '../partials'
import { MenuComponent } from '../assets/ts/components'
import { RightToolbar } from '../partials/layout/RightToolbar';

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    // setTimeout(() => {
      MenuComponent.reinitialization()
    // }, 2000)
  }, [])

  useEffect(() => {
    // setTimeout(() => {
      MenuComponent.reinitialization()
    // }, 2000)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <RightToolbar />
      <Main />
    </PageDataProvider>
  )
}

export { MasterLayout }

/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { AsideMenuItem } from "./AsideMenuItem";
import { useSelector } from "../../../../setup/redux/Store";
import { ROLES } from "../../../../models/app";

export function AsideMenuMain() {
  const { role } = useSelector((state) => ({
    role: state.auth.user?.role,
  }));

  return (
    <>
      {/* {role && role !== ROLES.SUPER_ADMIN && ( */}
      <AsideMenuItem
        to="/job_management"
        icon="/media/icons/headhunting.svg"
        title="Job Management"
        fontIcon="bi-app-indicator"
      />
      {/* )} */}
      {/* {role && role !== ROLES.SUPER_ADMIN && ( */}
      <AsideMenuItem
        to="/project_management"
        icon="/media/icons/project.svg"
        title="Project Management"
        fontIcon="bi-layers"
      />
      {/* )} */}
      {role && (role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN) && (
        <>
          <AsideMenuItem
            to="/app_user"
            title="App Users"
            icon="/media/icons/app-user.svg"
            fontIcon="bi-person"
          />

          <AsideMenuItem
            to="/user_management"
            title="User Management"
            icon="/media/icons/user.svg"
            fontIcon="bi-person"
          />

          <AsideMenuItem
            to="/chat_management"
            icon="/media/icons/chat.svg"
            title="Chat Management"
            fontIcon="bi-app-indicator"
          />
        </>
      )}
    </>
  );
}


import { createSlice, AnyAction } from '@reduxjs/toolkit'
import { getUserThunk, updateStatusThunk, blacklistThunk } from './thunk';
import { IFreelancer } from './types';


const initialState: IFreelancer = {
    inProgress: false,
    error: '',
    viewUser:null
}

const adminSlice = createSlice({
    name: 'freelancers',
    initialState,
    reducers: {
        viewUserAction:(state,action)=>{
            state.viewUser = action.payload;
        }
    },
    // Async actions
    extraReducers: (builder) => {
        //get User list
        builder.addCase(getUserThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(getUserThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(getUserThunk.rejected, (state, action) => {
            state.inProgress = false;
        })
        // update status
        builder.addCase(updateStatusThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(updateStatusThunk.fulfilled, (state, action: AnyAction) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(updateStatusThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        //blacklist/unblacklist user
        builder.addCase(blacklistThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(blacklistThunk.fulfilled, (state, action: AnyAction) => {
            state.inProgress = false;
            state.error = null
        })
        builder.addCase(blacklistThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

    }
})

export const { viewUserAction } = adminSlice.actions
export default adminSlice.reducer

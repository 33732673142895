import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUtils } from './type';
import {
  createPayment,
  updatePayment,
  deletePayment,
  getUsersPayments,
  getProjectManagersThunk,
  getCountries,
  getUserComment,
  getTaskThunk,
  editATask,
  getPaidPayment,
  getProjectById
} from './thunks';

const initialState: IUtils = {
  inProgress: true,
  projectManagers: [],
  paymentData: [],
  loader: false,
  taskReintialize: false,
  PaymentReintialize: false,
  reloadProject: false
};

const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // get the task
    builder.addCase(editATask.rejected, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(editATask.fulfilled, (state, action) => {
      state.inProgress = false;
      state.taskReintialize = true;
    });
    builder.addCase(editATask.pending, (state, action) => {
      state.inProgress = true;
    });

    //get tasks
    builder.addCase(getTaskThunk.fulfilled, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(getTaskThunk.rejected, (state, action) => {
      state.inProgress = false;
      state.PaymentReintialize = false;
    });
    builder.addCase(getTaskThunk.pending, (state, action) => {
      state.inProgress = true;
    });

    // get user paid payment getUserPaidPayments
    builder.addCase(getPaidPayment.pending, (state, action) => {
      //state.paymentData = [];
      state.inProgress = true;
    });

    builder.addCase(
      getPaidPayment.fulfilled,
      (state, action: PayloadAction<{ success: boolean; data: any }>) => {
        const id: string = action.payload.data._id;
        const deletePaymentIndex = state.paymentData.findIndex(
          (el: any) => el._id === id
        );
        state.inProgress = false;
        state.paymentData.splice(deletePaymentIndex, 1);
        state.PaymentReintialize = false;
      }
    );

    //get payment
    builder.addCase(getUsersPayments.pending, (state, action) => {
      //state.paymentData = [];
      state.inProgress = true;
    });
    builder.addCase(
      getUsersPayments.fulfilled,
      (
        state,
        action: PayloadAction<{ success: boolean; data: []; status: boolean }>
      ) => {
        state.inProgress = false;
        state.PaymentReintialize = false;
      }
    );
    //update payment
    builder.addCase(
      updatePayment.fulfilled,
      (state, action: PayloadAction<{ success: boolean; data: any }>) => {
        state.inProgress = false;
        state.PaymentReintialize = true;
      }
    );
    //delete payment
    builder.addCase(
      deletePayment.fulfilled,
      (state, action: PayloadAction<{ success: boolean; data: any }>) => {
        const id: string = action.payload.data._id;
        const deletePaymentIndex = state.paymentData.findIndex(
          (el: any) => el._id === id
        );
        state.inProgress = false;
        state.paymentData.splice(deletePaymentIndex, 1);
        state.PaymentReintialize = true;
      }
    );
    //create payment
    builder.addCase(createPayment.fulfilled, (state, action) => {
      state.paymentData.push(action.payload.data);
      state.PaymentReintialize = true;
    });

    // get comment
    builder.addCase(
      getUserComment.fulfilled,
      (state, action: PayloadAction<{ success: boolean; data: [] }>) => {}
    );

    //countries
    builder.addCase(
      getCountries.fulfilled,
      (state, action: PayloadAction<{ success: boolean; data: [] }>) => {
        state.inProgress = false;
        //state.location = action.payload.data;
      }
    );

    //project-managers
    builder.addCase(getProjectManagersThunk.pending, (state, action) => {});
    builder.addCase(
      getProjectManagersThunk.fulfilled,
      (state, action: PayloadAction<{ success: boolean; data: [] }>) => {
        state.projectManagers = action.payload.data;
      }
    );

    builder.addCase(getProjectById.pending, (state, action) => {
      state.loader = true;
    });
    builder.addCase(getProjectById.fulfilled,(state, action) => {
      state.loader = false;
    });
    builder.addCase(getProjectById.rejected, (state, action) => {
      state.loader = false;
    });

    
  },
});

export const roleAction = slice.actions;
export default slice.reducer;

import { number } from "yup";

export enum ROLES {
    SUPER_ADMIN = "super_admin",
    ADMIN = "admin",
    PROJECT_MANAGER = 'project_manager'
}

export enum USER_STATUS {
    INVITE = 0,
    ACTIVE = 1,
    INACTIVE = 2
}

export enum ADMIN_STATUS {
    PENDING_VERIFICATION = 0,
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}


export enum JOB_ROLE {
    AMBASSADOR = 'brand_ambassador',
    SUPERVISOR = 'supervisor'
}

export enum JOB_STATUS {
    DELETE = 0,
    DRAFT = 1,
    PUBLISHED = 2,
    IN_ACTIVE = 3,
}

export enum APPLIED_JOBS {
    REJECTED = 0,
    PROCESSING = 1,
    APPROVED = 2,
    TRAINING = 3
}

export enum APP_USER_PROFILE_STATUS {
    INITIAL = 'inital',
    CREATE_PASSWORD = 'create_password',
    VERIFY_OTP = 'verify_otp',
    CREATE_PROFILE = 'create_profile',
    ROLES_AND_SKILLS = 'roles_and_skills',
    WORK_EXPERIENCE = 'work_experience',
    LANGUAGE_KNOWN = 'language_known',
    BIO = 'bio',
    PROOF = 'proof',
    PREVIEW = 'preview',
    COMPLETED = 'completed'
}

export const APP_USER_PROGRESS_STATUS = {
    [APP_USER_PROFILE_STATUS.INITIAL]: 1,
    [APP_USER_PROFILE_STATUS.CREATE_PASSWORD]: 2,
    [APP_USER_PROFILE_STATUS.VERIFY_OTP]: 3,
    [APP_USER_PROFILE_STATUS.CREATE_PROFILE]: 4,
    [APP_USER_PROFILE_STATUS.ROLES_AND_SKILLS]: 5,
    [APP_USER_PROFILE_STATUS.WORK_EXPERIENCE]: 6,
    [APP_USER_PROFILE_STATUS.LANGUAGE_KNOWN]: 7,
    [APP_USER_PROFILE_STATUS.BIO]: 8,
    [APP_USER_PROFILE_STATUS.PROOF]: 9,
    [APP_USER_PROFILE_STATUS.PREVIEW]: 10,
    [APP_USER_PROFILE_STATUS.COMPLETED]: 11
}

export enum JOB_ROLES {
    AMBASSADOR = 'brand_ambassador',
    SUPERVISOR = 'supervisor'
}

import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'


export const loginThunk = createAsyncThunk(
  'auth/login', async (data: any, { rejectWithValue }) => {
    try {
      const response:any = await axios.post(`/auth/login`, data)
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const getUserByTokenThunk = createAsyncThunk(
  'auth/getUserByToken', async (token: string, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/getUserByTokenThunk', {
        token : token
      })
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const registerThunk = createAsyncThunk(
  'auth/register', async (data:any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/auth/register`, data)
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const requestPasswordThunk = createAsyncThunk(
  'auth/register', async (email: string, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/auth/forgot_password`, {
        email: email
      })
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const passwordResetThunk = createAsyncThunk(
  'auth/passwordReset', async (data:any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/passwordReset', data)
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const getUserByUidThunk = createAsyncThunk(
  'auth/getUserByUid', async (data:any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/auth/getUserByUid', data)
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const uploadPhotoThunk = createAsyncThunk(
  'auth/uploadPhoto', async (data:any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(`/auth/uploadPhoto`, data, {headers:{'content-type': 'multipart/form-data'}})
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

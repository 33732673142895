 import {combineReducers} from 'redux'

import auth from '../../app/redux/Auth/slice';
import admin from '../../app/redux/Admin/slice';
import freelancers from '../../app/redux/Freelancer/slice';
import adminJobs from '../../app/redux/Jobs/slice';
import project from '../../app/redux/Projects/projectSlice';
import groups from '../../app/redux/Chat/slice';
import slice from '../../app/redux/Projects/slice';
export const RootReducer = combineReducers({
  auth,
  admin,
  freelancers,
  adminJobs,
  project,
  groups,
  slice,
})

export type RootState = ReturnType<typeof RootReducer>
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from "react";
import { KTSVG } from "../../../../_metronic/helpers/components/KTSVG";
import { useDispatch, useSelector } from "../../../../setup/redux/Store";
import Multiselect from "multiselect-react-dropdown";
import { useFormik } from "formik";
import {
  editProjectThunk,
  getAllProjects,
  getProjectById,
  getProjectUsers,
} from "../../../redux/Projects/thunks";
import { toast } from "react-toastify";
import { createValidationSchema } from "../../../../utils/validationSchema";
import { isFulfilled } from "@reduxjs/toolkit";
import { getAppUserThunk, getUserThunk } from "../../../redux/Admin/thunk";
import { editGroupThunk } from "../../../redux/Chat/thunk";
import { useDebounce } from "../../../../_metronic/helpers";

const initFilter = {
  projectManager: "",
  startDate: "",
  endDate: "",
  status: "",
  location: "",
  name: "",
  supervisor: "",
};

const initUserFilter = {
  status: "",
  role: "",
};
/** NOTE IT IS USED FOR BOTH ADDING AND EDITING MODAL FOR THE PROJECT COMPONENT */
const EditGroup: React.FC<{
  action: string;
  closeModal: any;
  data: any;
  country: any;
  fetchAllGroups?: any;
}> = ({ closeModal, data, action, fetchAllGroups }) => {
  const { companyId, inProgress } = useSelector((state) => ({
    companyId: state.auth.user?.company?._id,
    role: state.auth.user?.role,
    inProgress: state.project.inProgress,
    project: state.project,
  }));


  const itemsPerPage = 200;
  const [filter, setFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const [userFilter, setUserFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initUserFilter,
  });

  const dispatch = useDispatch();
  function transformData(data:any) {
    const assignToUsers = data.userIds ? data.userIds.map((user:any) => ({ id: user._id, name: user.firstName + ' ' + user.lastName })) : [];
    const assignToAppUsers = data.appUserIds ? data.appUserIds.map((user:any) => ({ id: user._id, name: user.firstName + ' ' + user.lastName })) : [];
    
    return {
        assignToUsers,
        assignToAppUsers,
    };
}
const transformedData = transformData(data);

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    values,
    touched,
    status: formStatus,
  } = useFormik({
    initialValues: {
      groupId : data._id,
      name: data.name ? data.name : ``,
      assignToUsers: transformedData.assignToUsers,
      assignToAppUsers: transformedData.assignToAppUsers,
      projectId: data?.projectId ? data?.projectId : "",
    },
    enableReinitialize: true,
    validationSchema: createValidationSchema("addChatGroup"),
    onSubmit: async (values: any, { setStatus }) => {

      const payload = {
        name: values.name,
        userIds: values.assignToUsers.map((v:any) => v.id),
        appUserIds: values.assignToAppUsers.map((v:any) => v.id),
        projectId: values.projectId,
        groupId: values.groupId ,
    };
    
      const response: any = await dispatch(editGroupThunk(payload));
      if (isFulfilled(response)) {
        closeModal();
        if (action === "edit") {
          toast("Group updated successfully", { type: "success" });
        } else {
          toast("Group added successfully", { type: "success" });
        }
        fetchAllGroups && fetchAllGroups();
      } else {
        const err: any = response;
        setStatus(err.payload.error);
      }
    },
  });

  const [loadingAppUsers, setLoadingAppUsers] = useState<boolean>(true);
  const [loadingProjectUsers, setLoadingProjectUsers] = useState<boolean>(true);
  const [appUsers, setAppUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [userList, setUserList] = useState<any>([]);
  const [appUserSearch,setAppUserSearch] =useState<string>("");
  const debouncedSearchTerm = useDebounce(appUserSearch, 2000);

  useEffect(() => {
    const getAppUserList = async () => {
      const response: any = await dispatch(
        getAppUserThunk({ ...userFilter })
      );
      if (isFulfilled(response)) {
        if (response.payload.status) {
          setAppUsers(
            response.payload.data.map((v: any) => ({
              id: v._id,
              name: `${v.firstName} ${v.lastName}`,
            }))
          );
          setLoadingAppUsers(false);
        }
      } else {
        setLoadingAppUsers(false);
        setAppUsers([]);
      }
    };
    getAppUserList();
  }, [userFilter.search]);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && appUserSearch !== undefined) {
      setUserFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  const handleSearch = (searchValue:string) => {
    setAppUserSearch(searchValue)
  };

  useEffect(() => {
    const getProjectUsersList = async () => {
      const response: any = await dispatch(
        getProjectUsers({id: values?.projectId})
      );
      if (isFulfilled(response)) {
        setLoadingProjectUsers(false);
        const data=response.payload.data[0];

        const brandAmabassador=data.brandAmbassador.map((v:any)=>({
          id: v.userId,
          name: `${v.brandAmbassadorUser[0].firstName} ${v.brandAmbassadorUser[0].lastName}`,
        }));
        
        const supervisor=data.supervisor.map((v:any)=>({
          id: v.userId,
          name: `${v.supervisorUser[0].firstName} ${v.supervisorUser[0].lastName}`,
        }));
        
        const projectManager=data.projectManager.map((v:any)=>({
          id: v._id,
          name: `${v.firstName} ${v.lastName}`,
        }));

        const combinedUserList = [
          ...brandAmabassador,
          ...supervisor,
          ...projectManager,
        ];

        setUserList(combinedUserList);
      } else {
        setLoadingProjectUsers(false);
        setUserList([]);
      }
    };

    values?.projectId && getProjectUsersList();
  }, [values?.projectId]);



  useEffect(() => {
    const getProjectsList = async () => {
      const response: any = await dispatch(
        getAllProjects({ ...filter, companyId })
      );
      if (isFulfilled(response)) {
        setProjects(
          response.payload.data.map((v: any) => ({
            id: v._id,
            projectName: v.name,
          }))
        );
      }
    };
    getProjectsList();
  }, []);

  const onChangeUserHandler = (data: any) => {
     setFieldValue("assignToUsers", data);
  };

  const onChangeAppUserHandler = (data: any) => {
    setFieldValue("assignToAppUsers", data);
  };

  const onChangeProject = (value: any) => {
    setFieldValue("projectId", value);
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h2 style={{ margin: 20 }}>
            {action === "edit" ? "Edit Group" : "Add Group"}
          </h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => closeModal()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon-1"
            />
          </div>
        </div>

        <div
          className="modal-body scroll-y mx-5 mx-xl-15 my-7"
          style={{ maxHeight: 500 }}
        >
          <div className="d-flex flex-column" id="kt_modal_add_user_scroll">
            <div className="fv-row row ">
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7"
                id="kt_modal_add_user_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_add_user_header"
                data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Group Name
                  </label>
                  <input
                    required
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={({ target: { value } }) =>
                      setFieldValue("name", value)
                    }
                    onBlur={handleBlur}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </div>

                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">Project</label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    value={values.projectId}
                    onChange={({ target: { value } }) => onChangeProject(value)}
                  >
                    <option value={""}>Select</option>
                    {projects.map((v: any) => (
                      <option value={v.id}>{v.projectName}</option>
                    ))}
                  </select>
                  {errors.projectId && touched.projectId ? (
                    <div className="text-danger">{errors.projectId}</div>
                  ) : null}
                </div>

                <div className="fv-row mb-7">
                  <label className=" fw-bold fs-6 mb-2">Select Project Users</label>{" "}
                  <Multiselect
                    className="form-control form-control-solid border border-transparent"
                    loading={loadingProjectUsers}
                    onSelect={onChangeUserHandler}
                    onRemove={onChangeUserHandler}
                    displayValue="name"
                    placeholder="Select Users"
                    selectedValues={values.assignToUsers}
                    options={userList}
                    style={{
                      searchBox: {
                        border: "none",
                        "border-radius": "0px",
                      },
                    }}
                  />
                  {errors.assignToUsers && touched.assignToUsers ? (
                    <div className="text-danger">{errors.assignToUsers}</div>
                  ) : null}
                </div>

                <div className="fv-row mb-7">
                  <label className=" fw-bold fs-6 mb-2">Select Additional Users</label>{" "}
                  <Multiselect
                    className="form-control form-control-solid border border-transparent"
                    loading={loadingAppUsers}
                    onSelect={onChangeAppUserHandler}
                    onSearch={handleSearch}
                    onRemove={onChangeAppUserHandler}
                    displayValue="name"
                    placeholder="Select App Users"
                    selectedValues={values.assignToAppUsers}
                    options={appUsers}
                    style={{
                      searchBox: {
                        border: "none",
                        "border-radius": "0px",
                      },
                    }}
                  />
                  {errors.assignToAppUsers && touched.assignToAppUsers ? (
                    <div className="text-danger">{errors.assignToAppUsers}</div>
                  ) : null}
                </div>

                <div className="text-center mb-5">
                  <button
                    type="button"
                    className="btn btn-light me-3"
                    onClick={() => closeModal()}
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    disabled={inProgress}
                    className="btn btn-primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    <span className="indicator-label">Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditGroup;

import { createSlice } from '@reduxjs/toolkit';
import { IState } from './type';
import {
  createProject,
  deleteProjectThunk,
  getAllProjects,
  getProjectById,
  editProjectStatus,
  editProjectThunk,
  getProjectTotalHoursThunk,
} from './thunks';

const initialState: IState = {
  inProgress: true,
  error: '',
  projectHours: 0,
  reloadProject: false
};

const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    reloadProjectAction:(state,action)=> {
      state.reloadProject = action.payload;
    }
  },
  extraReducers: (builder) => {
    // get project total hours
    builder.addCase(getProjectTotalHoursThunk.rejected, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(getProjectTotalHoursThunk.pending, (state, action) => {
      state.projectHours = 0;
      state.inProgress = true;
    });
    builder.addCase(getProjectTotalHoursThunk.fulfilled, (state, action) => {
      state.inProgress = false;
      state.projectHours = action.payload.data.toFixed(2);
    });

    // edit project status
    builder.addCase(editProjectStatus.pending, (state, action) => {
      state.inProgress = true;
    });

    builder.addCase(editProjectStatus.fulfilled, (state, action) => {
      state.inProgress = false;
    });

    builder.addCase(editProjectStatus.rejected, (state, action) => {
      state.inProgress = false;
    });

    builder.addCase(editProjectThunk.pending, (state, action) => {
      state.inProgress = true;
    });

    builder.addCase(editProjectThunk.fulfilled, (state, action) => {
      state.inProgress = false;
    });

    builder.addCase(editProjectThunk.rejected, (state, action) => {
      state.inProgress = false;
    });
  

    //get a project by id
    builder.addCase(getProjectById.fulfilled, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(getProjectById.pending, (state, action) => {
      state.inProgress = true;
    });

    // delete a project
    builder.addCase(deleteProjectThunk.pending, (state, action) => {
      state.inProgress = true;
    });
    builder.addCase(deleteProjectThunk.fulfilled, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(deleteProjectThunk.rejected, (state, action) => {
      state.inProgress = false;
    });

    // getAllProjects
    builder.addCase(getAllProjects.pending, (state, action) => {
      state.inProgress = true;
      state.error = null;
    });
    builder.addCase(getAllProjects.rejected, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      state.inProgress = false;
    });

    //creating projects
    builder.addCase(createProject.pending, (state, action) => {
      state.inProgress = true;
      state.error = null;
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.inProgress = false;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.inProgress = false;
    });
  },
});


export const { reloadProjectAction } = projectSlice.actions;
export default projectSlice.reducer;

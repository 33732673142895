/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  Formik,
  Form,
  FormikValues,
  Field,
  ErrorMessage,
  useFormik,
} from "formik";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import * as Yup from "yup";
import { ROLES } from "../../../models/app";
import { createValidationSchema } from "../../../utils/validationSchema";
import { addUserThunk, editUserThunk } from "../../redux/Admin/thunk";
import { IAddForm } from "../../redux/Admin/types";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "../../../setup/redux/Store";
import { getCompanyThunk } from "../../redux/Admin/thunk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

interface IAddModal {
  closeModal: any;
  action: string;
  data?: any;
}

const AddModal: FC<IAddModal> = ({ closeModal, action, data, ...props }) => {
  const dispatch: any = useDispatch();
  const { inProgress, companyId, role } = useSelector((state) => ({
    role: state.auth.user?.role,
    inProgress: state.admin.inProgress,
    companyId: state.auth.user?.company?._id,
  }));
  const [company, setCompany] = useState([]);

  const isAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);

  useEffect(() => {
    setFieldValue("company", companyId);
  }, [companyId]);

  useEffect(() => {
    const getCompanyList = async () => {
      const response = await dispatch(getCompanyThunk({}));
      if (isFulfilled(response)) {
        setCompany(
          response.payload.data.map((v: any) => ({
            id: v._id,
            companyName: v.companyName,
          }))
        );
      }
    };
    getCompanyList();
  }, []);

  useEffect(() => {
    if (data) {
      setFieldValue("id", data._id);
      setFieldValue("firstName", data.firstName);
      setFieldValue("lastName", data.lastName);
      setFieldValue("email", data.email);
      setFieldValue("contact", data.phoneNumber);
      setFieldValue("company", data?.company?._id);
      setFieldValue("role", data.role);
    }
  }, [data]);

  const {
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
    errors,
    values,
    touched,
    status: formStatus,
  } = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      contact: "",
      company: "",
      role: "",
    },
    enableReinitialize: true,
    validationSchema: createValidationSchema("admin"),
    onSubmit: async (values: IAddForm, { setStatus }) => {
      let thunk = addUserThunk(values);
      if (action === "edit") {
        thunk = editUserThunk(values);
      }
      const response: any = await dispatch(thunk);
      if (isFulfilled(response)) {
        closeModal();
        if (action === "edit") {
          toast("User updated successfully", { type: "success" });
        } else {
          toast("User added successfully", { type: "success" });
        }
      } else {
        const err: any = response;
        setStatus(err.payload.error);
      }
    },
  });

  const handler = (field: string, event: any) => {
    const {
      target: { value },
    } = event;
    setFieldValue(field, value);
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h2 style={{margin:20}}>{action === "add" ? "Add" : "Edit"} User</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={() => closeModal()}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon-1"
          />
        </div>
      </div>

      <div
        className="modal-body scroll-y mx-5 mx-xl-15 my-7"
        style={{ maxHeight: 500 }}
      >
        <div className="d-flex flex-column" id="kt_modal_add_user_scroll">
          <div className="fv-row row ">
            <div className="col-md-6 mb-7">
              <label className="required fw-bold fs-6 mb-2">First Name</label>
              <input
                type="text"
                name="firstName"
                defaultValue={values.firstName}
                onKeyUp={(event) => handler("firstName", event)}
                onBlur={handleBlur}
                className="form-control form-control-solid mb-3 mb-lg-0"
              />
              {errors.firstName && touched.firstName ? (
                <div className="text-danger">{errors.firstName}</div>
              ) : null}
            </div>

            <div className="col-md-6 mb-7">
              <label className="required fw-bold fs-6 mb-2">Last Name</label>
              <input
                type="text"
                name="lastName"
                defaultValue={values.lastName}
                onKeyUp={(event) => handler("lastName", event)}
                onBlur={handleBlur}
                className="form-control form-control-solid mb-3 mb-lg-0"
              />
              {errors.lastName && touched.lastName ? (
                <div className="text-danger">{errors.lastName}</div>
              ) : null}
            </div>
          </div>
          <div className="fv-row row ">
            <div className="col-md-6 mb-7">
              <label className="required fw-bold fs-6 mb-2">Email</label>
              <input
                type="text"
                name="email"
                defaultValue={values.email}
                onKeyUp={(event) => handler("email", event)}
                onBlur={handleBlur}
                className="form-control form-control-solid mb-3 mb-lg-0"
              />
              {errors.email && touched.email ? (
                <div className="text-danger">{errors.email}</div>
              ) : null}
            </div>
            <div className="col-md-6 mb-7">
              <label className="required fw-bold fs-6 mb-2">Contact</label>
              <PhoneInput
               
                placeholder="Enter phone number"
                value={values.contact}
                onChange={(event) => setFieldValue("contact", event)}
              />

              {errors.contact && touched.contact ? (
                <div className="text-danger">{errors.contact}</div>
              ) : null}
            </div>
          </div>
          {isAdmin ? (
            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">Company</label>
              <select
                className="form-select form-select-solid mb-3 mb-lg-0"
                value={values.company}
                onChange={(event) => handler("company", event)}
              >
                <option value={""}>Select</option>
                {company.map((v: any) => (
                  <option value={v.id}>{v.companyName}</option>
                ))}
              </select>
              {errors.company && touched.company ? (
                <div className="text-danger">{errors.company}</div>
              ) : null}
            </div>
          ) : null}
          <div className="fv-row mb-7">
            <label className="required fw-bold fs-6 mb-2">Role</label>
            <select
              className="form-select form-select-solid mb-3 mb-lg-0"
              value={values.role}
              onChange={(event) => handler("role", event)}
            >
              <option value={""}>Select</option>
              <option value={ROLES.ADMIN}>Admin</option>
              <option value={ROLES.PROJECT_MANAGER}>Project Manager</option>
            </select>
            {errors.role && touched.role ? (
              <div className="text-danger">{errors.role}</div>
            ) : null}
          </div>
        </div>
        {formStatus ? (
          <div className="alert alert-danger">
            <div className="alert-text font-weight-bold">{formStatus}</div>
          </div>
        ) : null}
        <div className="text-center mb-5">
          <button
            type="button"
            className="btn btn-light me-3"
            onClick={() => closeModal()}
          >
            Discard
          </button>
          <button
            type="submit"
            disabled={inProgress}
            className="btn btn-primary"
            onClick={() => handleSubmit()}
          >
            <span className="indicator-label">Submit</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddModal;

import { useNavigate } from "react-router";
import { KTCard } from "../../../../_metronic/helpers";
import Chat from "./Chat";

const ChatManagement = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/project_management");
  };

  return (
    <>
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
          >
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              Project-Details
            </h1>
            <span className="h-20px border-gray-300 border-start mx-4"></span>
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li className="breadcrumb-item text-muted">
                <a
                  href="../../demo1/dist/index.html"
                  className="text-muted text-hover-primary"
                >
                  Home
                </a>
              </li>
              <li className="breadcrumb-item ">
                <span className="bullet bg-gray-300 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">Project Management</li>
              <li className="breadcrumb-item ">
                <span className="bullet bg-gray-300 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-dark">Group Chat</li>
            </ul>
          </div>
          <div>
            {/* <button
                  className="btn btn-secondary btn-sm text-center me-2"
                  onClick={getProject}
                >
                  {!loader ? (
                    <i className="bi bi-arrow-repeat fs-5 p-0"></i>
                  ) : (
                    <TailSpin color="green" height={15} width={15} />
                  )}
                </button> */}
            <button className="btn btn-sm btn-secondary" onClick={back}>
              Back
            </button>
          </div>
        </div>
      </div>
      <Chat />
    </>
  );
};

export default ChatManagement;

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import TaskDrawerTab from "./task-drawer-tab";
import PaymentDrawerTab from "./payment-drawer-tab";
import TimeFormat from "hh-mm-ss";
import { DrawerComponent } from '../../../../../../_metronic/assets/ts/components'
import moment from "moment";
import { durationInHours } from "../../../../../../utils/map-options";

const ProjectUserDrawer: React.FC<{
  fieldName: string,
  data: any;
  projectId: string | undefined;
  currency:string;
}> = ({ fieldName, data, projectId, currency }) => {

  let [activeDrawerTabs, setActiveDrawerTabs] = useState({
    tab1: true,
    tab2: false,
  });

  const onClickUserInfo = () => {
    setActiveDrawerTabs({ tab1: true, tab2: false });
  };
  const onClickWorkHistory = (event: any) => {
    setActiveDrawerTabs({ tab1: false, tab2: true });
  };

  useEffect(() => {
    onClickUserInfo();
    DrawerComponent.reinitialization();
  }, []);


  const ref = useRef(null);

  const totalHours = useMemo(() => {
  
    if (data && data.activities && data.activities.length > 0) {
      const total = data.activities.reduce((total: number, hrs: any) => {
          if (hrs.checkoutTime && hrs.checkinTime) {
            const seconds = Math.abs(
              (new Date(hrs.checkinTime).getTime() -
                new Date(hrs.checkoutTime).getTime()) /
                1000,
            );
            return total + seconds;
          } else {
            return total;
          }
        }, 0);
        return durationInHours(total)
    } else {
      return 0;
    }
  }, [data]);

  return (
    <div
      ref={ref}
      id="kt_drawer_chat"
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="chat"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'360px', 'md': '500px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_drawer_chat_toggle"
      data-kt-drawer-close="#kt_drawer_chat_close"
    >
      <div
        className="card w-100 rounded-0 border-0"
        id="kt_drawer_chat_messenger"
      >
        <div className="card-header px-5" id="kt_drawer_chat_messenger_header">
          <div className="card-title">
            <div className="d-flex justify-content-center flex-column me-3">
              <a
                href="javascript:(0)"
                className="fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1 text-capatilize"
              >
                {data?.data &&
                  `${data?.data?.firstName} ${data?.data?.lastName}`}
              </a>

              <div className="mb-0 lh-1 fs-7">
                Work hour so far
                <span className="badge badge-success rounded-pill mw-100px ms-2">
                  <i className="bi bi-clock fs-8 text-white me-1"></i>{" "}
                  {totalHours}
                </span>
              </div>
            </div>
          </div>
        </div>

        <ul
          className="nav nav-line-tabs nav-line-tabs-3x nav-stretch fw-bold px-9 bg-light"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item mb-0" role="presentation">
            <button
              className={`nav-link opacity-75 opacity-state-100 fw-bolder bg-light ${
                activeDrawerTabs.tab1 && "active"
              }`}
              id="task-tab"
              data-bs-toggle="tab"
              data-bs-target="#task"
              type="button"
              role="tab"
              aria-controls="task"
              aria-selected="true"
              onClick={onClickUserInfo}
            >
              Task
            </button>
          </li>
          <li className="nav-item mb-0" role="presentation">
            <button
              className={`nav-link opacity-75 opacity-state-100 fw-bolder bg-light p-4 ${
                activeDrawerTabs.tab2 && "active"
              }`}
              id="payment-tab"
              data-bs-toggle="tab"
              data-bs-target="#payment"
              type="button"
              role="tab"
              aria-controls="payment"
              aria-selected="false"
              onClick={onClickWorkHistory}
            >
              Payment
            </button>
          </li>
        </ul>
        <div className="card-body p-5" id="kt_drawer_chat_messenger_body">
          <div
            className="scroll-y me-n5 pe-5"
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="true"
            data-kt-scroll-height="80vh"
            data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
            data-kt-scroll-offset="0px"
          >
            <div className="tab-content" id="myTabContent">
              {/*** showing the userInfo page component */}
              {
                activeDrawerTabs.tab1 && data?
                <TaskDrawerTab fieldName={fieldName} data={data.data} activities={data.activities} projectId={projectId} />:null
              }
              {
                activeDrawerTabs.tab2 &&data?
                <PaymentDrawerTab  currency={currency} data={data} projectId={projectId} />:null
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ProjectUserDrawer };

import { FC, useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { AuthPage } from "../pages/auth/AuthPage";
import { Logout } from "../pages/auth/Logout";
import { App } from "../App";
import * as _redux from "../../setup";
import store, { useSelector, useDispatch } from "../../setup/redux/Store";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { accessToken } = useSelector((state) => ({
    accessToken: state.auth.accessToken,
  }));

  useEffect(() => {
    _redux.setupSocket(store);
  }, []);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {accessToken ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/job_management" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };

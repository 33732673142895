import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Navigate, Routes} from 'react-router-dom'
import { logoutAction } from '../../redux/Auth/slice'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(logoutAction())
    document.location.reload()
  }, [dispatch])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}

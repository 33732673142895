import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "../../../../../../setup/redux/Store";
import moment from "moment";
import {
  createPayment,
  deletePayment,
  updatePayment,
  getUsersPayments,
  getTaskThunk,
} from "../../../../../redux/Projects/thunks";
import { isFulfilled } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { KTCard } from "../../../../../../_metronic/helpers/components/KTCard";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { TailSpin } from "react-loader-spinner";
import { reloadProjectAction } from "../../../../../redux/Projects/projectSlice";
import clsx from "clsx";

const PaymentStatus: React.FC<{
  id: string;
  paymentStatus: number;
}> = ({ id, paymentStatus }) => {
  const [paymentState, setPaymentState] = useState<string>("Yet to Pay");
  const onChangeToProcessingHandler = (text: string) => {
    _editPaymentStatus(id, 1);
    setPaymentState(text);
  };

  const dispatch = useDispatch();

  const _editPaymentStatus = async (id: string, paymentStatus: number) => {
    const response = await dispatch(updatePayment({ id, paymentStatus }));
    if (isFulfilled(response)) {
      dispatch(reloadProjectAction(true));
      toast("Payment is been updated..", { type: "success" });
    } else {
      toast("Could not update Payment, try again", { type: "error" });
    }
  };

  const onChangeToPaidHandler = (text: string) => {
    _editPaymentStatus(id, 2);
    setPaymentState(text);
  };

  const onChangeToNotPaidYetHandler = (text: string) => {
    _editPaymentStatus(id, 0);
    setPaymentState(text);
  };

  useEffect(() => {
    switch (paymentStatus) {
      case 0: {
        setPaymentState("Yet to Pay");

        break;
      }
      case 1: {
        setPaymentState("Processing");

        break;
      }
      case 2: {
        setPaymentState("Paid");
        break;
      }
    }
  }, [paymentStatus]);

  return (
    <>
      {paymentState === "Paid" ? (
        <a className="text text-success">{paymentState}</a>
      ) : (
        <Menu
          menuButton={
            <a
              className={`text ${
                paymentState === "Yet to Pay"
                  ? "text-danger"
                  : paymentState === "Paid"
                  ? "text-success"
                  : "text-primary"
              } pointer`}
              //href='javascript:void(0)'
            >
              {paymentState}
            </a>
          }
        >
          {paymentState !== "Yet to Pay" ? (
            <MenuItem onClick={() => onChangeToNotPaidYetHandler("Yet to Pay")}>
              Yet to Pay
            </MenuItem>
          ) : null}

          {paymentState !== "Processing" ? (
            <MenuItem onClick={() => onChangeToProcessingHandler("Processing")}>
              Processing
            </MenuItem>
          ) : null}
          {paymentState !== "Paid" ? (
            <MenuItem onClick={() => onChangeToPaidHandler("Paid")}>
              Paid
            </MenuItem>
          ) : null}
        </Menu>
      )}
    </>
  );
};

const PaymentDrawerSection: React.FC<{
  data: any;
  projectId: string | undefined;
  currency: string;
}> = ({ data, projectId, currency }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [rate, setRate] = useState<string>("");
  const [hourOfWork, setHourOfWork] = useState<string>("");
  const [comment, setComment] = useState<string>("");
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [showComments, setShowComments] = useState(-1);

  const setRateHandler = (e: any) => setRate(e.target.value);
  const setHourOfWorkHandler = (e: any) => setHourOfWork(e.target.value);
  const setCommentHandler = (e: any) => setComment(e.target.value);
  const [activities, setActivities] = useState([]);
  const resetHandler = () => {
    setComment("");
    setRate("");
    setHourOfWork("");
  };

  const userId: string | undefined = useMemo(() => data.data._id, [data]);

  const { taskReintialize } = useSelector((store) => ({
    taskReintialize: store.slice.taskReintialize,
  }));

  const [tableData, setTableData]: any = useState<Array<{}>>([]);

  const getPayments = async () => {
    const response:any = await dispatch(getUsersPayments({ userId, projectId }));
    if (isFulfilled(response)) {
      setTableData(response.payload.data);
      setInProgress(false);
    } else {
      setTableData([]);
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (projectId && userId) {
      getPayments();
    }
  }, [projectId, userId]);

  const showFormHandler = () => setShow((prev) => !prev);

  const amount: string = useMemo(
    () => (+rate * +hourOfWork).toString(),
    [rate, hourOfWork]
  );

  const savePaymentData = async () => {
    if (!hourOfWork || +hourOfWork <= 0 || isNaN(+hourOfWork)) {
      toast("Please enter the valid hours", { type: "error" });
      return;
    }
    if (!amount || +amount <= 0 || isNaN(+amount)) {
      toast("Please enter the valid rate", { type: "error" });
      return;
    }
    const remainingHours = (
      approvedHours -
      tableData.reduce((acc: number, v: any) => acc + v.totalApprovedHours, 0)
    ).toFixed(2);
    if (+remainingHours < Number(hourOfWork)) {
      toast(
        `Approved hours is less than entered hours, remaining hrs (${+remainingHours})`,
        { type: "error" }
      );
      return;
    }
    const response = await dispatch(
      createPayment({
        projectId,
        userId,
        totalApprovedHours: +hourOfWork,
        amount: +rate,
        paymentComment: comment,
        paymentStatus: 0,
      })
    );
    if (isFulfilled(response)) {
      toast("Payment is saved", { type: "success" });
      resetHandler();
    } else {
      setInProgress(false);
    }
    getPayments();
  };

  const _deletePayment = async (id: string) => {
    const response = await dispatch(deletePayment({ id }));
    if (isFulfilled(response)) {
      dispatch(reloadProjectAction(true));
      toast("Payment is been deleted ..", { type: "success" });
      resetHandler();
    } else {
      toast("Could not delete Payment, try again", { type: "error" });
    }
    getPayments();
  };

  const getTasks = async () => {
    const result:any = await dispatch(
      getTaskThunk({ projectMappingId: data.index, userId: data.data._id })
    );
    if (isFulfilled(result)) {
      setActivities(result.payload.data);
    }
  };

  useEffect(() => {
    if (data) {
      getTasks();
    }
  }, [data]);

  const totalHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities.reduce((total: number, hrs: any) => {
        const workedHours = Number(
          (new Date(hrs.checkoutTime).getTime() -
            new Date(hrs.checkinTime).getTime()) /
            1000 /
            3600
        );
        return (
          total +
          (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
        );
      }, 0);
    } else {
      return 0;
    }
  }, [activities]);

  const approvedHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities
        .filter((v: any) => v.approvalStatus === 1)
        .reduce((total: number, hrs: any) => {
          const workedHours = Number(
            (new Date(hrs.checkoutTime).getTime() -
              new Date(hrs.checkinTime).getTime()) /
              1000 /
              3600
          );
          return (
            total +
            (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
          );
        }, 0);
    } else {
      return 0;
    }
  }, [activities]);

  const rejectedHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities
        .filter((v: any) => v.approvalStatus === 2)
        .reduce((total: number, hrs: any) => {
          const workedHours = Number(
            (new Date(hrs.checkoutTime).getTime() -
              new Date(hrs.checkinTime).getTime()) /
              1000 /
              3600
          );
          return (
            total +
            (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
          );
        }, 0);
    } else {
      return 0;
    }
  }, [activities]);

  const unapproveHours = useMemo(() => {
    if (activities && activities.length > 0) {
      return activities
        .filter((v: any) => v.approvalStatus === 0)
        .reduce((total: number, hrs: any) => {
          const workedHours = Number(
            (new Date(hrs.checkoutTime).getTime() -
              new Date(hrs.checkinTime).getTime()) /
              1000 /
              3600
          );
          return (
            total +
            (Number.isNaN(workedHours) || workedHours < 0 ? 0 : workedHours)
          );
        }, 0);
    } else {
      return 0;
    }
  }, [activities]);

  return (
    <React.Fragment>
      {inProgress && (
        <div className="d-flex justify-content-center align-items-center">
          <TailSpin color="green" height={50} width={50} />
        </div>
      )}

      {!inProgress && (
        <div
          className="tab-pane fade show active"
          id="payment"
          role="tabpanel"
          aria-labelledby="payment-tab"
        >
          <div className="fs-6 text-gray-800 fw-bolder mb-3">Hours</div>
          <div className="d-flex flex-wrap">
            <div className="roundedx p-3 me-3 mb-3 bg-light-success">
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bolder">{totalHours.toFixed(2)}</div>
              </div>

              <div className="fw-bold fs-6 text-gray-400">Total Hours</div>
            </div>
            <div className="roundedx p-3 me-3 mb-3 bg-light-success">
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bolder">{approvedHours.toFixed(2)}</div>
              </div>

              <div className="fw-bold fs-6 text-gray-400">Approved</div>
            </div>

            <div className="roundedx p-3 me-3 mb-3 bg-light-warning">
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bolder">
                  {unapproveHours.toFixed(2)}
                </div>
              </div>

              <div className="fw-bold fs-6 text-gray-400">Unapproved</div>
            </div>

            <div className="roundedx p-3 me-3 mb-3 bg-light-danger">
              <div className="d-flex align-items-center">
                <div className="fs-2 fw-bolder">{rejectedHours.toFixed(2)}</div>
              </div>

              <div className="fw-bold fs-6 text-gray-400">Rejected</div>
            </div>
          </div>
          <div className="fv-row row mt-9">
            <div className="col-12">
              <KTCard>
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fw-bolder text-muted bg-light">
                      <th className="text-center ">Date</th>
                      <th className="text-center">Hour</th>
                      <th className="text-center">Rate/hour<br/>(in {currency})</th>
                      <th className="text-center">Amount<br/>(in {currency})</th>
                      <th className="text-center">Status</th>
                      <th className="text-center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData && tableData.length > 0 ? (
                      tableData.map((el: any, index: number) => {
                        //const id = el._id;
                        return (
                          <>
                            <tr key={index}>
                              <td className="text-center ">
                                {moment(el.createdAt).format("DD MMM YYYY")}
                              </td>
                              <td className="text-center">
                                {el.totalApprovedHours}
                              </td>
                              <td className="text-center">{el.amount}</td>
                              <td className="text-center">{(el.amount * el.totalApprovedHours).toFixed(2)}</td>
                              <td className="text-center">
                                <PaymentStatus
                                  id={el._id}
                                  paymentStatus={el.paymentStatus}
                                />
                              </td>
                              <td className="min-w-30px">
                                {el.paymentComment ? (
                                  <a
                                    title="View Comments"
                                    //href='javascript:(0)'
                                    className="btn btn-link text-danger fs-6 p-0"
                                    onClick={() =>
                                      setShowComments(
                                        showComments === -1 ||
                                          showComments != index
                                          ? index
                                          : -1
                                      )
                                    }
                                  >
                                    <i
                                      className={clsx(
                                        showComments !== index
                                          ? "bi-eye"
                                          : "bi-eye-slash",
                                        "bi bi-eye-slash text-primary"
                                      )}
                                    ></i>
                                  </a>
                                ) : null}
                                
                                {el.paymentStatus !== 2?<a
                                  title="Delete"
                                  //href='javascript:(0)'
                                  className="btn btn-link text-danger fs-6 p-0"
                                  onClick={() => _deletePayment(el._id)}
                                >
                                  <i className="bi bi-trash-fill text-danger"></i>
                                </a>:null}
                              </td>
                            </tr>
                            {el.paymentComment && showComments === index ? (
                              <tr className="table-row-dashed">
                                <td colSpan={6}>
                                  <b>Comments:</b>{" "}
                                  <span className="text-muted">
                                    {el.paymentComment}
                                  </span>
                                </td>
                              </tr>
                            ) : null}
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8}>
                          <div className="d-flex  w-100  text-center justify-content-center">
                            No record found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </KTCard>
              <div>
                <a
                  className="btn btn-link"
                  data-bs-toggle="collapse"
                  //href='#pay-1'
                  role="button"
                  aria-expanded="false"
                  aria-controls="pay-1"
                  onClick={showFormHandler}
                >
                  Add payments
                </a>
              </div>
            </div>
          </div>
          {/** form */}
          <div className={`collapse ${show ? "show" : ""}`} id="pay-1">
            <div className="fv-row row mt-3">
              <div className="col-md-6 mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Rate per Hour (in {currency})
                </label>
                <input
                  value={rate}
                  type="text"
                  name="user_email"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  onChange={setRateHandler}
                />
              </div>

              <div className="col-md-6 mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Total hour Paid
                </label>
                <input
                  value={hourOfWork}
                  type="text"
                  name="user_email"
                  className="form-control form-control-solid mb-3 mb-lg-0"
                  onChange={setHourOfWorkHandler}
                />
              </div>

              <div className="fv-row mb-7">
                <label className="required fw-bold fs-6 mb-2">Comment </label>
                <textarea
                  className="form-control form-control-solid"
                  rows={2}
                  maxLength={500}
                  name="message"
                  value={comment}
                  placeholder=""
                  onChange={setCommentHandler}
                ></textarea>
              </div>

              <div className="col-md-6 mb-7 align-self-center">
                <label className="fw-bold fs-7">
                  Total Amount :
                 {' '} {amount} {' '}  {currency}
                </label>
              </div>
              <div className="col-md-6 mb-7 text-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  data-kt-indicator={`${inProgress ? "on" : ""}`}
                  onClick={savePaymentData}
                >
                  <span className="indicator-label">Save</span>

                  <span className="indicator-progress">
                    Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PaymentDrawerSection;

import { createSlice } from '@reduxjs/toolkit'
import { loginThunk, getUserByTokenThunk, uploadPhotoThunk } from './thunk';
import { IAuthState } from './types';




const initialState: IAuthState = {
    inProgress: false,
    user: undefined,
    accessToken:'',
    error: ''
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutAction:(state)=>{
            state.accessToken = ''
        }
    },
    // Async actions
    extraReducers: (builder) => {

        builder.addCase(loginThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(loginThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state.user = action.payload.data.user
            state.accessToken = action.payload.data.accessToken
            state.error = null
        })
        builder.addCase(loginThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(getUserByTokenThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(getUserByTokenThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state.user = action.payload.data
        })
        builder.addCase(getUserByTokenThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        builder.addCase(uploadPhotoThunk.pending, (state, action) => {
            state.inProgress = true;
            state.error = null
        })
        builder.addCase(uploadPhotoThunk.fulfilled, (state, action) => {
            state.inProgress = false;
            state.error = null
            state.user = {...state.user!, photo:action.payload.data.photo}
        })
        builder.addCase(uploadPhotoThunk.rejected, (state, action) => {
            state.inProgress = false;
        })

        

    }
})

export const { logoutAction } = authSlice.actions
export default authSlice.reducer

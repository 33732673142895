/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//import { Filter } from './components/filter';
import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";
import { ExportData } from "./components/exportData";
import ReactPaginate from "react-paginate";
import { UsersListLoading } from "../../../_metronic/layout/components/loading/UsersListLoading";
import { KTCardBody } from "../../../_metronic/helpers/components/KTCardBody";
import { useDispatch, useSelector } from "../../../setup/redux/Store";
import Modal from "react-modal";
import { getCountriesThunk } from "../../redux/Freelancer/thunk";

import { getAllProjects, editProjectStatus } from "../../redux/Projects/thunks";
import { toast } from "react-toastify";
import { isFulfilled } from "@reduxjs/toolkit";
import { ROLES } from "../../../models/app";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { useDebounce } from "../../../_metronic/helpers";
import EditGroup from "./components/editGroup";

import clsx from "clsx";
import CheckBox from "../../../utils/CheckBox";
import { deleteGroupThunk, getGroups } from "../../redux/Chat/thunk";

export interface stateType {
  _id: string;
  name: string;
  userIds: [];
  messages: [];
  projectId: string;
}

const customStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};
const deleteModalStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const itemsPerPage = 10;
const initFilter = {
  projectManager: "",
  startDate: "",
  endDate: "",
  status: "",
  location: "",
  name: "",
  supervisor: "",
  projectId: "",
};

const GroupManagementTable: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sort, setSort] = useState({ field: "", order: "asc" });

  const [search, setSearch] = useState<string>("");
  const [projects, setProjects] = useState<any>([]);
  const [country, setCountry] = useState([]);
  const [total, setTotal] = useState<number>(0);
  const [openDeleteConfirmation, setDeleteOpenConfirmation] = useState(false);
  const [groups, setGroups] = useState<any>([]);

  useEffect(() => {
    const getCountry = async () => {
      const response = await dispatch(getCountriesThunk());
      if (isFulfilled()) {
        setCountry(response.payload.data);
      }
    };
    getCountry();
  }, []);
  const [filter, setFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const { companyId, inProgress } = useSelector((state) => ({
    companyId: state.auth.user?.company?._id,
    inProgress: state.groups.inProgress,
  }));

  const handlePageClick = (event: any) => {
    setFilter({ ...filter, page: event.selected });
  };

  const fetchAllProjects = async () => {
    const response: any = await dispatch(
      getAllProjects({ ...filter, companyId })
    );
    if (isFulfilled(response)) {
      if (response.payload.status) {
        setProjects(response.payload.data);
        //  setTotal(response.payload.count);
      }
    }
    MenuComponent.reinitialization();
  };

  const fetchAllGroups = async () => {
    const response: any = await dispatch(getGroups(filter));
    if (isFulfilled(response)) {
      if (response.payload.status) {
        setGroups(response.payload.data);
        setTotal(response.payload.count);
      }
    }
    MenuComponent.reinitialization();
  };

  useEffect(() => {
    fetchAllGroups();
  }, [filter]);

  const deleteGroup = (data: any) => {
    setDeleteOpenConfirmation(true);
    setGroupData(data);
  };

  const deleteGroupHandler = async (data: any) => {
    const response = await dispatch(deleteGroupThunk({ id: data._id }));
    if (isFulfilled(response)) {
      toast("Group deleted successfully", { type: "success" });
      setDeleteOpenConfirmation(false);
      fetchAllGroups();
    } else {
      toast("Group deleted unsuccessfully", { type: "error" });
    }
  };

  const debouncedSearchTerm = useDebounce(search, 1000);

  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      setFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  const viewJob = (data: any) => {
    navigate(`/project_details/${data._id}`);
  };

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [groupData, setGroupData] = useState<any>({
    _id: "",
    name: "",
    userIds: [],
    messages: [],
    projectId: "",
  });

  const [action, setAction] = useState<string>("");

  const editGroup = (data: stateType) => {
    setGroupData(data);
    setAction("edit");
    openModal();
  };

  const addGroup = () => {
    setGroupData({
      _id: "",
      name: "",
      userIds: [],
      messages: [],
      projectId: "",
    });
    setAction("Add");
    openModal();
  };

  const onChangeProjectStatusHandler = async (
    projectId: string,
    status: number
  ) => {
    //let status: number = 0;
    const response = await dispatch(editProjectStatus({ status, projectId }));
    if (isFulfilled(response)) {
      toast("Project status has successfully been changed", {
        type: "success",
      });
      fetchAllProjects();
      fetchAllGroups();
    } else {
      toast("Project status hasn't been changed, try again", { type: "error" });
    }
  };

  const changeSort = (field: string) => {
    if (sort.field === field) {
      let order = "";
      let sortField = "";
      if (sort.order === "") {
        order = "asc";
        sortField = field;
      } else if (sort.order === "asc") {
        order = "desc";
        sortField = field;
      }
      setSort({ field: sortField, order: order });
      setFilter({ ...filter, sort: sortField, order: order });
    } else {
      setSort({ field, order: "asc" });
      setFilter({ ...filter, sort: field, order: "asc" });
    }
  };

  const mappedData = useMemo(() => {
    if (!projects) return [];
    let v: any = {};
    let result = projects.map((el: any) => {
      v.id = el._id;
      v.isChecked = false;
      return v;
    });
    return result;
  }, [projects]);

  const [isCheck, setIsCheck] = useState<
    Array<{ id: string; isChecked: boolean }>
  >([...mappedData]);

  const onSelect = (e: any, el: string) => {
    let project = isCheck;
    project.forEach((p) => {
      if (p.id === el) p.isChecked = e.target.checked;
    });
    setIsCheck(project);
  };

  const onSelectAll = (e: any) => {
    let project = isCheck;
    project.forEach((p) => (p.isChecked = e.target.checked));
    setIsCheck(project);
  };

  const isFilterApplied = useMemo(() => {
    return (
      Object.keys(filter.filter).filter((v) => !!filter.filter[v]).length > 0
    );
  }, [filter.filter]);

  return (
    <React.Fragment>
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {/* begin::Input Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search Group"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>

          {/* end::Input Search */}
        </div>
        {/* end::card-tile Search */}

        {
          /*(
          <DeleteSelectedDataOnTable itemsChecked={ numOfProjectChecked} />
        ) : */ <div className="card-toolbar">
            <div
              className="d-flex justify-content-end"
              data-kt-user-table-toolbar="base"
            >
              <>
                {/** start filtert */}
                {/* <>
                  <button
                    disabled={false}
                    type="button"
                    className={clsx(
                      "btn btn-light-primary me-3 d-flex align-items-center",
                      isFilterApplied ? "btn-success" : ""
                    )}
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen031.svg"
                      className="svg-icon-2"
                    />
                    Filter
                  </button>
                  <div
                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true"
                  >
                    <div className="px-7 py-5">
                      <div className="fs-5 text-dark fw-bolder">
                        Filter Options
                      </div>
                    </div>

                    <div className="separator border-gray-200"></div>

                    <div
                      className="px-7 py-5"
                      data-kt-user-table-filter="form"
                      style={{ overflow: "auto", maxHeight: 350 }}
                    >
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Project:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                          onChange={projectMangerHandler}
                          value={multiFilter.projectManager}
                        >
                          <option value="">Select Project</option>
                          {projectManager &&
                            projectManager.map((v: any) => (
                              <option
                                key={v._id}
                                value={v._id}
                              >{`${v.firstName} ${v.lastName}`}</option>
                            ))}
                        </select>
                      </div>
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          User:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                          onChange={projectMangerHandler}
                          value={multiFilter.projectManager}
                        >
                          <option value="">Select User</option>
                          {projectManager &&
                            projectManager.map((v: any) => (
                              <option
                                key={v._id}
                                value={v._id}
                              >{`${v.firstName} ${v.lastName}`}</option>
                            ))}
                        </select>
                      </div>
                     <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Status:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                          onChange={statusInputHandler}
                          value={multiFilter.status}
                        >
                          <option value="">Select</option>
                          <option value={1}>Disabled</option>
                          <option value={2}>Enabled</option>
                        </select>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          disabled={false}
                          onClick={resetFormHandler}
                          className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="reset"
                        >
                          Reset
                        </button>
                        <button
                          type="button"
                          onClick={applyFilter}
                          className="btn btn-primary fw-bold px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="filter"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </> */}
                {/** end filter */}
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center"
                  onClick={addGroup}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-2"
                  />
                  Add Group
                </button>
              </>
            </div>
          </div>
        }
      </div>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 "
          >
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-up~percase gs-0">
                <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_table_users .form-check-input"
                      value="check all"
                      onClick={(e) => onSelectAll(e)}
                    />
                  </div>
                </th>
                <th
                  onClick={() => changeSort("name")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "name" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  GROUP NAME
                </th>

                <th
                  onClick={() => changeSort("startDate")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "startDate" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  GROUP PROJECT
                </th>
                <th
                  // onClick={() => changeSort("startDate")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "startDate" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  GROUP CREATION DATE
                </th>

                <th
                  //onClick={() => changeSort('supervisor')}
                  onClick={() => changeSort("supervisor")}
                  className={clsx(
                    "min-w-125px pointer",
                    sort.field === "supervisor" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  NO OF USERS
                </th>

                <th
                  //onClick={() => changeSort('status')}
                  onClick={() => changeSort("status")}
                  className={clsx(
                    "min-w-125px pointer text-center",
                    sort.field === "status" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  STATUS
                </th>
                <th
                  //onClick={() => changeSort('action')}
                  onClick={() => changeSort("action")}
                  className={clsx(
                    "min-w-125px pointer text-center",
                    sort.field === "action" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                  //className={clsx('min-w-125px pointer text-center')}
                >
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {!groups ? (
                <tr>
                  <td colSpan={8}>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      No record found
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {groups?.length === 0 ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="d-flex  w-100  text-center justify-content-center">
                          No record found
                        </div>
                      </td>
                    </tr>
                  ) : (
                    groups.map((el: any, index: number) => {
                      let _status: string = "Active";
                      return (
                        <tr key={el._id}>
                          {/** the check box */}
                          <td>
                            <div className="form-check form-check-sm form-check-custom form-check-solid">
                              <CheckBox
                                onSelect={onSelect}
                                project={isCheck}
                                id={el._id}
                              />
                            </div>
                          </td>

                          {/** group title */}
                          <td>
                            <div className="d-flex justify-content-start flex-column pointer">
                              <a
                                //href='javascript:void(0)'
                                // onClick={() => viewJob(el)}
                                className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                              >
                                {el.name}
                              </a>
                            </div>
                          </td>

                          {/** group project */}
                          <td>
                            <div className="d-flex justify-content-start flex-column pointer">
                              <a
                                //href='javascript:void(0)'
                                // onClick={() => viewJob(el)}
                                className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                              >
                                {el.projectId.name}
                              </a>
                            </div>
                          </td>

                          {/** group date */}
                          <td>
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                //href='javascript:void(0)'
                                className="fw-bolder text-muted mb-1 fs-6 text-capitalize"
                              >
                                {new Intl.DateTimeFormat("en-GB", {
                                  dateStyle: "medium",
                                }).format(new Date(el.createdAt))}
                              </a>
                            </div>
                          </td>

                          <td>
                            <div className="d-flex justify-content-start flex-column pointer">
                              <a
                                // onClick={() => viewJob(el)}
                                className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                              >
                                {el.userIds.length + el.appUserIds.length}
                              </a>
                            </div>
                          </td>

                          {/** status */}
                          <td>
                            <a
                              //href='javascript:void(0)'
                              className="d-block mb-1 fs-6 text-center"
                            >
                              <div
                                className={clsx(
                                  "badge fw-bolder",
                                  "badge-light-success"
                                )}
                              >
                                {_status}
                              </div>
                            </a>
                          </td>

                          {/** action  */}
                          <td className="d-flex justify-content-center">
                            <a
                              //href='javascript:void(O)'
                              className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center "
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              Actions
                              <KTSVG
                                path="/media/icons/duotune/arrows/arr072.svg"
                                className="svg-icon-5 m-0"
                              />
                            </a>
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4"
                              data-kt-menu="true"
                            >
                              <div className="menu-item">
                                <a
                                  //href='javascript:void(0)'
                                  className="menu-link"
                                  onClick={() => editGroup(el)}
                                >
                                  Edit
                                </a>
                              </div>
                              {/* <div className="menu-item">
                                    <a
                                      className="menu-link"
                                      onClick={() =>
                                        onChangeProjectStatusHandler(el._id, 4)
                                      }
                                    >
                                      Disable
                                    </a>
                                  </div> */}
                              <div className="menu-item">
                                <a
                                  //href='javascript:void(0)'
                                  onClick={() => deleteGroup(el)}
                                  className="menu-link"
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ margin: 20 }}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={total / itemsPerPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={itemsPerPage}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            // forcePage={pageOffset}
          />
        </div>
        {inProgress && <UsersListLoading />}
      </KTCardBody>
      <Modal
        isOpen={modalIsOpen}
        id={"edit_project"}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <EditGroup
          action={action}
          closeModal={closeModal}
          fetchAllGroups={fetchAllGroups}
          data={groupData}
          country={country}
        />
      </Modal>
      <Modal
        isOpen={openDeleteConfirmation}
        // onRequestClose={closeModal}
        style={deleteModalStyles}
        id={"delete_confirmation"}
      >
        <div className="container m-5">
          <h1>Delete Project</h1>
          <p>Are you sure you want to delete this group?</p>
          <div className="clearfix mt-5 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setDeleteOpenConfirmation(false)}
              className="btn btn-sm btn-secondary me-3"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => deleteGroupHandler(groupData)}
              className="btn btn-sm btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default GroupManagementTable;

import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { IAddForm } from './types';

export const getUserThunk = createAsyncThunk(
    'admin/getUser', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/getUser', data,{responseType: data?.export?'arraybuffer':'json'})
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const getAppUserThunk = createAsyncThunk(
    'admin/getAppUsers', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/getAppUsers', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)


export const getCompanyThunk = createAsyncThunk(
    'admin/getCompany', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.get('/auth/company', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const addUserThunk = createAsyncThunk(
    'admin/addUser', async (data: IAddForm, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/user', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const updateUserThunk = createAsyncThunk(
    'admin/updateUser', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.put('/auth/user', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const deleteUserThunk = createAsyncThunk(
    'admin/deleteUser', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post(`/auth/deleteUser`, data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const resendInviteThunk = createAsyncThunk(
    'admin/resendInvite', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/resendInvite', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const updatePasswordThunk = createAsyncThunk(
    'admin/updatePassword', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/updatePassword', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const forgotPasswordThunk = createAsyncThunk(
    'admin/updatePassword', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/forgotPassword', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const changeUserStatusThunk = createAsyncThunk(
    'admin/changeUserStatus', async (data: any, { rejectWithValue }) => {
        try {
            const response: any = await axios.post('/auth/changeUserStatus', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)

export const editUserThunk = createAsyncThunk(
    'admin/editUser', async (data: IAddForm, { rejectWithValue }) => {
        try {
            const response: any = await axios.put('/auth/user', data)
            return response.data
        } catch (err) {
            return rejectWithValue((err as any).response.data || err)
        }
    }
)
import React, { FC, useState, useEffect } from "react";
import clsx from "clsx";
import { KTSVG, useDebounce } from "../../../_metronic/helpers";
import { useSelector, useDispatch } from "../../../setup/redux/Store";
import {
  getJobByIdThunk,
  getAppliedUserThunk,
  updateJobAppliedStatusThunk,
} from "../../redux/Jobs/thunk";
import { isFulfilled } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import { UsersListLoading } from "../../../_metronic/layout/components/loading/UsersListLoading";
import moment from "moment";
import ReactPaginate from "react-paginate";
import ExportModal from "./export";
import Modal from "react-modal";
import { JobDrawer } from "./jobDrawer";
import { viewUserAction } from "../../redux/Jobs/slice";
import { IAppUser } from "../../redux/Freelancer/types";
import { MenuComponent } from "../../../_metronic/assets/ts/components";

const itemsPerPage = 5;
const initFilter = {
  status: "",
};

const customStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const ViewJob: FC = () => {
  const { inProgress } = useSelector((state) => ({
    inProgress: state.adminJobs.inProgress,
  }));
  const dispatch = useDispatch();
  const { id } = useParams();
  const [jobDetail, setJobDetail] = useState<any>();
  const [jobStatics, setJobStatics] = useState<any>();
  const [appliedUser, setAppliedUser] = useState<any>([]);
  const [search, setSearch] = useState<string>("");
  const [total, setTotal] = useState(0);
  const [multiFilter, setMultiFilter] = useState(initFilter);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const getAppliedUser = async () => {
    const response: any = await dispatch(
      getAppliedUserThunk({ id, ...filter })
    );
    if (response) {
      setTotal(response.payload.count);
      setAppliedUser(response.payload.data);
    }
  };

  const getJobDetails = async () => {
    const response: any = await dispatch(getJobByIdThunk({ id }));
    if (response) {
      const {
        payload: {
          data: {
            jobDetails,
            totalCount,
            selectedCount,
            processingCount,
            rejectedCount,
          },
        },
      } = response;
      setJobStatics({
        totalCount,
        selectedCount,
        processingCount,
        rejectedCount,
      });
      setJobDetail(jobDetails);
      MenuComponent.reinitialization();
    }
  };

  const openExportModel = () => {
    setExportModalIsOpen(true);
  };

  const closeExportModel = () => {
    setExportModalIsOpen(false);
  };

  useEffect(() => {
    if (id) {
      getJobDetails();
      getAppliedUser();
    }
  }, [id]);

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      setFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getAppliedUser();
  }, [filter]);

  const handlePageClick = (event: any) => {
    setFilter({ ...filter, page: event.selected });
  };

  const resetFilter = () => {
    setMultiFilter(initFilter);
    setFilter({ ...filter, filter: initFilter });
  };

  const applyFilter = () => {
    setFilter({ ...filter, filter: multiFilter });
  };

  const onChangeFilter = (field: string, value: any) => {
    const filt: any = { ...multiFilter };
    filt[field] = value;
    setMultiFilter(filt);
  };

  const download = async (type: string) => {
    const response = await dispatch(
      getAppliedUserThunk({ id, ...filter, export: type })
    );
    if (isFulfilled(response)) {
      const blob = new Blob([response.payload], {
        type:
          type === "csv"
            ? "text/csv"
            : type === "pdf"
            ? "application/pdf"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `AppliedUser.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const updateStatus = async (status: number, data: any) => {
    const response = await dispatch(
      updateJobAppliedStatusThunk({ id: data._id, status: status })
    );
    if (isFulfilled(response)) {
      getAppliedUser();
      getJobDetails();
    }
  };

  const viewUser = async (data: any) => {
    await dispatch(viewUserAction(data));
  };

  const back = () => {
    navigate("/job_management");
  };

  return (
    <>
      <div className="toolbar" id="kt_toolbar">
        <div
          id="kt_toolbar_container"
          className="container-fluid d-flex flex-stack"
        >
          <div
            data-kt-swapper="true"
            data-kt-swapper-mode="prepend"
            data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
            className="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0"
          >
            <h1 className="d-flex align-items-center text-dark fw-bolder fs-3 my-1">
              Job-Details
            </h1>
            <span className="h-20px border-gray-300 border-start mx-4"></span>
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li className="breadcrumb-item text-muted">
                <a
                  href="../../demo1/dist/index.html"
                  className="text-muted text-hover-primary"
                >
                  Home
                </a>
              </li>
              <li className="breadcrumb-item ">
                <span className="bullet bg-gray-300 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-muted">Job Management</li>
              <li className="breadcrumb-item ">
                <span className="bullet bg-gray-300 w-5px h-2px"></span>
              </li>
              <li className="breadcrumb-item text-dark">Job Details</li>
            </ul>
          </div>
          <div>
            <button className="btn btn-sm btn-secondary" onClick={back}>
              Back
            </button>
          </div>
        </div>
      </div>

      {jobDetail ? (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <div className="card mb-6">
              <div className="card-body p-5">
                <div className="d-flex flex-wrap flex-sm-nowrap">
                  <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                          <a
                            href="javascript:void(0)"
                            className="text-gray-900 text-hover-primary fs-2 fw-bolder me-1 text-capitalize"
                          >
                            {jobDetail.title}{" "}
                            <span className="text-muted fs-6 text-capitalize">
                              - {jobDetail.role}
                            </span>
                          </a>
                        </div>
                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                          <a
                            href="javascript:void(0)"
                            className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                          >
                            <span className="svg-icon svg-icon-4 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M21 22H3C2.4 22 2 21.6 2 21V5C2 4.4 2.4 4 3 4H21C21.6 4 22 4.4 22 5V21C22 21.6 21.6 22 21 22Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M6 6C5.4 6 5 5.6 5 5V3C5 2.4 5.4 2 6 2C6.6 2 7 2.4 7 3V5C7 5.6 6.6 6 6 6ZM11 5V3C11 2.4 10.6 2 10 2C9.4 2 9 2.4 9 3V5C9 5.6 9.4 6 10 6C10.6 6 11 5.6 11 5ZM15 5V3C15 2.4 14.6 2 14 2C13.4 2 13 2.4 13 3V5C13 5.6 13.4 6 14 6C14.6 6 15 5.6 15 5ZM19 5V3C19 2.4 18.6 2 18 2C17.4 2 17 2.4 17 3V5C17 5.6 17.4 6 18 6C18.6 6 19 5.6 19 5Z"
                                  fill="black"
                                ></path>
                                <path
                                  d="M8.8 13.1C9.2 13.1 9.5 13 9.7 12.8C9.9 12.6 10.1 12.3 10.1 11.9C10.1 11.6 10 11.3 9.8 11.1C9.6 10.9 9.3 10.8 9 10.8C8.8 10.8 8.59999 10.8 8.39999 10.9C8.19999 11 8.1 11.1 8 11.2C7.9 11.3 7.8 11.4 7.7 11.6C7.6 11.8 7.5 11.9 7.5 12.1C7.5 12.2 7.4 12.2 7.3 12.3C7.2 12.4 7.09999 12.4 6.89999 12.4C6.69999 12.4 6.6 12.3 6.5 12.2C6.4 12.1 6.3 11.9 6.3 11.7C6.3 11.5 6.4 11.3 6.5 11.1C6.6 10.9 6.8 10.7 7 10.5C7.2 10.3 7.49999 10.1 7.89999 10C8.29999 9.90003 8.60001 9.80003 9.10001 9.80003C9.50001 9.80003 9.80001 9.90003 10.1 10C10.4 10.1 10.7 10.3 10.9 10.4C11.1 10.5 11.3 10.8 11.4 11.1C11.5 11.4 11.6 11.6 11.6 11.9C11.6 12.3 11.5 12.6 11.3 12.9C11.1 13.2 10.9 13.5 10.6 13.7C10.9 13.9 11.2 14.1 11.4 14.3C11.6 14.5 11.8 14.7 11.9 15C12 15.3 12.1 15.5 12.1 15.8C12.1 16.2 12 16.5 11.9 16.8C11.8 17.1 11.5 17.4 11.3 17.7C11.1 18 10.7 18.2 10.3 18.3C9.9 18.4 9.5 18.5 9 18.5C8.5 18.5 8.1 18.4 7.7 18.2C7.3 18 7 17.8 6.8 17.6C6.6 17.4 6.4 17.1 6.3 16.8C6.2 16.5 6.10001 16.3 6.10001 16.1C6.10001 15.9 6.2 15.7 6.3 15.6C6.4 15.5 6.6 15.4 6.8 15.4C6.9 15.4 7.00001 15.4 7.10001 15.5C7.20001 15.6 7.3 15.6 7.3 15.7C7.5 16.2 7.7 16.6 8 16.9C8.3 17.2 8.6 17.3 9 17.3C9.2 17.3 9.5 17.2 9.7 17.1C9.9 17 10.1 16.8 10.3 16.6C10.5 16.4 10.5 16.1 10.5 15.8C10.5 15.3 10.4 15 10.1 14.7C9.80001 14.4 9.50001 14.3 9.10001 14.3C9.00001 14.3 8.9 14.3 8.7 14.3C8.5 14.3 8.39999 14.3 8.39999 14.3C8.19999 14.3 7.99999 14.2 7.89999 14.1C7.79999 14 7.7 13.8 7.7 13.7C7.7 13.5 7.79999 13.4 7.89999 13.2C7.99999 13 8.2 13 8.5 13H8.8V13.1ZM15.3 17.5V12.2C14.3 13 13.6 13.3 13.3 13.3C13.1 13.3 13 13.2 12.9 13.1C12.8 13 12.7 12.8 12.7 12.6C12.7 12.4 12.8 12.3 12.9 12.2C13 12.1 13.2 12 13.6 11.8C14.1 11.6 14.5 11.3 14.7 11.1C14.9 10.9 15.2 10.6 15.5 10.3C15.8 10 15.9 9.80003 15.9 9.70003C15.9 9.60003 16.1 9.60004 16.3 9.60004C16.5 9.60004 16.7 9.70003 16.8 9.80003C16.9 9.90003 17 10.2 17 10.5V17.2C17 18 16.7 18.4 16.2 18.4C16 18.4 15.8 18.3 15.6 18.2C15.4 18.1 15.3 17.8 15.3 17.5Z"
                                  fill="black"
                                ></path>
                              </svg>
                            </span>
                            {moment(jobDetail.startDate).format("DD MMM YYYY")}{" "}
                            - {moment(jobDetail.endDate).format("DD MMM YYYY")}
                          </a>
                          <a
                            href="#"
                            className="d-flex align-items-center text-gray-400 text-hover-primary mb-2 text-capitalize"
                          >
                            <span className="svg-icon svg-icon-4 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                  fill="black"
                                />
                                <path
                                  d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            {jobDetail.location}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div>
                      <p>{jobDetail.jobDescription}</p>
                    </div>

                    <div className="d-flex flex-wrap flex-stack">
                      <div className="d-flex flex-column pe-8 w-100">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="d-flex">
                            <div className="border border-gray-300 border-dashed rounded min-w-100px p-3 me-3 mb-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="fs-2 fw-bolder"
                                  data-kt-countup="true"
                                  data-kt-countup-value="500"
                                >
                                  {jobStatics.totalCount}
                                </div>
                              </div>

                              <div className="fw-bold fs-6 text-gray-400">
                                Applied Count
                              </div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded min-w-100px p-3 me-3 mb-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="fs-2 fw-bolder"
                                  data-kt-countup="true"
                                  data-kt-countup-value="300"
                                >
                                  {jobStatics.selectedCount}
                                </div>
                              </div>

                              <div className="fw-bold fs-6 text-gray-400">
                                Selected Count
                              </div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded min-w-100px p-3 me-3 mb-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="fs-2 fw-bolder"
                                  data-kt-countup="true"
                                  data-kt-countup-value="160"
                                >
                                  {jobStatics.processingCount}
                                </div>
                              </div>

                              <div className="fw-bold fs-6 text-gray-400">
                                Inprogress Count
                              </div>
                            </div>

                            <div className="border border-gray-300 border-dashed rounded min-w-100px p-3 me-3 mb-3">
                              <div className="d-flex align-items-center">
                                <div
                                  className="fs-2 fw-bolder"
                                  data-kt-countup="true"
                                  data-kt-countup-value="40"
                                >
                                  {jobStatics.rejectedCount}
                                </div>
                              </div>

                              <div className="fw-bold fs-6 text-gray-400">
                                Rejected Count
                              </div>
                            </div>
                          </div>

                          {jobDetail.skillTag &&
                          jobDetail.skillTag.length > 0 ? (
                            <div>
                              <div className="d-flex flex-column">
                                <div className="fw-bold fs-6 text-gray-400 d-block">
                                  Skills
                                </div>
                                <div className="d-flex flex-row">
                                  {jobDetail.skillTag.map((v: any) => (
                                    <span className="badge badge-light fw-bolder me-2 text-capitalize">
                                      {v}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-wrap flex-stack mb-6">
              <h3 className="fw-bolder my-2">Applied Users</h3>

              <div className="card-toolbar">
                <div
                  className="d-flex justify-content-end"
                  data-kt-user-table-toolbar="base"
                >
                  <div className="d-flex align-items-center position-relative my-1 me-3">
                    <KTSVG
                      path="/media/icons/duotune/general/gen021.svg"
                      className="svg-icon-1 position-absolute ms-6"
                    />

                    <input
                      type="text"
                      data-kt-user-table-filter="search"
                      className="form-control form-control-solid w-250px ps-14 bg-white"
                      placeholder="Search job"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>

                  <button
                    disabled={inProgress}
                    type="button"
                    className="btn btn-light-primary me-3 d-flex align-items-center"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    <KTSVG
                      path="/media/icons/duotune/general/gen031.svg"
                      className="svg-icon-2"
                    />
                    Filter
                  </button>
                  <div
                    className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                    data-kt-menu="true"
                  >
                    <div className="px-7 py-5">
                      <div className="fs-5 text-dark fw-bolder">
                        Filter Options
                      </div>
                    </div>

                    <div className="separator border-gray-200"></div>

                    <div
                      className="px-7 py-5"
                      data-kt-user-table-filter="form"
                      style={{ overflow: "auto", maxHeight: 350 }}
                    >
                      <div className="mb-10">
                        <label className="form-label fs-6 fw-bold">
                          Status:
                        </label>
                        <select
                          className="form-select form-select-solid fw-bolder"
                          data-kt-select2="true"
                          data-placeholder="Select option"
                          data-allow-clear="true"
                          data-kt-user-table-filter="role"
                          data-hide-search="true"
                          onChange={(e) =>
                            onChangeFilter("status", e.target.value)
                          }
                          value={multiFilter.status}
                        >
                          <option value="">Select</option>
                          <option value={2}>Approved</option>
                          <option value={1}>Applied</option>
                          <option value={0}>Rejected</option>
                        </select>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          disabled={inProgress}
                          onClick={resetFilter}
                          className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="reset"
                        >
                          Reset
                        </button>
                        <button
                          disabled={inProgress}
                          type="button"
                          onClick={applyFilter}
                          className="btn btn-primary fw-bold px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-user-table-filter="filter"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={openExportModel}
                    type="button"
                    className="btn btn-light-primary me-3 d-flex align-items-center"
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr078.svg"
                      className="svg-icon-2"
                    />
                    Export
                  </button>
                </div>
              </div>
            </div>

            {appliedUser.length > 0 ? (
              <>
                <div className="row g-6 g-xl-9">
                  {appliedUser.map((v: any) => {
                    let rating =
                      v.rating.length > 0
                        ? v.rating.reduce(
                            (acc: number, r: any) => acc + r.rating,
                            0
                          ) / v.rating.length
                        : 0;

                    let supervisorRating =
                      v.supervisorRating.length > 0
                        ? v.supervisorRating.reduce(
                            (acc: number, r: any) => acc + r.rating,
                            0
                          ) / v.supervisorRating.length
                        : 0;

                    const overallRating = rating + supervisorRating;
                    const avgRating =
                      overallRating > 0
                        ? Math.round(
                            overallRating /
                              (rating > 0 && supervisorRating > 0 ? 2 : 1)
                          )
                        : 0;
                    return (
                      <div className="col-md-6 col-xl-4">
                        <a
                          href="javascript:void(0);"
                          className="card border-hover-primary"
                        >
                          <div className="card-header border-0 p-4 d-block">
                            <div className="card-title m-0">
                              {v.user[0]?.photo ? (
                                <div className="symbol symbol-50px w-50px bg-light me-4">
                                  <img
                                    src={v?.user[0]?.photo}
                                    className="align-self-end w-100 br-50"
                                  />
                                </div>
                              ) : null}
                              <div className="user-name w-100">
                                <div className="fs-3 fw-bolder text-dark text-truncate me-4 d-flex justify-content-between">
                                  <a
                                    id="kt_drawer_job_toggle"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="left"
                                    data-bs-dismiss="click"
                                    data-bs-trigger="hover"
                                    // href="javascript:void(0)"
                                    onClick={() => viewUser(v)}
                                    className="fs-3 fw-bolder text-dark text-truncate"
                                  >
                                    <span
                                      className="textoverflow breakspaces"
                                      style={{ maxWidth: 200}}
                                    >
                                      {v.user[0]?.firstName}{" "}
                                      {v.user[0]?.lastName}{" "}
                                    </span>
                                    <span className="badge badge-light-primary fw-bolder px-4 py-3 text-capitalize">
                                      {v.user[0]?.gender}
                                    </span>
                                  </a>
                                </div>

                                <div className="d-flex align-items-center fw-bold mt-1">
                                  <span className="badge bg-light text-gray-700 px-3 py-1 me-2">
                                    <i
                                      className={clsx(
                                        avgRating === 5
                                          ? "bi-star-fill"
                                          : avgRating > 0
                                          ? "bi-star-half"
                                          : "bi-star",
                                        "bi me-1 text-warning fs-6"
                                      )}
                                    ></i>{" "}
                                    {avgRating}
                                  </span>
                                  <span className="text-gray-400 fs-7 text-capitalize">
                                    {v.jobId[0]?.title}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card-body p-4">
                            <div className="d-flex flex-wrap">
                              <div className="badge badge-light fw-bolder fs-8 px-1 py-3 me-3 d-flex align-items-center">
                                {" "}
                                {moment(v.createdAt).format("DD/MM/YYYY")}
                              </div>
                              <div
                                className={clsx(
                                  v.status === 2
                                    ? "badge-light-success"
                                    : v.status === 1 || v.status === 3
                                    ? "badge-light-primary"
                                    : "badge-light-danger",
                                  "badge  fw-bolder me-auto px-2 py-3 d-flex  align-items-center"
                                )}
                                data-kt-menu-trigger="click"
                                data-kt-menu-placement="bottom-end"
                                 data-kt-menu-flip='bottom'
                              >
                                {v.status === 2
                                  ? "Approved"
                                  : v.status === 1
                                  ? "Pending"
                                  : v.status === 3
                                  ? "Training"
                                  : "Rejected"}
                                {v.status !== 2 ? (
                                  <KTSVG
                                    path="/media/icons/duotune/arrows/arr072.svg"
                                    className="svg-icon-5 m-0"
                                  />
                                ) : null}
                              </div>
                              {v.status !== 2 ? (
                                <div
                                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                                  data-kt-menu="true"
                                >
                                  {v.status !== 3 ? (
                                    <div className="menu-item">
                                      <a
                                        className="menu-link"
                                        onClick={() => updateStatus(3, v)}
                                      >
                                        Training
                                      </a>
                                    </div>
                                  ) : null}
                                  {v.status !== 2 ? (
                                    <div className="menu-item">
                                      <a
                                        className="menu-link"
                                        onClick={() => updateStatus(2, v)}
                                      >
                                        Approve
                                      </a>
                                    </div>
                                  ) : null}

                                  {v.status == 1 || v.status === 3 ? (
                                    <div className="menu-item">
                                      <a
                                        className="menu-link"
                                        onClick={() => updateStatus(0, v)}
                                      >
                                        Reject
                                      </a>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}

                              <div className="badge badge-light-primary fw-bolder ms-auto px-2 py-3 d-flex align-items-center">
                                <i className="bi bi-telephone-fill text-primary me-2"></i>{" "}
                                {v.user[0]?.phoneNumber}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>

                <div className="d-flex flex-stack flex-wrap pt-10 justify-content-center mt-10">
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={total / itemsPerPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={itemsPerPage}
                    onPageChange={handlePageClick}
                    containerClassName="pagination"
                    activeClassName="active"
                    // forcePage={pageOffset}
                  />
                </div>
              </>
            ) : (
              <div
                className="text-center mt-5"
                style={{
                  height: 150,
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Records found
              </div>
            )}
          </div>
        </div>
      ) : null}
      {inProgress && <UsersListLoading />}
      <Modal
        isOpen={exportModalIsOpen}
        id={"export_project"}
        onRequestClose={closeExportModel}
        style={customStyles}
      >
        <ExportModal closeModal={closeExportModel} download={download} />
      </Modal>
      <JobDrawer
        getAppliedUser={getAppliedUser}
        getJobDetails={getJobDetails}
      />
    </>
  );
};

export default ViewJob;

import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAllProjects = createAsyncThunk(
  '/allProjects',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/project/allProjects', data, { responseType: data?.export ? 'arraybuffer' : 'json' });
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const getProjectUsers= createAsyncThunk(
  '/getProject',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/project/getProjectUsers', data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const getProjectById = createAsyncThunk(
  '/getProject',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post('/project/getProject', data, { responseType: data?.export ? 'arraybuffer' : 'json' });
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const createProject = createAsyncThunk(
  '/createProject',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post('/project/createProject', data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const editProjectThunk = createAsyncThunk(
  '/editProjects',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post('/project/editProjects', data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const deleteProjectThunk = createAsyncThunk(
  '/deleteProject',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post('/project/deleteProject', data);
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const editProjectStatus = createAsyncThunk(
  '/changeProjectStatus',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post(
        '/project/changeProjectStatus',
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const getProjectManagersThunk = createAsyncThunk(
  '/getProjectManager',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getProjectManager', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);


export const getBrandAmbassadorThunk = createAsyncThunk(
  '/getBrandAmbassadors',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `/project/getBrandAmbassadors`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);


export const getSupervisorThunk = createAsyncThunk(
  '/getSupervisor',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `/project/getSupervisor`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//getBasWhoAppliesTo job
export const getAppliedJobThunk = createAsyncThunk(
  '/getUsersAppliedJob',
  async (data: any, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.post(
        `/project/getUsersAppliedJob`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//get country
export const getCountries = createAsyncThunk(
  '/getCountries',
  async (data, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<any> = await axios.get(
        `/freelancer/countries`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

// get jobs
export const getJobsInCompanyThunk = createAsyncThunk(
  '/getJobsByCompany',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getJobsByCompany', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//get comments
export const getUserComment = createAsyncThunk(
  '/getComments',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getComments', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

// create payment
export const createPayment = createAsyncThunk(
  '/createPayment',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/createPayment', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//deletePayment
export const deletePayment = createAsyncThunk(
  '/deletePayment',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/deletePayment', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//updatePayment
export const updatePayment = createAsyncThunk(
  '/updatePayment',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/updatePayment', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//get paid payment
export const getPaidPayment = createAsyncThunk(
  '/getUserPaidPayments',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getUserPaidPayments', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//getpayment
export const getUsersPayments = createAsyncThunk(
  '/getUserPayments',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getUserPayments', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

//getTask
export const getTaskThunk = createAsyncThunk(
  '/getTask',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getTask', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

// edit task
export const editATask = createAsyncThunk(
  '/editTask',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/editTask', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

// get total hours spent on project, whether approved or not
export const getProjectTotalHoursThunk = createAsyncThunk(
  '/getProjectHours',
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post('/project/getProjectHours', data);
      return result.data;
    } catch (err) {
      return rejectWithValue((err as any).response.data || err);
    }
  }
);

export const publishProjectThunk = createAsyncThunk(
  '/publishProject', async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post('/project/publishProject', data)
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)

export const updateTaskStatusThunk = createAsyncThunk(
  '/updateTaskStatus', async (data: any, { rejectWithValue }) => {
    try {
      const response: any = await axios.post('/project/updateTaskStatus', data)
      return response.data
    } catch (err) {
      return rejectWithValue((err as any).response.data || err)
    }
  }
)




import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "../../../setup/redux/Store";
import {
  getUserThunk,
  resendInviteThunk,
  changeUserStatusThunk,
  deleteUserThunk,
} from "../../redux/Admin/thunk";
import { viewUserAction } from "../../redux/Freelancer/slice";
import { KTCardBody } from "../../../_metronic/helpers";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { isFulfilled } from "@reduxjs/toolkit";
import { APP_USER_PROGRESS_STATUS } from "../../../models/app";
import { IAdminState } from "../../redux/Admin/types";
import { toast } from "react-toastify";
import { ROLES, ADMIN_STATUS } from "../../../models/app";
import { UsersListLoading } from "../../../_metronic/layout/components/loading/UsersListLoading";
import ReactPaginate from "react-paginate";
import { KTSVG, useDebounce } from "../../../_metronic/helpers";
import AddModal from "./addModal";
import Modal from "react-modal";
import ExportModal from "./export";

const itemsPerPage = 10;
const initFilter = {
  status: "",
  role: "",
};
const deleteModalStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "30%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const customStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const UsersTable = () => {
  const { companyId, role, inProgress } = useSelector((state) => ({
    role: state.auth.user?.role,
    inProgress: state.admin.inProgress,
    companyId: state.auth.user?.company?._id,
  }));

  const dispatch = useDispatch();
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [users, setUsers] = useState<any[]>([]);
  const [sort, setSort] = useState({ field: "", order: "asc" });
  const [total, setTotal] = useState(0);
  const [multiFilter, setMultiFilter] = useState(initFilter);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [action, setAction] = useState("add");
  const [filter, setFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const isAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);

  const getUserList = async () => {
    const response:any = await dispatch(getUserThunk({ ...filter, companyId }));
    if (isFulfilled(response)) {
      setTotal(response.payload.count);
      setUsers(response.payload.data);
    }
    MenuComponent.reinitialization();
  };

  useEffect(() => {
    getUserList();
  }, [filter]);

  const deleteUser = async (data: any) => {
    const result = await dispatch(deleteUserThunk({ id: data._id }));
    if (isFulfilled(result)) {
      toast("User deleted successfully", { type: "success" });
      setOpenConfirmation(false);
      getUserList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const userDelete = (data: any) => {
    setOpenConfirmation(true);
    setUserData(data);
  };

  const editUser = (data: any) => {
    setAction("edit");
    setUserData(data);
    openModal();
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    getUserList();
    setModalIsOpen(false);
    setUserData(null);
    setAction("add");
  };

  const updateStatus = async (status: number, data: any) => {
    const result = await dispatch(
      changeUserStatusThunk({ id: data._id, status })
    );
    if (isFulfilled(result)) {
      toast("Status updated successfully", { type: "success" });
      getUserList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const resentInvite = async (data: any) => {
    const result = await dispatch(resendInviteThunk({ id: data._id }));
    if (isFulfilled(result)) {
      toast("Inivitation resent successfully", { type: "success" });
      return;
    } else {
      const err: any = result;
      toast("Error in sending invite", { type: "error" });
    }
  };

  const onSelect = (state: boolean, data: any) => {
    const selected: string[] = [...selectedData];
    if (state) {
      selected.push(data._id.toString());
    } else {
      const index = selected.findIndex((v) => v === data._id);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setSelectedData(selected);
  };

  const onSelectAll = (state: boolean) => {
    if (state) {
      const selected: string[] = users.map((v) => v._id.toString());
      setSelectedData(selected);
    } else {
      setSelectedData([]);
    }
  };

  const handlePageClick = (event: any) => {
    setFilter({ ...filter, page: event.selected });
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      setFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  const changeSort = (field: string) => {
    if (sort.field === field) {
      let order = "";
      let sortField = "";
      if (sort.order === "") {
        order = "asc";
        sortField = field;
      } else if (sort.order === "asc") {
        order = "desc";
        sortField = field;
      }
      setSort({ field: sortField, order: order });
      setFilter({ ...filter, sort: sortField, order: order });
    } else {
      setSort({ field, order: "asc" });
      setFilter({ ...filter, sort: field, order: "asc" });
    }
  };

  const onChangeFilter = (field: string, value: any) => {
    const filt: any = { ...multiFilter };
    filt[field] = value;
    setMultiFilter(filt);
  };

  const resetFilter = () => {
    setMultiFilter(initFilter);
    setFilter({ ...filter, filter: initFilter });
  };

  const applyFilter = () => {
    setFilter({ ...filter, filter: multiFilter });
  };

  const download = async (type: string) => {
    const response = await dispatch(
      getUserThunk({ ...filter, companyId, export: type })
    );
    if (isFulfilled(response)) {
      const blob = new Blob([response.payload], {
        type:
          type === "csv"
            ? "text/csv"
            : type === "pdf"
            ? "application/pdf"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Users.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const openExportModel = ()=>{
    setExportModalIsOpen(true)
  }

  const closeExportModel = ()=>{
    setExportModalIsOpen(false)
  }

  const isFilterApplied = useMemo(()=>{
    return Object.keys(filter.filter).filter((v)=>!!filter.filter[v]).length > 0
  },[filter.filter])

  return (
    <>
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search user"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            <>
              <button
                disabled={inProgress}
                type="button"
                className={clsx("btn btn-light-primary me-3 d-flex align-items-center",isFilterApplied?"btn-success":"")}
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-2"
                />
                Filter
              </button>

              <div
                className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                data-kt-menu="true"
              >
                <div className="px-7 py-5">
                  <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                </div>

                <div className="separator border-gray-200"></div>

                <div className="px-7 py-5" data-kt-user-table-filter="form">
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Status:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      onChange={(e) => onChangeFilter("status", e.target.value)}
                      value={multiFilter.status}
                    >
                      <option value="">Select</option>
                      <option value={ADMIN_STATUS.PENDING_VERIFICATION}>
                        Pending
                      </option>
                      <option value={ADMIN_STATUS.ACTIVE}>Active</option>
                      <option value={ADMIN_STATUS.INACTIVE}>In-active</option>
                      <option value={ADMIN_STATUS.DELETED}>Deleted</option>
                    </select>
                  </div>
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Role:</label>
                    <select
                      className="form-select form-select-solid fw-bolder"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      onChange={(e) => onChangeFilter("role", e.target.value)}
                      value={multiFilter.role}
                    >
                      <option value="">Select</option>
                      <option value={ROLES.ADMIN}>Admin</option>
                      <option value={ROLES.PROJECT_MANAGER}>
                        Project Manager
                      </option>
                    </select>
                  </div>

                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={inProgress}
                      onClick={resetFilter}
                      className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="reset"
                    >
                      Reset
                    </button>
                    <button
                      disabled={inProgress}
                      type="button"
                      onClick={applyFilter}
                      className="btn btn-primary fw-bold px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="filter"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </>

            <button
              onClick={openExportModel}
              type="button"
              className="btn btn-light-primary me-3 d-flex align-items-center"
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr078.svg"
                className="svg-icon-2"
              />
              Export
            </button>

            <button
              type="button"
              className="btn btn-primary d-flex"
              onClick={openModal}
            >
              <KTSVG
                path="/media/icons/duotune/arrows/arr075.svg"
                className="svg-icon-2"
              />
              Add User
            </button>
          </div>
        </div>
      </div>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                {/* <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check={selectedData.length === total}
                      data-kt-check-target="#kt_table_users .form-check-input"
                      checked={selectedData.length === total}
                      onChange={({ target: { checked } }) =>
                        onSelectAll(checked)
                      }
                    />
                  </div>
                </th> */}
                <th
                  onClick={() => changeSort("firstName")}
                  className={clsx(
                    "min-w-150px pointer",
                    sort.field === "firstName" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  First Name
                </th>
                <th
                  onClick={() => changeSort("lastName")}
                  className={clsx(
                    "min-w-150px pointer",
                    sort.field === "lastName" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Last Name
                </th>
                <th
                  onClick={() => changeSort("role")}
                  className={clsx(
                    "min-w-140px pointer",
                    sort.field === "role" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Roles
                </th>
                <th
                  onClick={() => changeSort("email")}
                  className={clsx(
                    "min-w-120px pointer",
                    sort.field === "email" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Email Id
                </th>
                <th>Contact</th>
                {isAdmin?<th>Company</th>:null}
                <th className="min-w-120px">Status</th>
                <th className="min-w-100px text-center">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {users && users.length > 0 ? (
                users.map((v: any, key: number) => (
                  <tr key={key}>
                    {/* <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          data-kt-check={selectedData.includes(
                            v._id.toString()
                          )}
                          data-kt-check-target="#kt_table_users .form-check-input"
                          checked={selectedData.includes(v._id.toString())}
                          onChange={({ target: { checked } }) =>
                            onSelect(checked, v)
                          }
                        />
                      </div>
                    </td> */}
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href="javascript:void(0)"
                          className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                        >
                          {v.firstName}
                        </a>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-start flex-column">
                        <a
                          href="javascript:void(0)"
                          className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                        >
                          {v.lastName}
                        </a>
                      </div>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark text-hover-primary d-block mb-1 fs-6 overflow-eclilipse mw-120px text-capitalize"
                      >
                        {v.role.replace("_", " ")}
                      </a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark text-hover-primary d-block mb-1 fs-6"
                      >
                        {v.email}
                      </a>
                    </td>
                    <td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark  text-hover-primary d-block mb-1 fs-6"
                      >
                        {v.phoneNumber}
                      </a>
                    </td>
                    {isAdmin?<td>
                      <a
                        href="javascript:void(0)"
                        className="text-dark  text-hover-primary d-block mb-1 fs-6"
                      >
                        {v.company?.companyName}
                      </a>
                    </td>:null}
                    <td>
                      <span
                        className={
                          v.status === 0
                            ? "badge badge-light-warning"
                            : v.status === 1
                            ? "badge badge-light-success"
                            : "badge badge-light-danger"
                        }
                      >
                        {v.status === 0
                          ? "Invitation Sent"
                          : v.status === 1
                          ? "Active"
                          : "In-Active"}
                      </span>
                    </td>
                    <td className="d-flex justify-content-center">
                      <a
                        href="javascript:void(O)"
                        className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        Actions
                        <KTSVG
                          path="/media/icons/duotune/arrows/arr072.svg"
                          className="svg-icon-5 m-0"
                        />
                      </a>
                      <div
                        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                        data-kt-menu="true"
                      >
                        {v.status === 0 ? (
                          <div className="menu-item">
                            <a
                              className="menu-link"
                              onClick={() => resentInvite(v)}
                            >
                              Resend Invite
                            </a>
                          </div>
                        ) : (
                          <>
                            {v.status === 2 ? (
                              <div className="menu-item">
                                <a
                                  className="menu-link"
                                  onClick={() =>
                                    updateStatus(ADMIN_STATUS.ACTIVE, v)
                                  }
                                >
                                  Active
                                </a>
                              </div>
                            ) : (
                              <div className="menu-item">
                                <a
                                  className="menu-link"
                                  onClick={() =>
                                    updateStatus(ADMIN_STATUS.INACTIVE, v)
                                  }
                                >
                                  In-Active
                                </a>
                              </div>
                            )}

                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => editUser(v)}
                              >
                                Edit
                              </a>
                            </div>
                            <div className="menu-item">
                              <a
                                className="menu-link"
                                onClick={() => userDelete(v)}
                              >
                                Delete
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      No record found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{margin:20}}>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={total / itemsPerPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={itemsPerPage}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active"
          // forcePage={pageOffset}
        />
        </div>
        {inProgress && <UsersListLoading />}
      </KTCardBody>
      <Modal
        isOpen={openConfirmation}
        // onRequestClose={closeModal}
        style={deleteModalStyles}
        id={"delete_confirmation"}
      >
        <div className="container m-5">
          <h1>Delete Account</h1>
          <p>Are you sure you want to delete your account?</p>
          <div className="clearfix mt-5 d-flex justify-content-end">
            <button
              type="button"
              onClick={() => setOpenConfirmation(false)}
              className="btn btn-sm btn-secondary me-3"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => deleteUser(userData)}
              className="btn btn-sm btn-danger"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        id={"user_management"}
      >
        <AddModal closeModal={closeModal} action={action} data={userData} />
      </Modal>
      <Modal
        isOpen={exportModalIsOpen}
        id={"export_project"}
        onRequestClose={closeExportModel}
        style={customStyles}
      >
        <ExportModal closeModal={closeExportModel} download={download} />
      </Modal>
    </>
  );
};

export { UsersTable };

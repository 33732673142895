import { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "../../../setup/redux/Store";
import {
  getUserThunk,
  blacklistThunk,
  updateStatusThunk,
  getCountriesThunk,
} from "../../redux/Freelancer/thunk";
import { viewUserAction } from "../../redux/Freelancer/slice";
import { KTCardBody, useDebounce } from "../../../_metronic/helpers";
import { MenuComponent } from "../../../_metronic/assets/ts/components";
import { isFulfilled } from "@reduxjs/toolkit";
import { APP_USER_PROGRESS_STATUS } from "../../../models/app";
import { IAppUser } from "../../redux/Freelancer/types";
import { toast } from "react-toastify";
import { ROLES, USER_STATUS } from "../../../models/app";
import { UsersListLoading } from "../../../_metronic/layout/components/loading/UsersListLoading";
import ReactPaginate from "react-paginate";
import { KTSVG } from "../../../_metronic/helpers";
import Modal from "react-modal";
import ExportModal from "./export";

const itemsPerPage = 10;
const initFilter = {
  status: "",
  country: "",
  gender: "",
};

const customStyles = {
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "50%",
    padding: 0,
    transform: "translate(-50%, -50%)",
  },
};

const UsersTable = () => {
  const { companyId, role, inProgress } = useSelector((state) => ({
    role: state.auth.user?.role,
    inProgress: state.freelancers.inProgress,
    companyId: state.auth.user?.company?._id,
  }));

  const dispatch = useDispatch();
  const [country, setCountry] = useState([]);
  const [selectedData, setSelectedData] = useState<string[]>([]);
  const [search, setSearch] = useState<string>("");
  const [freelancers, setFreelancers] = useState<IAppUser[]>([]);
  const [sort, setSort] = useState({ field: "", order: "asc" });
  const [total, setTotal] = useState(0);
  const [multiFilter, setMultiFilter] = useState(initFilter);
  const [exportModalIsOpen, setExportModalIsOpen] = useState(false);
  const [filter, setFilter] = useState<any>({
    page: 0,
    itemsPerPage,
    sort: "",
    order: "",
    search: "",
    filter: initFilter,
  });

  const isAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);

  const getUserList = async () => {
    const response:any = await dispatch(getUserThunk({ ...filter, companyId }));
    if (isFulfilled(response)) {
      setTotal(response.payload.count);
      setFreelancers(response.payload.data);
    }
    MenuComponent.reinitialization();
  };

  useEffect(() => {
    const getCountry = async () => {
      const response = await dispatch(getCountriesThunk());
      if (isFulfilled()) {
        setCountry(response.payload.data);
      }
    };
    getCountry();
  }, []);

  useEffect(() => {
    getUserList();
  }, [filter]);

  const view = (data: any) => {};

  const blacklist = async (data: IAppUser) => {
    let message = data.blacklist.includes(`${companyId}`)
      ? "User unblacklisted successfully"
      : "User blacklisted successfully";
    const result = await dispatch(
      blacklistThunk({
        id: [data._id],
        companyId,
        action: data.blacklist.includes(`${companyId}`)
          ? "unblacklist"
          : "blacklist",
      })
    );
    if (isFulfilled(result)) {
      toast(message, { type: "success" });
      getUserList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const updateStatus = async (status: number, data: IAppUser) => {
    const result = await dispatch(
      updateStatusThunk({ id: [data._id], status })
    );
    if (isFulfilled(result)) {
      toast("Status updated successfully", { type: "success" });
      getUserList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const onSelect = (state: boolean, data: IAppUser) => {
    const selected: string[] = [...selectedData];
    if (state) {
      selected.push(data._id.toString());
    } else {
      const index = selected.findIndex((v) => v === data._id);
      if (index > -1) {
        selected.splice(index, 1);
      }
    }
    setSelectedData(selected);
  };

  const onSelectAll = (state: boolean) => {
    if (state) {
      const selected: string[] = freelancers.map((v) => v._id.toString());
      setSelectedData(selected);
    } else {
      setSelectedData([]);
    }
  };

  const handlePageClick = (event: any) => {
    setFilter({ ...filter, page: event.selected });
  };

  const debouncedSearchTerm = useDebounce(search, 1000);
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && search !== undefined) {
      setFilter({ ...filter, search: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  const changeSort = (field: string) => {
    if (sort.field === field) {
      let order = "";
      let sortField = "";
      if (sort.order === "") {
        order = "asc";
        sortField = field;
      } else if (sort.order === "asc") {
        order = "desc";
        sortField = field;
      }
      setSort({ field: sortField, order: order });
      setFilter({ ...filter, sort: sortField, order: order });
    } else {
      setSort({ field, order: "asc" });
      setFilter({ ...filter, sort: field, order: "asc" });
    }
  };

  const updateBulkBlacklist = async (action: string) => {
    const result = await dispatch(
      blacklistThunk({ id: selectedData, companyId, action })
    );
    if (isFulfilled(result)) {
      toast("Status updated successfully", { type: "success" });
      setSelectedData([]);
      getUserList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const updateBulkStatus = async (status: number) => {
    const result = await dispatch(
      updateStatusThunk({ id: selectedData, status })
    );
    if (isFulfilled(result)) {
      toast("Status updated successfully", { type: "success" });
      setSelectedData([]);
      getUserList();
      return;
    } else {
      const err: any = result;
      toast("Error occured while updating", { type: "error" });
    }
  };

  const onChangeFilter = (field: string, value: any) => {
    const filt: any = { ...multiFilter };
    filt[field] = value;
    setMultiFilter(filt);
  };

  const resetFilter = () => {
    setMultiFilter(initFilter);
    setFilter({ ...filter, filter: initFilter });
  };

  const applyFilter = () => {
    setFilter({ ...filter, filter: multiFilter });
  };

  const download = async (type: string) => {
    const response:any = await dispatch(
      getUserThunk({ ...filter, companyId, export: type })
    );
    if (isFulfilled(response)) {
      const blob = new Blob([response.payload], {
        type:
          type === "csv"
            ? "text/csv"
            : type === "pdf"
            ? "application/pdf"
            : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `AppUsers.${type}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const viewUser = async (data: IAppUser) => {
    await dispatch(viewUserAction(data));
  };

  const openExportModel = () => {
    setExportModalIsOpen(true);
  };

  const closeExportModel = () => {
    setExportModalIsOpen(false);
  };

  const isFilterApplied = useMemo(() => {
    return (
      Object.keys(filter.filter).filter((v) => !!filter.filter[v]).length > 0
    );
  }, [filter.filter]);

  return (
    <>
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          {/* begin::Search */}
          <div className="d-flex align-items-center position-relative my-1">
            <KTSVG
              path="/media/icons/duotune/general/gen021.svg"
              className="svg-icon-1 position-absolute ms-6"
            />
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search user"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className="card-toolbar">
          {selectedData.length === 0 ? (
            <div
              className="d-flex justify-content-end"
              data-kt-user-table-toolbar="base"
            >
              <>
                <button
                  disabled={inProgress}
                  type="button"
                  className={clsx(
                    "btn btn-light-primary me-3 d-flex align-items-center",
                    isFilterApplied ? "btn-success" : ""
                  )}
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-2"
                  />
                  Filter
                </button>

                <div
                  className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                  data-kt-menu="true"
                >
                  <div className="px-7 py-5">
                    <div className="fs-5 text-dark fw-bolder">
                      Filter Options
                    </div>
                  </div>

                  <div className="separator border-gray-200"></div>

                  <div className="px-7 py-5" data-kt-user-table-filter="form">
                    <div className="mb-10">
                      <label className="form-label fs-6 fw-bold">Status:</label>
                      <select
                        className="form-select form-select-solid fw-bolder"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-user-table-filter="role"
                        data-hide-search="true"
                        onChange={(e) =>
                          onChangeFilter("status", e.target.value)
                        }
                        value={multiFilter.status}
                      >
                        <option value="">Select</option>
                        <option value={USER_STATUS.ACTIVE}>Active</option>
                        <option value={USER_STATUS.INACTIVE}>In-active</option>
                        <option value="blacklist">Blacklist</option>
                      </select>
                    </div>
                    <div className="mb-10">
                      <label className="form-label fs-6 fw-bold">
                        Country:
                      </label>
                      <select
                        className="form-select form-select-solid fw-bolder"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-user-table-filter="role"
                        data-hide-search="true"
                        onChange={(e) =>
                          onChangeFilter("country", e.target.value)
                        }
                        value={multiFilter.country}
                      >
                        <option value=""></option>
                        {country.map((v: any) => (
                          <option value={v.value}>{v.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-10">
                      <label className="form-label fs-6 fw-bold">Gender:</label>
                      <select
                        className="form-select form-select-solid fw-bolder"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-user-table-filter="role"
                        data-hide-search="true"
                        onChange={(e) =>
                          onChangeFilter("gender", e.target.value)
                        }
                        value={multiFilter.gender}
                      >
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        disabled={inProgress}
                        onClick={resetFilter}
                        className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                        data-kt-menu-dismiss="true"
                        data-kt-user-table-filter="reset"
                      >
                        Reset
                      </button>
                      <button
                        disabled={inProgress}
                        type="button"
                        onClick={applyFilter}
                        className="btn btn-primary fw-bold px-6"
                        data-kt-menu-dismiss="true"
                        data-kt-user-table-filter="filter"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </>

              <button
                onClick={openExportModel}
                type="button"
                className="btn btn-light-primary me-3 d-flex align-items-center"
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr078.svg"
                  className="svg-icon-2"
                />
                Export
              </button>

              {/* <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add User
      </button> */}
            </div>
          ) : (
            <div className="d-flex justify-content-end align-items-center">
              <div className="fw-bolder me-5">
                <span className="me-2">{selectedData.length}</span> Selected
              </div>
              {isAdmin ? (
                <>
                  <button
                    type="button"
                    className="btn btn-danger me-3"
                    onClick={() => updateBulkStatus(USER_STATUS.INACTIVE)}
                  >
                    In-activate
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => updateBulkStatus(USER_STATUS.ACTIVE)}
                  >
                    Activate
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn-danger me-3"
                    onClick={() => updateBulkBlacklist("blacklist")}
                  >
                    Blacklist
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => updateBulkBlacklist("unblacklist")}
                  >
                    Unblacklist
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <KTCardBody className="py-4">
        <div className="table-responsive">
          <table
            id="kt_table_users"
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          >
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check={selectedData.length === total}
                      data-kt-check-target="#kt_table_users .form-check-input"
                      checked={selectedData.length === total}
                      onChange={({ target: { checked } }) =>
                        onSelectAll(checked)
                      }
                    />
                  </div>
                </th>
                <th
                  onClick={() => changeSort("name")}
                  className={clsx(
                    "min-w-150px pointer",
                    sort.field === "name" && sort.order !== ""
                      ? sort.order === "asc"
                        ? "table-sort-asc"
                        : "table-sort-desc"
                      : ""
                  )}
                >
                  Name
                </th>
                <th className="min-w-140px">Skills</th>
                <th
                  // onClick={() => changeSort("location")}
                  className={clsx(
                    "min-w-120px"
                    // sort.field === "location" && sort.order !== ""
                    //   ? sort.order === "asc"
                    //     ? "table-sort-asc"
                    //     : "table-sort-desc"
                    //   : ""
                  )}
                >
                  Location
                </th>
                <th
                  // onClick={() => changeSort("gender")}
                  className={clsx(
                    "min-w-120px"
                    // sort.field === "gender" && sort.order !== ""
                    //   ? sort.order === "asc"
                    //     ? "table-sort-asc"
                    //     : "table-sort-desc"
                    //   : ""
                  )}
                >
                  Gender
                </th>
                <th className="min-w-120px">Rating</th>
                {/* <th className="min-w-100px text-end">Actions</th> */}
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold">
              {freelancers && freelancers.length > 0 ? (
                freelancers.map((v: IAppUser, key: number) => {
                  let rating =
                    v.rating.length > 0
                      ? v.rating.reduce(
                          (acc: number, r: any) => acc + r.rating,
                          0
                        ) / v.rating.length
                      : 0;

                  let supervisorRating =
                    v.supervisorRating.length > 0
                      ? v.supervisorRating.reduce(
                          (acc: number, r: any) => acc + r.rating,
                          0
                        ) / v.supervisorRating.length
                      : 0;

                  const overallRating = rating + supervisorRating;
                  const avgRating =
                    overallRating > 0
                      ? Math.round(
                          overallRating /
                            (rating > 0 && supervisorRating > 0 ? 2 : 1)
                        )
                      : 0;

                  return (
                    <tr key={key}>
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            data-kt-check={selectedData.includes(
                              v._id.toString()
                            )}
                            data-kt-check-target="#kt_table_users .form-check-input"
                            checked={selectedData.includes(v._id.toString())}
                            onChange={({ target: { checked } }) =>
                              onSelect(checked, v)
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50px me-5">
                            <span className="symbol-label bg-light br-50 ">
                              {v.photo ? (
                                <img
                                  src={v.photo}
                                  className="h-100 align-self-end w-100 br-50"
                                  alt=""
                                />
                              ) : null}
                            </span>
                          </div>
                          <div className="d-flex justify-content-start flex-column">
                            <a
                              onClick={() => viewUser(v)}
                              id="kt_view_user_toggle"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-bs-dismiss="click"
                              data-bs-trigger="hover"
                              href="javascript:void(0)"
                              className="text-dark fw-bolder text-hover-primary mb-1 fs-6 text-capitalize"
                            >
                              {`${v.firstName} ${v.lastName}`}
                            </a>
                            <span
                              className={clsx(
                                v.status === USER_STATUS.ACTIVE &&
                                  (v.blacklist.length === 0 ||
                                    !v.blacklist.includes(`${companyId}`))
                                  ? "text-success "
                                  : "text-danger ",
                                "fw-bold d-block fs-7 cursor-pointer"
                              )}
                              data-kt-menu-trigger="click"
                              data-kt-menu-placement="bottom-end"
                            >
                              <span>
                                {v.status === USER_STATUS.ACTIVE &&
                                v.blacklist.length > 0 &&
                                v.blacklist.includes(`${companyId}`)
                                  ? "Blacklist"
                                  : v.status === USER_STATUS.ACTIVE
                                  ? "Active"
                                  : "In-Active"}
                              </span>
                              <span className="svg-icon svg-icon-5 m-0">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                            </span>
                            <div
                              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                              data-kt-menu="true"
                            >
                              {isAdmin ? (
                                <>
                                  {v.status !== USER_STATUS.ACTIVE ? (
                                    <div className="menu-item px-3">
                                      <a
                                        href="javascript:void(0)"
                                        className="menu-link px-3"
                                        onClick={() =>
                                          updateStatus(USER_STATUS.ACTIVE, v)
                                        }
                                      >
                                        Active
                                      </a>
                                    </div>
                                  ) : (
                                    <div className="menu-item px-3">
                                      <a
                                        href="javascript:void(0)"
                                        className="menu-link px-3"
                                        onClick={() =>
                                          updateStatus(USER_STATUS.INACTIVE, v)
                                        }
                                      >
                                        In-Active
                                      </a>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className="menu-item px-3">
                                  <a
                                    href="javascript:void(0)"
                                    className="menu-link px-3"
                                    data-kt-users-table-filter="delete_row"
                                    onClick={() => blacklist(v)}
                                  >
                                    {v.blacklist?.length > 0 &&
                                    v.blacklist.includes(`${companyId}`)
                                      ? "Unblacklist"
                                      : "Blacklist"}
                                  </a>
                                </div>
                              )}
                            </div>
                            <div style={{ width: 100, marginTop: 10 }}>
                              <div
                                className="h-4px w-100 bg-light mb-5"
                                data-bs-toggle="tooltip"
                                title="Profile completion"
                              >
                                <div
                                  className={
                                    (APP_USER_PROGRESS_STATUS[v.profileStatus] /
                                      11) *
                                      100 >=
                                    100
                                      ? `bg-success rounded h-4px`
                                      : `bg-primary rounded h-4px`
                                  }
                                  role="progressbar"
                                  title="Profile completion status"
                                  style={{
                                    width: `${
                                      (APP_USER_PROGRESS_STATUS[
                                        v.profileStatus
                                      ] /
                                        11) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          title={
                            v.skills &&
                            v.skills
                              .map((v: any) => {
                                return v.title;
                              })
                              .join(", ")
                          }
                          href="javascript:void(0)"
                          className="text-dark text-hover-primary d-block mb-1 fs-6 overflow-eclilipse mw-120px"
                        >
                          {v.skills &&
                            v.skills
                              .map((v: any) => {
                                return v.title;
                              })
                              .join(", ")}
                        </a>
                      </td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          className="text-dark text-hover-primary d-block mb-1 fs-6 text-capitalize"
                        >
                          {v?.profile?.city}
                        </a>
                        <span className="text-muted fw-bold text-muted d-block fs-7 text-capitalize">
                          {v?.profile?.country}
                        </span>
                      </td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          className="text-dark  text-hover-primary d-block mb-1 fs-6 text-capitalize"
                        >
                          {v.gender}
                        </a>
                      </td>
                      <td>
                        <div className="rating">
                          <div className="rating-label me-2">
                            <i
                              className={clsx(
                                avgRating > 0 ? "bi-star-fill" : "bi-star",
                                " bi fs-5 text-warning"
                              )}
                            ></i>
                          </div>
                          <div className="rating-label me-2">
                            <i
                              className={clsx(
                                avgRating > 1 ? "bi-star-fill" : "bi-star",
                                " bi fs-5 text-warning"
                              )}
                            ></i>
                          </div>
                          <div className="rating-label me-2">
                            <i
                              className={clsx(
                                avgRating > 2 ? "bi-star-fill" : "bi-star",
                                " bi fs-5 text-warning"
                              )}
                            ></i>
                          </div>
                          <div className="rating-label me-2">
                            <i
                              className={clsx(
                                avgRating > 3 ? "bi-star-fill" : "bi-star",
                                " bi fs-5 text-warning"
                              )}
                            ></i>
                          </div>
                          <div className="rating-label me-2">
                            <i
                              className={clsx(
                                avgRating > 4 ? "bi-star-fill" : "bi-star",
                                " bi fs-5 text-warning"
                              )}
                            ></i>
                          </div>
                        </div>
                      </td>
                      {/* <td className="text-end">
                    <a
                      href="#"
                      className="btn btn-light btn-active-light-primary btn-sm d-flex align-items-center"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      Actions
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr072.svg"
                        className="svg-icon-5 m-0"
                      />
                    </a>
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                      data-kt-menu="true"
                    >
                      <div className="menu-item">
                        <a className="menu-link py-0" onClick={() => view(v)}>
                          View
                        </a>
                      </div>
                    </div>
                  </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="d-flex text-center w-100 align-content-center justify-content-center">
                      No record found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div style={{ margin: 20 }}>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={total / itemsPerPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={itemsPerPage}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
            // forcePage={pageOffset}
          />
        </div>
        {inProgress && <UsersListLoading />}
      </KTCardBody>
      <Modal
        isOpen={exportModalIsOpen}
        id={"export_project"}
        onRequestClose={closeExportModel}
        style={customStyles}
      >
        <ExportModal closeModal={closeExportModel} download={download} />
      </Modal>
    </>
  );
};

export { UsersTable };

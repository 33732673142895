/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useMemo, useCallback } from "react";
import { KTSVG } from "../../../../_metronic/helpers/components/KTSVG";
import { useDispatch, useSelector } from "../../../../setup/redux/Store";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import { useDebounce } from "../../../../_metronic/helpers";
import { useFormik } from "formik";
import {
  editProjectThunk,
  getProjectManagersThunk,
  getJobsInCompanyThunk,
  getBrandAmbassadorThunk,
  getSupervisorThunk,
} from "../../../redux/Projects/thunks";
import { toast } from "react-toastify";
import { createValidationSchema } from "../../../../utils/validationSchema";
import { isFulfilled } from "@reduxjs/toolkit";
import { ROLES } from "../../../../models/app";
import { currencies } from "currencies.json";
import { getCompanyThunk } from "../../../redux/Admin/thunk";

/** NOTE IT IS USED FOR BOTH ADDING AND EDITING MODAL FOR THE PROJECT COMPONENT */
const EditProject: React.FC<{
  action: string;
  closeModal: any;
  data: any;
  country: any;
  fetchAllProjects?: any;
}> = ({ closeModal, data, action, country, fetchAllProjects }) => {

  const { companyId, role ,inProgress,project} = useSelector((state) => ({
    companyId: state.auth.user?.company?._id,
    role: state.auth.user?.role,
    inProgress: state.project.inProgress,
    project: state.project
  }));

  const [company, setCompany] = useState([]);
  const dispatch = useDispatch();

  const isSuperAdmin = useMemo(() => {
    return ROLES.SUPER_ADMIN === role ? true : false;
  }, [role]);

  useEffect(() => {
    const getCompanyList = async () => {
      const response: any = await dispatch(getCompanyThunk({}));
      if (isFulfilled(response)) {
        setCompany(
          response.payload.data.map((v: any) => ({
            id: v._id,
            companyName: v.companyName,
          }))
        );
      }
    };
    if (isSuperAdmin) {
      getCompanyList();
    }
  }, [isSuperAdmin]);

  const getLastFourDigits = (text: string) => {
    return text ? text.slice(-4) : "";
  };

  const {
    handleBlur,
    handleSubmit,
    setFieldValue,
    handleChange,
    errors,
    values,
    touched,
    status: formStatus,
  } = useFormik({
    initialValues: {
      name: data.name ? data.name : ``,
      startDate: data.startDate
        ? moment(data.startDate).format("YYYY-MM-DD")
        : "",
      endDate: data.endDate ? moment(data.endDate).format("YYYY-MM-DD") : "",
      location: data.location ? data.location?.country : "",
      projectDescription: data.projectDescription
        ? data.projectDescription
        : ``,
      tagToJob: data.tagToJob ? data.tagToJob : 0,
      tagAllsUsers: data.tagAllsUsers ? data.tagAllsUsers : 0,
      assignToBA: data.brandAmbassador
        ? data?.brandAmbassador?.map((v: any) => ({
            id: v.userId,
            name: `${v?.brandAmbassadorUser[0]?.firstName} ${
              v?.brandAmbassadorUser[0]?.lastName
            } - XXXXXX${getLastFourDigits(
              v?.brandAmbassadorUser[0]?.phoneNumber
            )}`,
          }))
        : [],
      assignToSupervisor: data.supervisor ? data?.supervisor[0]?.userId : ``,
      assignToProjectManager: data.projectManager
        ? data?.projectManager[0]?._id
        : ``,
      jobsId: data.jobs
        ? data?.jobs.map((v: any) => {
            return { id: v._id, name: `${v.jobNo} - ${v.title}` };
          })
        : [],
      currency: data.currency ? data?.currency : "",
      fieldName: data.fieldName ? data?.fieldName : "",
      fieldDisplayName: data.fieldDisplayName ? data?.fieldDisplayName : "",
      companyId: data.companyId
        ? data?.companyId
        : isSuperAdmin
        ? ""
        : companyId,
    },
    enableReinitialize: true,
    validationSchema: createValidationSchema("addProject"),
    onSubmit: async (values: any, { setStatus }) => {
      if (new Date(values.startDate) > new Date(values.endDate)) {
        toast("End date should be greater than Start Date experience", {
          type: "error",
        });
        return;
      }

      const payload = {
        projectName: values.name,
        startDate: values.startDate,
        endDate: values.endDate,
        location: values.location,
        projectDescription: values.projectDescription,
        assignToBA: values.assignToBA.map((v: any) => v.id),
        assignToSupervisor: values.assignToSupervisor,
        assignToProjectManager: values.assignToProjectManager,
        tagAllsUsers: values.tagAllsUsers,
        tagToJob: values.tagToJob,
        jobsId: values.jobsId.map((v: any) => v.id),
        companyId: values.companyId,
        currency: values.currency,
        fieldName: values.fieldName,
        fieldDisplayName: values.fieldDisplayName,
        id: action === "edit" ? data._id : "",
      };

      const response: any = await dispatch(editProjectThunk(payload));
      if (isFulfilled(response)) {
        closeModal();
        if (action === "edit") {
          toast("Project updated successfully", { type: "success" });
        } else {
          toast("Project added successfully", { type: "success" });
        }
        fetchAllProjects && fetchAllProjects();
      } else {
        const err: any = response;
        setStatus(err.payload.error);
      }
    },
  });


  const [isloading, setIsLoading] = useState<boolean>(false);
  const [jobData, setJobData] = useState<[]>([]);
  const [brandAmbassadorData, setBrandAmbassadorData] = useState<[]>([]);
  const [supervisorData, setSupervisorData] = useState<[]>([]);
  const debouncedSelectJobs = useDebounce(values.jobsId, 1000);
  const [projectManager, setProjectManager] = useState([]);
  const getAllJobs = async () => {
    setIsLoading(true);
    const response:any = await dispatch(
      getJobsInCompanyThunk({ companyId: values.companyId })
    );
    if (isFulfilled(response)) {
      setJobData(
        response.payload.data.map((v: any) => ({
          id: v._id,
          name: `${v.jobNo} - ${v.title}`,
        }))
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setJobData([]);
    }
  };

  const getBrandAmbassador = async (jobId: Array<any>) => {
    setIsLoading(true);
    const response:any = await dispatch(
      getBrandAmbassadorThunk({
        jobId: values.tagAllsUsers ? [] : jobId.map((v: any) => v.id),
      })
    );
    if (isFulfilled(response) && response.payload.status) {
      setBrandAmbassadorData(response.payload.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setBrandAmbassadorData([]);
    }
  };

  const getSupervisors = async (jobId: Array<any>) => {
    setIsLoading(true);
    const response: any = await dispatch(
      getSupervisorThunk({
        jobId: values.tagAllsUsers ? [] : jobId.map((v: any) => v.id),
      })
    );
    if (isFulfilled(response) && response.payload.status) {
      setSupervisorData(response.payload.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setBrandAmbassadorData([]);
    }
  };

  const getProjectManagers = async () => {
    if (values.companyId) {
      const response:any = await dispatch(
        getProjectManagersThunk({ companyId: values.companyId })
      );
      if (isFulfilled(response)) {
        if (response.payload.status) {
          setProjectManager(response.payload.data);
        }
      }
    } else {
      setProjectManager([]);
      setFieldValue("assignToProjectManager", "");
    }
  };

  const resetField = () => {
    setFieldValue("assignToBA", []);
    setFieldValue("assignToSupervisor", "");
  };

  useEffect(() => {
    if (values.tagToJob) {
      getAllJobs();
    } else {
      setJobData([]);
    }
    if (action !== "edit") {
      resetField();
    }
  }, [values.companyId, values.tagToJob]);

  useEffect(() => {
    setSupervisorData([]);
    setBrandAmbassadorData([]);
    if (action !== "edit") {
      resetField();
    }
    getBrandAmbassador(values.jobsId);
    getSupervisors(values.jobsId);
  }, [values.companyId, values.jobsId, values.tagAllsUsers]);

  useEffect(() => {
    getProjectManagers();
    if (action !== "edit") {
      resetField();
    }
  }, [values.companyId]);

  const onChangeJobHandler = (data: any) => {
    setFieldValue("jobsId", data);
  };

  const onChangeBAHandler = (data: any) => {
    setFieldValue("assignToBA", data);
  };

  const onChangeCompany = (value: any) => {
    setFieldValue("companyId", value);
    setFieldValue("assignToProjectManager", "");
  };

  const onChangeJobTag = (state: number) => {
    setFieldValue("tagToJob", state);
    setFieldValue("jobsId", []);
    setFieldValue("assignToBA", []);
    setFieldValue("assignToSupervisor", "");
    if (state) {
      setFieldValue("tagAllsUsers", 1);
    }
  };

  const onChangeAllUser = (state: number) => {
    setFieldValue("tagAllsUsers", state);
    setFieldValue("assignToBA", []);
    setFieldValue("assignToSupervisor", "");
  };

  return (
    <>
      <div className="modal-content">
        <div className="modal-header">
          <h2 style={{margin:20}}>{action === "edit" ? "Edit Project" : "Add Project"}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => closeModal()}
          >
            <KTSVG
              path="/media/icons/duotune/arrows/arr061.svg"
              className="svg-icon-1"
            />
          </div>
        </div>

        <div
          className="modal-body scroll-y mx-5 mx-xl-15 my-7"
          style={{ maxHeight: 500 }}
        >
          <div className="d-flex flex-column" id="kt_modal_add_user_scroll">
            <div className="fv-row row ">
              <div
                className="d-flex flex-column scroll-y me-n7 pe-7"
                id="kt_modal_add_user_scroll"
                data-kt-scroll="true"
                data-kt-scroll-activate="{default: false, lg: true}"
                data-kt-scroll-max-height="auto"
                data-kt-scroll-dependencies="#kt_modal_add_user_header"
                data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
                data-kt-scroll-offset="300px"
              >
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Project Name
                  </label>
                  <input
                    required
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={({ target: { value } }) =>
                      setFieldValue("name", value)
                    }
                    onBlur={handleBlur}
                    className="form-control form-control-solid mb-3 mb-lg-0"
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </div>
                <div className="fv-row row ">
                  <div className="col-md-6 mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Start Date
                    </label>
                    <input
                      type="date"
                      required
                      onBlur={handleBlur}
                      name="startDate"
                      value={values.startDate}
                      onChange={({ target: { value } }) =>
                        setFieldValue("startDate", value)
                      }
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                    {errors.startDate && touched.startDate ? (
                      <div className="text-danger">{errors.startDate}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      End Date
                    </label>
                    <input
                      type="date"
                      required
                      onBlur={handleBlur}
                      name="endDate"
                      value={values.endDate}
                      onChange={({ target: { value } }) =>
                        setFieldValue("endDate", value)
                      }
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                    {errors.endDate && touched.endDate ? (
                      <div className="text-danger">{errors.endDate}</div>
                    ) : null}
                  </div>
                </div>
                {isSuperAdmin ? (
                  <div className="fv-row mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Company
                    </label>
                    <select
                      className="form-select form-select-solid mb-3 mb-lg-0"
                      value={values.companyId}
                      onChange={({ target: { value } }) =>
                        onChangeCompany(value)
                      }
                    >
                      <option value={""}>Select</option>
                      {company.map((v: any) => (
                        <option value={v.id}>{v.companyName}</option>
                      ))}
                    </select>
                    {errors.companyId && touched.companyId ? (
                      <div className="text-danger">{errors.companyId}</div>
                    ) : null}
                  </div>
                ) : null}
                <div className="fv-row mb-7 ">
                  <label className="required fw-bold fs-6 mb-2">Location</label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    defaultValue={values.location}
                    onChange={({ target: { value } }) =>
                      setFieldValue("location", value)
                    }
                    onBlur={handleBlur}
                  >
                    <option value={""}>Select</option>
                    {country.map((v: any) => (
                      <option value={v.value}>{v.label}</option>
                    ))}
                  </select>
                  {/* <div className='input-group'>
                    <input
                      type='text'
                      required
                      name='location'
                      value={values.location}
                      onBlur={handleBlur}
                      //onChange={openGoogleMap}
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      placeholder='Location'
                    />

                    <a
                      href='#'
                      className='input-group-text d-flex align-items-center text-gray-400  mb-3 mb-lg-0 border border-transparent'
                      id='inputGroup-sizing-default'
                      onClick={openGoogleMap}
                    >
                      <span className='svg-icon svg-icon-4 me-1'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            opacity='0.3'
                            d='M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z'
                            fill='black'
                          />
                          <path
                            d='M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z'
                            fill='black'
                          />
                        </svg>
                      </span>
                    </a>
                  </div> */}

                  {errors.location && touched.location ? (
                    <div className="text-danger">{errors.location}</div>
                  ) : null}
                </div>
                <div className="fv-row mb-7 ">
                  <label className="required fw-bold fs-6 mb-2">Currency</label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    defaultValue={values.currency}
                    onChange={({ target: { value } }) =>
                      setFieldValue("currency", value)
                    }
                    onBlur={handleBlur}
                  >
                    <option value={""}>Select</option>
                    {currencies.map((v: any) => (
                      <option value={v.code}>
                        {v.code} - {v.name}
                      </option>
                    ))}
                  </select>
                  {errors.currency && touched.currency ? (
                    <div className="text-danger">{errors.currency}</div>
                  ) : null}
                </div>
                <div className="fv-row row ">
                  <div className="col-md-6 mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Ask your Question for Task
                    </label>
                    <input
                      type="text"
                      required
                      onBlur={handleBlur}
                      name="fieldDisplayName"
                      value={values.fieldDisplayName}
                      onChange={({ target: { value } }) =>
                        setFieldValue("fieldDisplayName", value)
                      }
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                    {errors.fieldDisplayName && touched.fieldDisplayName ? (
                      <div className="text-danger">
                        {errors.fieldDisplayName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mb-7">
                    <label className="required fw-bold fs-6 mb-2">
                      Task display label
                      <span className="fs-12">(Used in reports)</span>
                    </label>
                    <input
                      type="text"
                      required
                      onBlur={handleBlur}
                      name="fieldName"
                      value={values.fieldName}
                      onChange={({ target: { value } }) =>
                        setFieldValue("fieldName", value)
                      }
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                    {errors.fieldName && touched.fieldName ? (
                      <div className="text-danger">{errors.fieldName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Project Description
                  </label>
                  <textarea
                    className="form-control form-control-solid"
                    required
                    onBlur={handleBlur}
                    rows={3}
                    name="projectDescription"
                    placeholder=""
                    value={values.projectDescription}
                    onChange={({ target: { value } }) =>
                      setFieldValue("projectDescription", value)
                    }
                  ></textarea>
                  {errors.projectDescription && touched.projectDescription ? (
                    <div className="text-danger">
                      {errors.projectDescription}
                    </div>
                  ) : null}
                </div>
                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Tag The Job To Project
                  </label>
                  <div className="d-flex fv-row">
                    <div className="form-check form-check-custom form-check-solid col-md-2 mb-2">
                      <input
                        className="form-check-input"
                        name="tag-job-project"
                        type="radio"
                        value={1}
                        onChange={() => onChangeJobTag(1)}
                        checked={values.tagToJob === 1 ? true : false}
                      />
                      <label className="form-check-label fw-bold fs-6 ml-4 text-gray-800">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-custom form-check-solid col-md-2 mb-2">
                      <input
                        className="form-check-input"
                        name="tag-job-project"
                        type="radio"
                        value={0}
                        onChange={() => onChangeJobTag(0)}
                        checked={values.tagToJob === 0 ? true : false}
                      />
                      <label className="form-check-label fw-bold fs-6 ml-4 text-gray-800">
                        No
                      </label>
                    </div>
                  </div>
                </div>

                {/**start */}
                {values.tagToJob === 1 && (
                  <>
                    <div className="fv-row mt-3 ">
                      <div className="col-12 mb-10">
                        <label className="form-label">Job Selection</label>
                        <Multiselect
                          className="form-control form-control-solid "
                          loading={isloading}
                          onSelect={onChangeJobHandler}
                          onRemove={onChangeJobHandler}
                          displayValue="name"
                          selectedValues={values.jobsId}
                          options={jobData}
                          style={{
                            searchBox: {
                              border: "none",
                              "border-radius": "0px",
                            },
                          }}
                        />
                      </div>
                    </div>

                    <div className="mb-4 jobcollapse1">
                      <label className="required fs-6 mb-2">
                        Select User Type
                      </label>
                      <div className="d-flex fv-row mt-5 mb-5">
                        <div className="form-check form-check-custom form-check-solid me-5">
                          <input
                            className="form-check-input me-3"
                            name="user_job"
                            type="radio"
                            //defaultValue={}
                            /*defaultChecked={
                                data && values.tagAllsUsers === 0 ? true : false
                              }*/
                            value={0}
                            id="kt_modal_update_role_option_01"
                            checked={values.tagAllsUsers === 0 ? true : false}
                            onChange={({ target: { value } }) =>
                              onChangeAllUser(0)
                            }
                          />

                          <label className="form-check-label">
                            <div className="fw-bolder text-gray-800">
                              Job Applied User
                            </div>
                          </label>
                        </div>

                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input me-3"
                            name="user_job"
                            type="radio"
                            value={1}
                            id="kt_modal_update_role_option_11"
                            checked={values.tagAllsUsers === 1 ? true : false}
                            onChange={({ target: { value } }) =>
                              onChangeAllUser(1)
                            }
                          />

                          <label className="form-check-label">
                            <div className="fw-bolder text-gray-800">
                              All Users
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/**end */}

                <div className="fv-row mb-7">
                  <label className=" fw-bold fs-6 mb-2">Assign BA</label>{" "}
                  <Multiselect
                    className="form-control form-control-solid border border-transparent"
                    loading={isloading}
                    onSelect={onChangeBAHandler}
                    onRemove={onChangeBAHandler}
                    displayValue="name"
                    placeholder="Select BAs"
                    selectedValues={values.assignToBA}
                    options={brandAmbassadorData}
                    style={{
                      searchBox: {
                        border: "none",
                        "border-radius": "0px",
                      },
                    }}
                  />
                  {errors.assignToBA && touched.assignToBA ? (
                    <div className="text-danger">{errors.assignToBA}</div>
                  ) : null}
                </div>

                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Assign Supervisor
                  </label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    defaultValue={data && values.assignToSupervisor}
                    value={values.assignToSupervisor}
                    onChange={({ target: { value } }: any) => {
                      setFieldValue("assignToSupervisor", value);
                    }}
                    name="assignToSupervisor"
                    required
                    onBlur={handleBlur}
                  >
                    <option>{"Select a supervisor"}</option>
                    {supervisorData.map((el: any, index: number) => {
                      return (
                        <option key={index} value={el.id}>
                          {el.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.assignToSupervisor && touched.assignToSupervisor ? (
                    <div className="text-danger">
                      {errors.assignToSupervisor}
                    </div>
                  ) : null}
                </div>

                <div className="fv-row mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Assign Project Manager
                  </label>
                  <select
                    className="form-select form-select-solid mb-3 mb-lg-0"
                    value={values.assignToProjectManager}
                    onChange={({ target: { value } }) =>
                      setFieldValue("assignToProjectManager", value)
                    }
                    name="assignToProjectManager"
                    onBlur={handleBlur}
                    required
                  >
                    <option value="">{"Select a project manager"}</option>
                    {projectManager &&
                      projectManager.map((el: any, index: number) => {
                        return (
                          <option key={index} value={el._id}>
                            {`${el?.firstName} ${
                              el?.lastName
                            } - XXXXXX${getLastFourDigits(el?.phoneNumber)}`}
                          </option>
                        );
                      })}
                  </select>
                  {errors.assignToProjectManager &&
                  touched.assignToProjectManager ? (
                    <div className="text-danger">
                      {errors.assignToProjectManager}
                    </div>
                  ) : null}
                </div>

                {formStatus ? (
                  <div className="alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formStatus}
                    </div>
                  </div>
                ) : null}

                <div className="text-center mb-5">
                  <button
                    type="button"
                    className="btn btn-light me-3"
                    onClick={() => closeModal()}
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    disabled={inProgress}
                    className="btn btn-primary"
                    onClick={() => handleSubmit()}
                  >
                    <span className="indicator-label">Submit</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProject;
